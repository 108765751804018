<div style="flex-direction: column; margin: 20px">

  <h3 class="days-one">Offene Parkvorgänge</h3>

  <div *ngIf="!isLoading" style="display: flex; flex-direction: row">
    <span class="color-primary days-one"
          style="width: 5vw; min-width: fit-content; margin: 5px 10px">PLID</span>
    <span class="color-primary days-one"
          style="width: 15vw; min-width: fit-content; margin: 5px 10px">Parkplatz</span>
    <span class="color-primary days-one"
          style="width: 10vw; min-width: fit-content; margin: 5px 10px">Parkbeginn</span>
    <span class="color-primary days-one"
          style="width: 10vw; min-width: fit-content; margin: 5px 10px">Parkende</span>
    <span class="color-primary days-one"
          style="width: 7vw; min-width: fit-content; margin: 5px 10px">Parkdauer</span>
    <span class="color-primary days-one"
          style="width: 5vw; min-width: fit-content; margin: 5px 10px">Parkgebühr</span>
    <span class="color-primary days-one"
          style="width: 5vw; min-width: fit-content; margin: 5px 10px">Tarif-Info</span>
    <span class="color-primary days-one"
          style="width: 5vw; min-width: fit-content; margin: 5px 10px">Status</span>
    <span class="color-primary days-one"
          style="width: 10vw; min-width: fit-content; margin: 5px 10px"><!--create booking--></span>
  </div>

  <div *ngIf="isLoading" class="scs-flex-r scs-align-center" style="width: 100%; margin: 20px">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngFor="let parkingOperation of parkingOperationsWithBookingsAndFees">
    <mat-divider style="margin: 10px"></mat-divider>

    <div
      *ngIf="getParkingLotOfParkingOperation(parkingOperation)?.name != null"
      style="display: flex; flex-direction: row; align-items: center">

      <span style="width: 5vw; min-width: 50px; margin: 0 10px">
                {{getParkingLotOfParkingOperation(parkingOperation)?.id}}
              </span>

      <span style="width: 15vw; min-width: 150px; margin: 0 10px">
                {{getParkingLotOfParkingOperation(parkingOperation)?.name}}
              </span>

      <span *ngIf="parkingOperation.parking_operation.start_event != null; else noEvent"
            style="width: 10vw; min-width: 100px; margin: 0 10px">
        {{parkingOperation.parking_operation.start_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm'}}
      </span>

      <span *ngIf="parkingOperation.parking_operation.end_event != null; else noEvent"
            style="width: 10vw; min-width: 100px; margin: 0 10px">
        {{parkingOperation.parking_operation.end_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm'}}
      </span>

      <ng-template #noEvent>
        <span style="width: 10vw; min-width: 100px; margin: 0 10px">unbekannt</span>
      </ng-template>

      <span style="width: 7vw; min-width: 70px; margin: 0 10px">
                {{getParkingDuration(parkingOperation)}}
              </span>

      <span style="width: 5vw; min-width: 50px; margin: 0 10px">
        <span *ngIf="isParkingOperationComplete(parkingOperation)">
          {{getFormattedFee(parkingOperation.fee)}}
        </span>
      </span>

      <div style="width: 5vw; min-width: 50px; margin: 0 10px">
        <button mat-icon-button
                (click)="openTariffStructureDialog(getParkingLotOfParkingOperation(parkingOperation)?.id ?? -1)">
          <mat-icon color="primary" style="scale: 1.25">info_outline</mat-icon>
        </button>
      </div>

      <div style="width: 5vw; min-width: 50px; margin: 0 10px">
        <span>{{getPaymentStatus(parkingOperation)}}</span>
      </div>

      <div style="width: 10vw; min-width: 100px; margin: 0 10px">
        <button mat-stroked-button color="primary" style="width: fit-content;" class="mat-elevation-z2"
                (click)="openAddSupportBookingDialog(parkingOperation)">
          Parkberechtigung hinzufügen
        </button>
      </div>

    </div>
  </div>

  <mat-divider style="margin: 10px"></mat-divider>

  <div class="scs-flex-r scs-align-center">
    <span class="days-one">
      Die Parkgebühr für die offenen Parkvorgänge beträgt: {{getTotalFee(parkingOperationsWithBookingsAndFees)}}
    </span>
  </div>

</div>
