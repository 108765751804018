<div style="margin: 20px">

  <mat-stepper linear #stepper>

    <mat-step color="accent">
      <ng-template matStepLabel>
        <span (click)="globalStateService.clearState()">
          Kennzeichen suchen
        </span>
      </ng-template>
      <ng-template matStepContent>
        <app-license-plate-input-step [stepper]="stepper">
        </app-license-plate-input-step>
      </ng-template>
    </mat-step>

    <mat-step *ngIf="globalStateService.licensePlateInput.length >= 3"
              label="Parkvorgänge verwalten"
              color="accent">
      <ng-template matStepContent>
        <app-parking-operation-management-step></app-parking-operation-management-step>
      </ng-template>
    </mat-step>

  </mat-stepper>

</div>
