<div class="scs-flex-c scs-gap-l"
     style="align-self: center; align-items: center; text-align: center; padding: 1rem">

  <h1 class="color-primary days-one font-size-h1">
    Parkvorgänge für das Kennzeichen {{globalStateService.licensePlateInput}} bezahlen
  </h1>

  <p class="days-one">
    Die Rechnung über {{getFormattedFee(data.totalFee)}} für die ausgewählten Parkvogänge wurde erstellt<span *ngIf="data.emailSentSuccessfully"> und an die angegebene E-Mail Adresse versandt</span>.<br>
    Bitte überweisen Sie die Parkgebühr und geben dabei die Referenz-Nummer an, welche Sie auf der Rechnung finden.
  </p>

  <button mat-flat-button color="primary"
          [disabled]="isDownloadingInvoice"
          style="width: fit-content; padding: 1.5rem; margin: 1rem"
          (click)="downloadInvoice()">
    Rechnung direkt herunterladen
  </button>

  <mat-spinner *ngIf="isDownloadingInvoice"></mat-spinner>

  <div class="scs-flex-r scs-gap-l" style="justify-content: center">
    <img src="../../../../../assets/images/warn_triangle.svg" style="width: 25%; max-width: 5rem" alt="warn">

    <p class="scs-flex-c scs-gap-s days-one" style="text-align: start; width: 50%">
      Wichtiger Hinweis:<br>
      <span style="font-size: smaller">Der Geldeingang muss innerhalb der auf der Rechnung angegebenen Frist erfolgen.</span>
    </p>
  </div>

  <div mat-dialog-actions>
    <button mat-flat-button class="color-grey" matDialogClose>Schließen</button>
  </div>

</div>
