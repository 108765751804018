<div class="scs-flex-c scs-gap-l" style="margin: 20px">

  Auf dieser Webseite können Sie die Tarif-Struktur für Parkplätze konfigurieren und verwalten.

  <div class="scs-flex-r scs-gap-l">

    <mat-card style="margin: 20px">
      <mat-card-content>
        <div class="scs-flex-c scs-gap-s">
          <span style="font-size: small">Tarif-Einstellungen von Parkplätzen einsehen und ändern</span>

          <div class="scs-flex-r">
            <h4 class="color-primary days-one" style="margin: 0; margin-right: 100px; align-self: center">Parkplatz
              suchen:</h4>

            <mat-form-field appearance="outline" style="width: 200px">
              <input [(ngModel)]="parkingLotFilter" (input)="onFilterChange()" matInput
                     placeholder="Name des Parkplatzes">
            </mat-form-field>
          </div>

          <!--invoice table-->
          <table mat-table matSort [dataSource]="dataSourceExistingTariffStructuresTable">
            <ng-container matColumnDef="parking_lot_id">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>PLID</th>
              <td mat-cell *matCellDef="let item">
                {{ item.parking_lot_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="parking_lot">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Parkplatz</th>
              <td mat-cell *matCellDef="let item">
                {{ getParkingLot(item.parking_lot_id) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="tariff-info">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Tarif-Info</th>
              <td mat-cell *matCellDef="let item">
                <button mat-icon-button
                        (click)="openTariffInfoDialog(item.parking_lot_id)">
                  <mat-icon color="primary" style="scale: 1.25">info_outline</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="tariff-change">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let item">
                <button mat-stroked-button color="primary" style="min-width: fit-content; width: 150px"
                        class="mat-elevation-z2"
                        (click)="openEditTariffStructureDialog(item.parking_lot_id, item)">
                  Tarif ändern
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsExistingTariffStructuresTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsExistingTariffStructuresTable"></tr>
          </table>

          <span style="font-size: small;margin-top:20px;">Auf folgenden Parkplätzen existieren benutzerdefinierte Tarife, welche nicht im Dashboard editierbar sind</span>

          <table mat-table matSort [dataSource]="dataSourceCustomTariffStructures">
            <ng-container matColumnDef="parking_lot_id">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>PLID</th>
              <td mat-cell *matCellDef="let item">
                {{ item.parking_lot_id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Parkplatz</th>
              <td mat-cell *matCellDef="let item">
                {{ item.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="tariff-info">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Tarif-Info</th>
              <td mat-cell *matCellDef="let item">
                <button mat-icon-button
                        (click)="openCustomTariffInfoDialog(item)">
                  <mat-icon color="primary" style="scale: 1.25">info_outline</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsExistingCustomTariffStructuresTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsExistingCustomTariffStructuresTable"></tr>
          </table>

        </div>
      </mat-card-content>
    </mat-card>


    <mat-card style="margin: 20px">
      <mat-card-content>
        <div class="scs-flex-c scs-gap-s">
          <span style="font-size: small">Parkplätze ohne Tarif-Einstellungen</span>

          <!--invoice table-->
          <table mat-table matSort [dataSource]="dataSourceNotExistingTariffStructuresTable">
            <ng-container matColumnDef="parking_lot_id">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>PLID</th>
              <td mat-cell *matCellDef="let item">
                {{ item }}
              </td>
            </ng-container>

            <ng-container matColumnDef="parking_lot">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Parkplatz</th>
              <td mat-cell *matCellDef="let item">
                {{ getParkingLot(item) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="tariff-creation">
              <th mat-header-cell class="color-primary days-one" *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let item">
                <button mat-stroked-button color="primary" style="min-width: fit-content; width: 150px"
                        class="mat-elevation-z2"
                        (click)="openEditTariffStructureDialog(item, null)">
                  Tarif einstellen
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsNotExistingTariffStructuresTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsNotExistingTariffStructuresTable"></tr>
          </table>

        </div>
      </mat-card-content>
    </mat-card>


  </div>

</div>
