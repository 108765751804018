// src/app/config/customer1.config.ts


import {CustomerConfig} from "../CustomerConfig";

export const customer2Config: CustomerConfig = {
  icons: {
    tabIcon: 'Logo_Parken_Management_RGB.svg',
    icon: 'Logo_Parken_Management_RGB.svg',
  },
  strings: {
    title: 'Parken Management Payment',
    footerText: "Sheesh",
    email: "info@abcde.de",
    number: "+49 1234 567890",
    contactRef: "https://parken-und-management.de/impressum/",
    dataProtectionRef: "https://parken-und-management.de/datenschutzerklaerung/"
  }
};
