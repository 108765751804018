<div class="page-container scs-flex-c scs-align-center">
  <div class="login mat-elevation-z3">
    <div style="display: flex; flex-direction: column">

      <h2 class="color-primary" style="margin-top: 0">Management Portal</h2>

      <form [formGroup]="loginForm" (ngSubmit)="submitCredentials()" style="display: flex; flex-direction: column">

        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input appAutoFocus matInput id="mat-input-0" name="username" formControlName="username" type="text"
                 placeholder="Username">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput id="mat-input-1" name="password" formControlName="password" type="password"
                 placeholder="Password">
        </mat-form-field>

        <button mat-raised-button id="login-button" mat-flat-button="" color="primary" autofocus="" type="submit"
                class="mat-focus-indicator mat-flat-button mat-button-base mat-primary"><span
          class="mat-button-wrapper"> Login </span>
          <div class="mat-ripple mat-button-ripple"></div>
          <div class="mat-button-focus-overlay"></div>
        </button>

      </form>
    </div>
  </div>
</div>
