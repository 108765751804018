import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgIf} from "@angular/common";
import {
  BehaviorSubject,
  filter, finalize,
  fromEvent,
  map,
  Observable, race,
  Subscription,
  switchMap,
  take,
  takeWhile,
  tap,
  timer
} from "rxjs";
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {FeeService} from "../../../../services/fee.service";
import {ParkingOperationsPageTabIndexService} from "../../../../services/parking-operations-page-tab-index.service";
import {GlobalStateService} from "../../../../services/global-state.service";
import {TelecashPaymentServiceService} from "../../../../services/payment/telecash-payment-service.service";
import {ApiCallWrapperService} from "../../../../services/api/api-call-wrapper.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {CompleteParkingOperation} from "../../../../models/ParkingOperation";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../../../models/InvoiceWithPaymentLink";
import {PaymentHandlingService} from "../../../../services/payment/payment-handling.service";
import {AppScrollIndicatorComponent} from "../app-scroll-indicator/app-scroll-indicator.component";
import {ScrollService} from "../../../../services/scroll.service";

@Component({
  selector: 'app-pay-now-button',
  imports: [
    MatFabButton,
    MatIcon,
    MatProgressSpinner,
    NgIf,
    AppScrollIndicatorComponent
  ],
  templateUrl: './pay-now-button.component.html',
  styleUrl: './pay-now-button.component.css'
})
export class PayNowButtonComponent {
  @Input() invoiceWithPaymentLink: PaymentWebsiteInvoiceWithPaymentLink | null = null
  @Input() email: string | null = null
  @Input() selectedParkingOperations!: BehaviorSubject<ParkingOperationWithBookingsAndFeesWithSelection[]>
  @Input() totalSelectionFee!: BehaviorSubject<number>;
  @Input() onInvoicesPage: boolean = false;
  @Output() reloadParkingOperationsTrigger = new EventEmitter<any>();

  private getErrorMessage(status: string): string {
    switch (status) {
      case 'DENIED':
        return 'Zahlung wurde verweigert';
      case 'FAILED':
        return 'Zahlung ist fehlgeschlagen';
      case 'REJECTED':
        return 'Zahlung wurde abgelehnt';
      default:
        return 'Ein Fehler ist bei der Zahlung aufgetreten';
    }
  }

  invoiceCreationInProgress = false;
  checkoutInProgress = false;
  invoiceInProgress = false
  waitingOnPaymentSuccess = false
  successfulPayment = false
  paymentFailed = false;
  cancelPayment = false;

  private pollingSubscription?: Subscription;
  paymentApproved = false;
  pollingPaymentStatus = false;
  pollCount = 0;
  paymentError: string | null = null; // Reset error state

  private readonly ERROR_STATES: string[] = ['FAILED',];
  private readonly UNRECOVERABLE_ERROR_STATUSES = ['FRAUD', 'DENIED', 'REJECTED'];

  getLoadingStatusString() {
    if (this.cancelPayment) {
      return "Zahlung abgebrochen..."
    } else if (this.invoiceInProgress) {
      return "Rechnung wird erstellt..."
    } else if (this.waitingOnPaymentSuccess) {
      return "Zahlungsvorgang..."
    } else if (this.successfulPayment) {
      return "Zahlung erfolgreich"
    } else {
      return "Laden..."
    }
  }

  constructor(
    private feeService: FeeService,
    public parkingOperationsPageTabIndexService: ParkingOperationsPageTabIndexService,
    public globalStateService: GlobalStateService,
    private telecashPaymentService: TelecashPaymentServiceService,
    private apiCallHelper: ApiCallWrapperService,
    private snackBar: MatSnackBar,
    private paymentHandlingService: PaymentHandlingService,
    private router: Router,
    private scrollService: ScrollService
  ) {

  }

  @ViewChild('payButton') payButton!: ElementRef;


  ngAfterViewInit() {
    // Register the button with the service
    this.scrollService.setPayButton(this.payButton.nativeElement);
  }

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

  async onStartCheckout() {
    const mergedParkingOperations = this.selectedParkingOperations

    this.paymentFailed = false
    this.successfulPayment = false
    const simpleParkingOperations: CompleteParkingOperation[] = mergedParkingOperations.value.map((parkingOperation) => {
      return {
        start_event: parkingOperation.parking_operation.parking_operation.start_event!,
        end_event: parkingOperation.parking_operation.parking_operation.end_event!,
        longer_than_allowed: parkingOperation.parking_operation.parking_operation.longer_than_allowed
      }
    });

    this.invoiceCreationInProgress = true;
    this.checkoutInProgress = true
    this.invoiceInProgress = true
    let invoiceWithPaymentLink = await this.apiCallHelper.call(this.telecashPaymentService.createInvoiceAndPaymentLink(
      this.globalStateService.normalizedLicensePlate,
      this.globalStateService.licensePlateInput,
      this.email || undefined,
      simpleParkingOperations)
    )
    await this.delay(1000)
    if (invoiceWithPaymentLink == null) {
      this.snackBar.open("Die Rechnung konnte leider nicht erstellt werden, bitte versuchen Sie es später erneut", "", {duration: 3000});
      this.invoiceCreationInProgress = false;
      this.checkoutInProgress = false
      this.paymentFailed = true
      return;
    }
    this.invoiceInProgress = false

    this.waitingOnPaymentSuccess = true
    const paymentLinkUrl = invoiceWithPaymentLink.payment_link
    const paymentLinkId = invoiceWithPaymentLink.external_payment_link_id
    try {
      let successfulPayment = await this.handlePayment(paymentLinkUrl, paymentLinkId, this.globalStateService.normalizedLicensePlate)
      if (successfulPayment) {
        this.successfulPayment = true
        this.paymentFailed = false
        await this.delay(1000)
        this.checkoutInProgress = false
        this.globalStateService.addPaidInvoiceInSessionId(invoiceWithPaymentLink.invoice_id_string)
        this.parkingOperationsPageTabIndexService.switchToInvoiceTab()
      } else {
        this.successfulPayment = false
        this.paymentFailed = true
      }
    } catch (error) {
      this.paymentFailed = true;

    } finally {
      this.checkoutInProgress = false;
    }
  }

  private async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private paymentWindow: Window | null = null;

  async isPaymentLinkApproved(paymentLinkId: string, licensePlate: string): Promise<boolean> {
    let paymentLink = await this.apiCallHelper.call(this.telecashPaymentService.getPaymentLink(paymentLinkId, licensePlate))
    return paymentLink?.payment_status?.toUpperCase() == "APPROVED";
  }

  async handlePayment(paymentLinkUrl: string, paymentLinkId: string, licensePlate: string): Promise<boolean> {
    try {
      console.log('Opening payment window');
      this.openPaymentLink(paymentLinkUrl).subscribe({
        next: async ({status}) => {
          switch (status) {
            case 'completed':
              console.log('Payment was completed successfully');
              break;
            case 'closed':
              console.log('User closed the payment window');
              let isPayed = await this.isPaymentLinkApproved(paymentLinkId, licensePlate)
              if(isPayed){
                this.successfulPayment = true
                await this.delay(1000)
                this.goToInvoices()
              }else{
                this.cancelPayment = true
                await this.delay(1000)
                this.goToInvoices()
              }

              break;
          }
        }
      });

      console.log('Polling payment status');
      const result = await this.startPollingPaymentStatus(paymentLinkId, licensePlate, false);
      console.log('Payment result:', result);
      return result;
    } catch (error) {
      console.error('Payment failed:', error);
      return false;
    }
  }

  startPollingPaymentStatus(paymentLinkId: string, licensePlate: string, testMode: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();
      const MAX_POLLING_TIME = 4 * 120000; // 2 minutes
      const POLLING_INTERVAL = 4000; // 4 seconds
      this.pollCount = 0;
      this.pollingPaymentStatus = true;
      this.paymentError = null;

      this.pollingSubscription = timer(0, POLLING_INTERVAL).pipe(
        tap(() => this.pollCount++),
        takeWhile(() => {
          const timeElapsed = Date.now() - startTime;
          const withinTimeLimit = timeElapsed < MAX_POLLING_TIME &&
            !this.paymentApproved &&
            !this.paymentError;
          if (this.cancelPayment) {
            return false
          }
          if (testMode) {
            return this.pollCount <= 2;
          }
          return withinTimeLimit;
        }),
        switchMap(() => this.apiCallHelper.call(this.telecashPaymentService.getPaymentLink(paymentLinkId, licensePlate)))
      ).subscribe({
        next: (response) => {
          console.log('Polling payment status:', response);
          if (response?.payment_status.toUpperCase() === 'APPROVED') {
            this.paymentApproved = true;
            this.pollingPaymentStatus = false;
            this.closePaymentWindow();
            this.pollingSubscription?.unsubscribe();
            resolve(true);
          } else if (this.ERROR_STATES.includes(response?.payment_status.toUpperCase() || '')) {
            this.paymentError = this.getErrorMessage(response?.payment_status.toUpperCase() || '');
            this.pollingPaymentStatus = false;
            this.closePaymentWindow();
            this.pollingSubscription?.unsubscribe();
            reject(this.paymentError);
          }
        },
        error: (error) => {
          console.error('Error polling payment status:', error);
          this.paymentError = 'Ein technischer Fehler ist aufgetreten';
          this.pollingPaymentStatus = false;
          this.closePaymentWindow();
          this.pollingSubscription?.unsubscribe();
          reject(this.paymentError);
        },
        complete: () => {
          console.log(`Polling completed after ${this.pollCount} polls`);
          this.pollingPaymentStatus = false;
          if (!this.paymentApproved && !this.paymentError) {
            this.paymentError = 'Zeitüberschreitung bei der Zahlung';
            reject(this.paymentError);
          }
        }
      });
    });
  }


  private handleSuccessfulPayment(): void {
    this.pollingPaymentStatus = false;
    this.paymentApproved = true;
    this.paymentError = null;
    this.pollingSubscription?.unsubscribe();
  }


  closePaymentWindow(): void {
    if (this.paymentWindow) {
      this.paymentWindow.close();
      this.paymentWindow = null;
    }
  }

  private handlePaymentError(error: any): void {
    this.pollingPaymentStatus = false;
    this.paymentApproved = false;
    this.paymentError = 'Ein Fehler ist bei der Zahlung aufgetreten';
    this.pollingSubscription?.unsubscribe();
  }

    openPaymentLink(paymentLinkUrl: string): Observable<{ status: 'completed' | 'closed' | 'timeout' }> {
      this.paymentWindow = window.open(
        paymentLinkUrl,
        'PaymentWindow',
        'width=800,height=600'
      );

      const windowClosed$ = new Observable<'closed'>(observer => {
        const checkWindow = setInterval(() => {
          if (this.paymentWindow?.closed) {
            observer.next('closed');
            observer.complete();
            clearInterval(checkWindow);
          }
        }, 500);

        return () => clearInterval(checkWindow);
      });

      const paymentComplete$ = fromEvent<MessageEvent>(window, 'message').pipe(
        filter(event => event.data?.type === 'PAYMENT_COMPLETE'),
        map(() => 'completed' as const)
      );

      return race(
        paymentComplete$,
        windowClosed$,
        timer(5 * 60 * 1000).pipe(map(() => 'timeout' as const))  // 5-minute timeout
      ).pipe(
        take(1),
        map(status => ({status})),
        finalize(() => {
          if (this.paymentWindow && !this.paymentWindow.closed) {
            this.paymentWindow.close();
          }
        })
      );
    }

  goToInvoices() {
    if (!this.onInvoicesPage) {
      this.parkingOperationsPageTabIndexService.switchToInvoiceTab()

    }
  }


}
