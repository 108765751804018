import {TariffStructure, TariffTimes, TariffWithTariffTimes} from "../../../../../models/TariffStructure";
import {v4 as uuidv4} from 'uuid';

export class GroupedTariffTimeStructureState {


  onSameDayTariffTimes: Map<string, GroupedTariffTimesWithDays> = new Map<string, GroupedTariffTimesWithDays>();
  timeSpanTariffTimes: Map<string, GroupedTariffTimesWithDays> = new Map<string, GroupedTariffTimesWithDays>();

  groupedTariffTimesArray: GroupedTariffTimesWithDays[] = [];
  private _tariffStructure: TariffStructure;

  constructor(
    tariffStructure: TariffStructure
  ) {
    this._tariffStructure = {...tariffStructure};
    this.groupTariffTimes();
  }

  sortOrderCounter = 0;

  getAndIncreaseSortOrder(): number {
    return this.sortOrderCounter++;
  }

  removeTariffGroup(groupedTariffTime: GroupedTariffTimesWithDays) {
    if (groupedTariffTime.multiDay) {
      this.onSameDayTariffTimes.delete(groupedTariffTime.key);
    } else {
      this.timeSpanTariffTimes.delete(groupedTariffTime.key);
    }
    this.updateGroupedTariffTimesArray();
  }

  addTariffTime(tariffTime: TariffTimes) {
    if (this.onSameDayTariffTimes.has(this.getGroupKey(tariffTime))) {
      return;
    }
    if (this.timeSpanTariffTimes.has(this.getGroupKey(tariffTime))) {
      return;
    }
    let isOnSameDay = tariffTime.day_of_week_start == tariffTime.day_of_week_end;
    if (!isOnSameDay) {
      this.timeSpanTariffTimes.set(this.getGroupKey(tariffTime), {
        key: this.getGroupKey(tariffTime),
        sortOrder: this.getAndIncreaseSortOrder(),
        uniqueId: this.getUUID(),
        multiDay: false,
        days: [tariffTime.day_of_week_start, tariffTime.day_of_week_end],
        tariffTimes: [tariffTime]
      })
    } else {
      this.onSameDayTariffTimes.set(this.getGroupKey(tariffTime), {
        key: this.getGroupKey(tariffTime),
        sortOrder: this.getAndIncreaseSortOrder(),
        uniqueId: this.getUUID(),
        multiDay: true,
        days: [tariffTime.day_of_week_start],
        tariffTimes: [tariffTime]
      })
    }
    this.updateGroupedTariffTimesArray();
  }

  findAndReplaceTariffTime(tariffTime: TariffTimes) {

  }

  getUUID() {
    return uuidv4();
  }

  updateInternalTariffStructureFromGroups() {
    let tariffTimes: TariffTimes[] = [];
    for (let groupedTariffTime of this.groupedTariffTimesArray) {
      tariffTimes = [...tariffTimes, ...groupedTariffTime.tariffTimes];
    }
    this._tariffStructure.tariff_times = tariffTimes;
  }

  /**
   * Returns all tariff times from multiDayTariffTimes and timeSpanTariffTimes.
   */
  getAllTariffTimes() {
    let allGroupings = [...this.onSameDayTariffTimes.values(), ...this.timeSpanTariffTimes.values()]
    return allGroupings.map(grouping => grouping.tariffTimes).flat();
  }

  getAllGroupedTariffTimes() {
    return this.groupedTariffTimesArray;
  }

  onDayChange(groupedTariffTime: GroupedTariffTimesWithDays, days: number[]) {
    if (groupedTariffTime.multiDay) {
      let current = this.onSameDayTariffTimes.get(groupedTariffTime.key);
      if (current == null) {
        return;
      }
      const updatedCurrent = {
        ...current,
        days: days,
        tariffTimes: this.generateTariffTimesOnDays(days, current.tariffTimes[0])
      };
      if (updatedCurrent.days.length == 0) {
        this.onSameDayTariffTimes.delete(groupedTariffTime.key);
      } else {
        this.onSameDayTariffTimes.set(groupedTariffTime.key, updatedCurrent);
      }
    }

    this.updateGroupedTariffTimesArray()
  }

  private generateTariffTimesOnDays(days: number[], tariffTime: TariffTimes): TariffTimes[] {
    return days.map(day => {
      return {
        ...tariffTime,
        day_of_week_start: day,
        day_of_week_end: day
      }
    })
  }

  updateKeysAndTariffTimes() {
    let currentOnSameDayEntries = Array.from(this.onSameDayTariffTimes.entries());
    this.onSameDayTariffTimes.clear()
    currentOnSameDayEntries.forEach((values) => {
      let value = values[1];
      let baseTariffTime = value.tariffTimes[0];
      console.log(baseTariffTime)
      let newKey = this.getGroupKey(baseTariffTime);
      let adjustedTariffTimes = value.tariffTimes.map(tariffTime => {
        return {
          ...baseTariffTime,
          day_of_week_start: baseTariffTime.day_of_week_start,
          day_of_week_end: baseTariffTime.day_of_week_end
        }
      })
      this.onSameDayTariffTimes.set(newKey, {
        ...value,
        key: newKey,
        tariffTimes: adjustedTariffTimes
      })
    });

    let currentTimeSpanEntries = Array.from(this.timeSpanTariffTimes.entries());
    this.timeSpanTariffTimes.clear();
    currentTimeSpanEntries.forEach((values) => {
      let value = values[1];
      let key = values[0];
      let baseTariffTime = value.tariffTimes[0];
      let newKey = this.getGroupKey(baseTariffTime);
      this.timeSpanTariffTimes.delete(key);
      this.timeSpanTariffTimes.set(newKey, {
        ...value,
        key: newKey,
      })
    });
  }

  groupTariffTimes() {
    console.log("group tariffTimes")
    console.log(this._tariffStructure.tariff_times)
    this.onSameDayTariffTimes.clear();
    this.timeSpanTariffTimes.clear();
    for (let tariffTime of this._tariffStructure.tariff_times) {
      let isOnSameDay = tariffTime.day_of_week_start == tariffTime.day_of_week_end;
      console.log("isOnSameDay", isOnSameDay)
      console.log("tariffTime", tariffTime)
      if (!isOnSameDay) {
        this.timeSpanTariffTimes.set(this.getGroupKey(tariffTime), {
          key: this.getGroupKey(tariffTime),
          uniqueId: this.getUUID(),
          sortOrder: this.getAndIncreaseSortOrder(),
          multiDay: false,
          days: [tariffTime.day_of_week_start, tariffTime.day_of_week_end],
          tariffTimes: [tariffTime]
        })
      } else {
        if (this.onSameDayTariffTimes.has(this.getGroupKey(tariffTime))) {
          this.onSameDayTariffTimes.get(this.getGroupKey(tariffTime))?.days.push(tariffTime.day_of_week_start);
          this.onSameDayTariffTimes.get(this.getGroupKey(tariffTime))?.tariffTimes.push(tariffTime);
          console.log("same day existing")
        } else {
          this.onSameDayTariffTimes.set(this.getGroupKey(tariffTime), {
            key: this.getGroupKey(tariffTime),
            sortOrder: this.getAndIncreaseSortOrder(),
            uniqueId: this.getUUID(),
            multiDay: true,
            days: [tariffTime.day_of_week_start],
            tariffTimes: [tariffTime]
          })
          console.log("same day new")
        }
      }
    }
    this.updateGroupedTariffTimesArray();
  }

  private updateGroupedTariffTimesArray() {
    this.groupedTariffTimesArray = [...Array.from(this.timeSpanTariffTimes.values()), ...Array.from(this.onSameDayTariffTimes.values())];
  }

  private getGroupKey(tariff: TariffTimes): string {
    return `${tariff.start_time}-${tariff.end_time}-${tariff.fee}-${tariff.flat_fee}-${tariff.consecutive_fees.join("-")}-${tariff.billing_interval_minutes}-${tariff.consecutive_billing_interval_minutes.join("-")}-${tariff.free_parking_duration_minutes}`;
  }

  /**
   * Converts a time span tariff to a multi day tariff.
   * @param groupedTariffTime
   */
  convertToMultiDay(groupedTariffTime: GroupedTariffTimesWithDays) {
    if (groupedTariffTime.multiDay) {
      console.log("already multi day")
      return;
    }
    let current = this.timeSpanTariffTimes.get(groupedTariffTime.key);
    if (current == null) {
      return;
    }
    this.timeSpanTariffTimes.delete(groupedTariffTime.key);

    let updatedCurrent = {
      ...current,
      multiDay: true,
      days: [current.tariffTimes[0].day_of_week_start],
      tariffTimes: current.tariffTimes
    }
    this.onSameDayTariffTimes.set(groupedTariffTime.key, updatedCurrent);
    this.updateGroupedTariffTimesArray();

  }

  convertToTimeSpan(groupedTariffTime: GroupedTariffTimesWithDays) {
    console.log("convert to time span", groupedTariffTime)
    if (!groupedTariffTime.multiDay) {
      console.log("not multi day")
      return;
    }
    let current = this.onSameDayTariffTimes.get(groupedTariffTime.key);
    if (current == null) {
      console.log("current is null", this.onSameDayTariffTimes)
      return;
    }
    this.onSameDayTariffTimes.delete(groupedTariffTime.key);
    let updatedCurrent = {
      ...current,
      multiDay: false,
      days: [current.tariffTimes[0].day_of_week_start],
      tariffTimes: [current.tariffTimes[0]]
    }
    this.timeSpanTariffTimes.set(groupedTariffTime.key, updatedCurrent);
    this.updateGroupedTariffTimesArray();
  }

  onTariffTimeChange(groupedTariffTime: GroupedTariffTimesWithDays, tariffTime: TariffTimes) {
    if (groupedTariffTime.multiDay) {
      let current = this.onSameDayTariffTimes.get(groupedTariffTime.key);
      if (current == null) {
        console.log("current is null")
        return;
      }
      let oldKey = current.key
      let newKey = this.getGroupKey(tariffTime);
      let updatedCurrent = {
        ...current,
        key: newKey,
        tariffTimes: current.tariffTimes.map(tariff => {
          return {
            ...tariff,
            start_time: tariffTime.start_time,
            end_time: tariffTime.end_time,
            fee: tariffTime.fee,
            flat_fee: tariffTime.flat_fee,
            consecutive_fees: tariffTime.consecutive_fees,
            billing_interval_minutes: tariffTime.billing_interval_minutes,
            consecutive_billing_interval_minutes: tariffTime.consecutive_billing_interval_minutes,
            free_parking_duration_minutes: tariffTime.free_parking_duration_minutes
          }
        })
      }
      this.onSameDayTariffTimes.delete(oldKey);
      this.onSameDayTariffTimes.set(newKey, updatedCurrent);
    } else {
      let current = this.timeSpanTariffTimes.get(groupedTariffTime.key);
      if (current == null) {
        return;
      }
      let oldKey = current.key
      let newKey = this.getGroupKey(tariffTime);
      let updatedCurrent = {
        ...current,
        key: newKey,
        tariffTimes: current.tariffTimes.map(tariff => {
          return {
            ...tariff,
            start_time: tariffTime.start_time,
            end_time: tariffTime.end_time,
            day_of_week_start: tariffTime.day_of_week_start,
            day_of_week_end: tariffTime.day_of_week_end,
            fee: tariffTime.fee,
            flat_fee: tariffTime.flat_fee,
            consecutive_fees: tariffTime.consecutive_fees,
            billing_interval_minutes: tariffTime.billing_interval_minutes,
            consecutive_billing_interval_minutes: tariffTime.consecutive_billing_interval_minutes,
            free_parking_duration_minutes: tariffTime.free_parking_duration_minutes
          }
        })
      }
      this.timeSpanTariffTimes.delete(oldKey);
      this.timeSpanTariffTimes.set(newKey, updatedCurrent);
    }
    this.updateGroupedTariffTimesArray();
  }

  private doTariffTimesOverlap(a: TariffTimes, b: TariffTimes): boolean {
    const minutesInDay = 60 * 24;

    const timeSpansToCompare: number[][] = [];

    const aStart = a.start_time + a.day_of_week_start * minutesInDay;
    let aEnd = a.end_time + a.day_of_week_end * minutesInDay;
    if (aEnd < aStart) {
      aEnd += 7 * minutesInDay;
    }

    const bStart = b.start_time + b.day_of_week_start * minutesInDay;
    let bEnd = b.end_time + b.day_of_week_end * minutesInDay;
    if (bEnd < bStart) {
      bEnd += 7 * minutesInDay;
    }

    timeSpansToCompare.push([aStart, aEnd, bStart, bEnd]);
    timeSpansToCompare.push([aStart + 7 * minutesInDay, aEnd + 7 * minutesInDay, bStart, bEnd]);
    timeSpansToCompare.push([aStart, aEnd, bStart + 7 * minutesInDay, bEnd + 7 * minutesInDay]);

    return timeSpansToCompare.some(timeSpan => {
      return this.timeSpanOverlap(timeSpan[0], timeSpan[1], timeSpan[2], timeSpan[3]);
    });
  }

  private timeSpanOverlap(aStart: number, aEnd: number, bStart: number, bEnd: number): boolean {
    return aStart < bEnd && bStart < aEnd;
  }

}

export interface GroupedTariffTimesWithDays {
  key: string,
  sortOrder: number,
  uniqueId: string,
  multiDay: boolean,
  days: number[],
  tariffTimes: TariffTimes[]
}
