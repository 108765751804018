import {Component, Input, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {TariffStructure} from "../../../../../models/TariffStructure";
import {
  computeColoredHoursMap,
  getTariffTimeGroupFeeHtmlDescription,
  getTariffTimeGroupTimeHtmlDescription
} from "./tariff-setting-colored-hours";
import {FeeService} from "../../../../../services/fee.service";
import {PRESET_COLOR_SCHEME} from "./tariff-setting-color-bar/color-bar.constant";

@Component({
    selector: 'app-tariff-setting-quick-overview',
    templateUrl: './tariff-setting-quick-overview.component.html',
    styleUrls: ['./tariff-setting-quick-overview.component.css'],
    standalone: false
})
export class TariffSettingQuickOverviewComponent implements OnInit, OnDestroy {

  @Input() tariffStructureSubject!: BehaviorSubject<TariffStructure | null | undefined>;

  tariffStructure: TariffStructure | null = null;

  /**
   * Maps the day of the week to the color bar (array of 24 colors, one for each hour of the day).
   */
  dayToColoredHoursMap = signal(new Map<number, string[]>());

  tariffTimeGroupDescriptions: WritableSignal<[string, string][]> = signal([]);

  /**
   * Subscription for managing subscriptions to observables.
   */
  private subscriptions: Subscription[] = [];

  constructor(
    private feeService: FeeService
  ) {

  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.tariffStructureSubject.subscribe((tariffStructure) => {
        if (tariffStructure == null) {
          console.log("failed to load tariff structure");
          return;
        }
        this.tariffStructure = tariffStructure;

        const [dayToColoredHoursMap, tariffTimeGroups] = computeColoredHoursMap(tariffStructure);
        this.dayToColoredHoursMap.set(dayToColoredHoursMap);

        this.tariffTimeGroupDescriptions.set(tariffTimeGroups.map(tariffTimes => {
          const timeDescription = getTariffTimeGroupTimeHtmlDescription(tariffTimes);
          const feeDescription = getTariffTimeGroupFeeHtmlDescription(tariffTimes, tariffStructure.billing_interval_minutes, this.feeService);
          return [timeDescription, feeDescription];
        }));

        //TODO compute list of description texts for the tariff times
        //TODO update description texts

        //TODO update tariff structure description texts
      })
    );
  }

  /**
   * index starts at 0.
   */
  getColorOfTariffTimeGroupIndex(index: number): string {
    return PRESET_COLOR_SCHEME[(index % (PRESET_COLOR_SCHEME.length - 1)) + 1];
  }

  getDefaultColor(): string {
    return PRESET_COLOR_SCHEME[0];
  }

  getMaxPayableParkingDurationDescription(tariffStructure: TariffStructure): string {
    if (tariffStructure.maximum_duration_minutes == null) {
      return "unbegrenzt";
    }
    return this.getTimeStringFromMinutes(tariffStructure.maximum_duration_minutes);
  }

  getTimeStringFromMinutes(minutesOfDay: number): string {
    const hours = Math.floor(minutesOfDay / 60);
    const minutes = minutesOfDay % 60;
    return hours + ":" + (minutes.toString().padStart(2, "0"));
  }

  getMaxParkingFeeDescription(tariffStructure: TariffStructure): string {
    if (tariffStructure.maximum_fee == null) {
      return "unbegrenzt";
    }

    return this.feeService.getFormattedFee(tariffStructure.maximum_fee);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getMaxDailyFeeDescription(tariffStructure: TariffStructure) {
    if (tariffStructure.max_daily_fee == null) {
      return "nicht gesetzt";
    }
    return this.feeService.getFormattedFee(tariffStructure.max_daily_fee);
  }

  getDailyMaxFeeResetTimeDescription(tariffStructure: TariffStructure) {
    if (tariffStructure.daily_max_fee_reset_time == null) {
      return "nicht gesetzt";
    }
    return this.getTimeStringFromMinutes(tariffStructure.daily_max_fee_reset_time!);
  }
}
