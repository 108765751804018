import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  licensePlateInput: string = "";
  normalizedLicensePlate: string = "";

  constructor() {

  }

  paidInvoicesInSessionIds: string[] = [];

  addPaidInvoiceInSessionId(invoiceIdString: string) {
    console.log("Adding paid invoice in session id: " + invoiceIdString);
    this.paidInvoicesInSessionIds.push(invoiceIdString);
  }

  hasPaidInvoiceInSessionId(invoiceIdString: string): boolean {
    console.log("Checking if invoice has been paid in session: " + invoiceIdString);
    return this.paidInvoicesInSessionIds.includes(invoiceIdString);
  }

  clearState() {
    this.licensePlateInput = "";
    this.normalizedLicensePlate = "";
  }

  toNormalizedLicensePlate(licensePlateInput: string): string {
    return this.extendWithE(this.toNormalizedLicensePlateWithoutE(licensePlateInput));
  }

  toNormalizedLicensePlateWithoutE(licensePlateInput: string): string {
    return licensePlateInput.replaceAll(" ", "").replaceAll("-", "").toUpperCase();
  }

  /**
   * Adds an E at the end of the license plate if it does not already end with an E.
   * This is part of the normalized license plate to cover electric vehicle license plates.
   */
  extendWithE(trimmedLicensePlate: string): string {
    if (trimmedLicensePlate.endsWith("E")) {
      return trimmedLicensePlate;
    } else {
      return trimmedLicensePlate + "E";
    }
  }

}
