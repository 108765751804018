<div style="margin: 20px">

  Auf dieser Webseite können Sie Rechnungen einsehen, erneut versenden und einzeln bzw. gesammelt exportieren

  <mat-card style="margin: 20px 10px;">
    <div class="scs-flex-c scs-gap-m" style="margin: 20px">

      <!--header with global functions-->
      <div class="scs-flex-r scs-align-space-between-stretch">
        <h3 class="days-one">Rechnungen eines Monats</h3>

        <div *ngIf="!incompleteMonth; else incompleteDownloadButtons" class="scs-flex-r scs-gap-s">
          <button mat-stroked-button color="primary" style="width: fit-content;" class="mat-elevation-z2"
                  [disabled]="isExportingCsv"
                  (click)="exportCsv()">
            CSV herunterladen
            <mat-progress-bar *ngIf="isExportingCsv" mode="indeterminate" color="primary"></mat-progress-bar>
          </button>

          <button mat-stroked-button color="primary" style="width: fit-content;" class="mat-elevation-z2"
                  [disabled]="isExportingPdf"
                  (click)="exportPdf()">
            PDF herunterladen
            <mat-progress-bar *ngIf="isExportingPdf" mode="indeterminate" color="primary"></mat-progress-bar>
          </button>
        </div>

        <ng-template #incompleteDownloadButtons>
          <div class="scs-flex-r scs-gap-s">

            <span class="color-warn" style="font-size: small">
              Der ausgewählte Monat ist noch nicht abgeschlossen. <br>
              Die Rechnungen für diesen Monat können sich noch ändern!
            </span>

            <button mat-stroked-button color="warn" style="width: fit-content;" class="mat-elevation-z2"
                    [disabled]="isExportingCsv"
                    (click)="exportCsv()">
              CSV herunterladen
              <mat-progress-bar *ngIf="isExportingCsv" mode="indeterminate" color="warn"></mat-progress-bar>
            </button>

            <button mat-stroked-button color="warn" style="width: fit-content;" class="mat-elevation-z2"
                    [disabled]="isExportingPdf"
                    (click)="exportPdf()">
              PDF herunterladen
              <mat-progress-bar *ngIf="isExportingPdf" mode="indeterminate" color="warn"></mat-progress-bar>
            </button>
          </div>
        </ng-template>
      </div>

      <!--selections-->
      <div class="scs-flex-r scs-align-space-between-stretch">
        <div class="scs-flex-r scs-gap-s" style="align-items: center">
          <mat-form-field appearance="outline">
            <input #monthPicker matInput readonly [matDatepicker]="picker">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="year" [startAt]="startDate"
                            (monthSelected)="onMonthSelectionChange($event); picker.close()"></mat-datepicker>
          </mat-form-field>

          <button mat-icon-button
                  color="primary"
                  matTooltip="Aktualisieren"
                  [disabled]="isLoadingInvoices"
                  (click)="reloadCurrentMonthInvoices()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="scs-flex-r scs-gap-s" style="align-items: center">
          Suche
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="filterType" (ngModelChange)="onFilterTypeChange($event)">
              <mat-option [value]="'license_plate'">Kennzeichen</mat-option>
              <mat-option [value]="'invoice_id'">Rechnungsnummer</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 200px">
            <input (keyup)="onFilterChange($event)" matInput placeholder="{{getFilterType(filterType)}}">
          </mat-form-field>
        </div>
      </div>

      <!-- Stats Summary with Clickable Metric Cards - Always visible -->
      <div class="invoice-metrics">
        <div class="backdrop-decoration decoration-1"></div>
        <div class="backdrop-decoration decoration-2"></div>

        <div class="metrics-header">
          <div class="header-title days-one">Rechnungsübersicht</div>
          <div class="header-count">
            <div class="count-pill">
              <div class="count-icon">#</div>
              <span>{{ filteredInvoices.length }} Rechnungen</span>
            </div>
          </div>
        </div>

        <div class="metrics-grid">
          <!-- Total / All filter -->
          <div class="metric-item metric-total"
               [class.active]="isStatusFilterActive('all')"
               (click)="toggleStatusFilter('all')">
            <div class="metric-top">
              <div class="metric-label days-one">Gesamt</div>
              <div class="metric-value">{{ getMultipleInvoicesFees(allInvoices) }}</div>
            </div>
            <div class="metric-indicator">
              <span>100%</span>
            </div>
          </div>

          <!-- Paid filter -->
          <div class="metric-item metric-paid"
               [class.active]="isStatusFilterActive('paid')"
               (click)="toggleStatusFilter('paid')">
            <div class="metric-top">
              <div class="metric-label ays-one">Bezahlt</div>
              <div class="metric-value">{{ getTotalCollectedFeeString(allInvoices) }}</div>
            </div>
            <div class="metric-indicator">
              <span>{{ getPaidInvoicesCount(allInvoices) }} Rechnungen</span>
            </div>
          </div>

          <!-- Pending filter -->
          <div class="metric-item metric-pending"
               [class.active]="isStatusFilterActive('pending')"
               (click)="toggleStatusFilter('pending')">
            <div class="metric-top">
              <div class="metric-label ays-one">Ausstehend</div>
              <div class="metric-value">{{ getFormattedActivePendingAmount(allInvoices) }}</div>
            </div>
            <div class="metric-indicator">
              <span>{{ getActivePendingInvoices(allInvoices).length }} Rechnungen</span>
            </div>
          </div>

          <!-- Expired filter -->
          <div class="metric-item metric-expired"
               [class.active]="isStatusFilterActive('expired')"
               (click)="toggleStatusFilter('expired')">
            <div class="metric-top">
              <div class="metric-label ays-one">Abgelaufen</div>
              <div class="metric-value">{{ getExpiredFeesFormatted(allInvoices) }}</div>
            </div>
            <div class="metric-indicator">
              <span>{{ getExpiredInvoicesCount(allInvoices) }} Rechnungen</span>
            </div>
          </div>
        </div>

        <div class="progress-section">
          <div class="progress-title">
            <div class="title-icon">€</div>
            <span>Verteilung nach Betrag</span>
          </div>

          <div class="progress-container">
            <div class="progress-track">
              <div class="multi-progress">
                <div class="progress-segment segment-paid"></div>
                <div class="progress-segment segment-pending"></div>
                <div class="progress-segment segment-expired"></div>
                <div class="progress-segment segment-other"></div>
              </div>
            </div>

            <div class="amount-scale">
              <span>0€</span>
              <span>50€</span>
              <span>100€</span>
              <span>150€</span>
              <span>200€</span>
              <span>250€</span>
              <span>300€</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Filter info message when filtered list is empty -->
      <div *ngIf="filteredInvoices.length === 0 && allInvoices.length > 0" class="filter-info-message">
        Keine Rechnungen entsprechen den ausgewählten Filterkriterien.
        <button mat-button color="primary" (click)="toggleStatusFilter('all')">Alle Rechnungen anzeigen</button>
      </div>

      <!-- Table Container with Scrolling -->
      <div class="table-container" *ngIf="filteredInvoices.length > 0">
        <!--invoice table-->
        <table mat-table matSort [dataSource]="dataSource">

          <ng-container matColumnDef="invoice_id">
            <th mat-header-cell mat-sort-header class="color-primary days-one" *matHeaderCellDef>Rechnungsnummer</th>
            <td mat-cell *matCellDef="let item">
              {{ item.invoice.invoice_id_string }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell mat-sort-header class="color-primary days-one" *matHeaderCellDef>Rechnungsdatum</th>
            <td mat-cell *matCellDef="let item">
              {{ item.invoice.created_at * 1000 | date: 'dd.MM.yyyy HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="license_plate">
            <th mat-header-cell mat-sort-header class="color-primary days-one" *matHeaderCellDef>Kennzeichen</th>
            <td mat-cell *matCellDef="let item">
              {{ item.invoice.license_plate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fee">
            <th mat-header-cell mat-sort-header class="color-primary days-one" *matHeaderCellDef>Rechnungsbetrag</th>
            <td mat-cell *matCellDef="let item">
              {{ getInvoiceFee(item) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="payment_status">
            <th mat-header-cell mat-sort-header class="color-primary days-one" *matHeaderCellDef>Zahlungsstatus</th>
            <td mat-cell *matCellDef="let item" [ngClass]="getPaymentStatusClass(item)">
              <div class="payment-status">
                <mat-icon [class]="getStatusIconClass(item)">
                  {{ getStatusIcon(item) }}
                </mat-icon>
                <span class="payment-status-text">{{ getPaymentStatus(item) }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="payment_expiration_date">
            <th mat-header-cell mat-sort-header class="color-primary days-one" *matHeaderCellDef>Zahlungsfrist</th>
            <td mat-cell *matCellDef="let item" [ngClass]="getExpirationClass(item)">
              {{ getPaymentExpirationDate(item) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="details">
            <th mat-header-cell mat-sort-header class="color-primary days-one" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let item">

              <button mat-icon-button aria-label="open in dialog"
                      (click)="openInvoiceDetailsDialog(item)"
                      matTooltip="Details">
                <mat-icon>open_in_new</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>

        <mat-paginator class="mat-paginator-sticky"
                       [pageSize]="10"
                       [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons
                       aria-label="Select page">
        </mat-paginator>
      </div>

      <div *ngIf="allInvoices.length === 0 && !isLoadingInvoices" style="align-self: center; margin: 20px">
        Im ausgewählten Monat wurden keine Rechnungen erstellt.
      </div>

      <div *ngIf="isLoadingInvoices" class="loading-container">
        <mat-spinner></mat-spinner>
        <p>Laden...</p>
      </div>

    </div>
  </mat-card>

</div>
