import {Component, Input} from '@angular/core';
import {PaymentWebsiteInvoiceWithPaymentLink} from 'src/app/models/InvoiceWithPaymentLink';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {filter, finalize, fromEvent, interval, map, Observable, race, Subscription, take} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {ParkingLot} from "../../../models/ParkingLot";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiCallWrapperService} from "../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../services/api/api-routes.service";
import {InvoiceDownloadService} from "../../../services/invoice-download-service";
import {GlobalStateService} from "../../../services/global-state.service";
import {FeeService} from "../../../services/fee.service";
import {ParkingLotsCacheService} from "../../../services/parking-lots-cache.service";
import {PaymentHandlingService, PaymentPollingResult} from "../../../services/payment/payment-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageService} from "../../../services/local-storage.service";
import {Invoice} from "../../../models/Invoice";
import {PaymentWebsitePaymentLink} from "../../../models/CreatedInvoiceResponse";
import {DatePipe, NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatFabButton, MatIconButton} from "@angular/material/button";
import {
  ParkingOperationWithHoverComponent
} from "../invoices-page/parking-operation-with-hover/parking-operation-with-hover.component";
import {ConfigService} from "../../../services/config.service";

@Component({
  selector: 'app-invoice-with-payment-link-desktop',
  imports: [
    DatePipe,
    MatIcon,
    NgIf,
    MatProgressSpinner,
    MatFabButton,
    MatIconButton,
    ParkingOperationWithHoverComponent
  ],
  standalone: true,
  templateUrl: './invoice-with-payment-link-desktop.component.html',
  styleUrl: './invoice-with-payment-link-desktop.component.css',
  animations: [
    trigger('paymentSuccess', [
      state('initial', style({
        border: '1px solid rgba(0, 0, 0, 0.12)', // default mat-card border
        boxShadow: 'none',
        backgroundColor: 'white'
      })),
      state('success', style({
        border: '1px solid #81c784', // subtle green border
        backgroundColor: '#f1fff7' // very soft green background
      })),
      transition('initial => success', [
        // First flash a more prominent success state
        animate('0.3s ease-in', style({
          border: '2px solid #4CAF50',
          boxShadow: '0 0 12px rgba(76, 175, 80, 0.5)'
        })),
        // Then settle into the subtle final state
        animate('0.5s ease-out')
      ])
    ])
  ],
})
export class InvoiceWithPaymentLinkDesktopComponent {
  @Input() invoiceWithPaymentLink!: PaymentWebsiteInvoiceWithPaymentLink;
  @Input() parkingLots: ParkingLot[] = [];


  private refreshInterval = 60 * 1000; // 5 minutes in milliseconds
  private refreshSubscription?: Subscription;
  private updateTimeInterval?: Subscription;
  lastUpdateTime?: Date;

  emailAddressFormControl = new FormControl('', [Validators.email]);

  invoicesWithPaymentLink: PaymentWebsiteInvoiceWithPaymentLink[] = [];

  downloadingInvoiceIds: string[] = [];
  selectedInvoices: PaymentWebsiteInvoiceWithPaymentLink[] = [];

  isSendingEmail = false;
  lastSentInvoiceIdStrings: string[] = [];
  cancelPayment = false;

  paymentStates: { [key: string]: 'success' | 'initial' } = {};

  waitingOnPaymentSuccess = false
  successfulPayment = false
  paymentFailed = false;
  checkoutInProgress = false;
  paymentApproved = false;
  pollingPaymentStatus = false;
  paymentError: string | null = null; // Reset error state


  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    private invoiceDownloadService: InvoiceDownloadService,
    public globalStateService: GlobalStateService,
    private feeService: FeeService,
    private parkingLotsCacheService: ParkingLotsCacheService,
    private paymentHandlingService: PaymentHandlingService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private configService: ConfigService
  ) {
  }

  async ngOnInit() {
  }

  ngOnDestroy() {

  }

  private async refreshData() {
    try {
      await this.loadInvoicesWithPaymentLink();
      this.lastUpdateTime = new Date();
    } catch (error) {
      console.error('Failed to refresh invoices:', error);
    }
  }

  private setupRefreshInterval() {
    this.refreshSubscription = interval(this.refreshInterval).subscribe(() => {
      this.refreshData();
    });
  }

  private setupUpdateTimeInterval() {
    // Update every second for the first minute, then every minute
    this.updateTimeInterval = interval(10000).subscribe(() => {
      const diffInSeconds = Math.floor(
        (new Date().getTime() - this.lastUpdateTime!.getTime()) / 1000
      );

      if (diffInSeconds >= 60) {
        // Switch to minute-based updates after the first minute
        this.updateTimeInterval?.unsubscribe();
        this.updateTimeInterval = interval(60000).subscribe(() => {
          this.lastUpdateTime = this.lastUpdateTime;
        });
      }

      this.lastUpdateTime = this.lastUpdateTime;
    });
  }

  getTimeSinceLastUpdate(): string {
    if (!this.lastUpdateTime) return '';

    const diffInSeconds = Math.floor(
      (new Date().getTime() - this.lastUpdateTime.getTime()) / 1000
    );

    // Less than a minute
    if (diffInSeconds < 60) {
      if (diffInSeconds <= 1) return 'einer Sekunde';
      return `${diffInSeconds} Sekunden`;
    }

    // Minutes
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      if (diffInMinutes === 1) return 'einer Minute';
      return `${diffInMinutes} Minuten`;
    }

    // Hours
    const hours = Math.floor(diffInMinutes / 60);
    if (hours < 24) {
      if (hours === 1) return 'einer Stunde';
      return `${hours} Stunden`;
    }

    // Days (optional, depending on your needs)
    const days = Math.floor(hours / 24);
    if (days === 1) return 'einem Tag';
    return `${days} Tagen`;
  }


  async loadInvoicesWithPaymentLink() {
    this.parkingLotsCacheService.getParkingLots().then(parkingLots => {
      this.parkingLots = parkingLots;
      this.apiCallHelper.call(
        this.apiRoutesService.getInvoicesWithPaymentLink(this.globalStateService.normalizedLicensePlate)
      ).then((invoicesWithPaymentLink) => {
        if (invoicesWithPaymentLink == null) {
          this.snackBar.open("Leider ist etwas schief gelaufen. Bitte versuchen Sie es erneut.", "", {duration: 3000});
          return;
        }
        if (invoicesWithPaymentLink.length > 0) {
          this.localStorageService.setLicensePlateData(this.globalStateService.licensePlateInput,
            this.globalStateService.normalizedLicensePlate)
        }
        this.invoicesWithPaymentLink = invoicesWithPaymentLink.sort((invoice1, invoice2) => invoice2.invoice.created_at - invoice1.invoice.created_at);
        this.invoicesWithPaymentLink?.forEach(invoice => {
          if (this.globalStateService.hasPaidInvoiceInSessionId(invoice.invoice.invoice_id_string)) {
            this.paymentStates[invoice.invoice.invoice_id_string] = 'success';
          } else {
            this.paymentStates[invoice.invoice.invoice_id_string] = 'initial';
          }
        });
      });
    });
  }

  selectionChange(invoice: PaymentWebsiteInvoiceWithPaymentLink, selected: boolean) {
    if (selected) {
      this.selectedInvoices.push(invoice);
    } else {
      this.selectedInvoices = this.selectedInvoices.filter(selectedInvoice => selectedInvoice.invoice.invoice_id != invoice.invoice.invoice_id);
    }
  }

  onSendToEmail() {
    if (this.emailAddressFormControl.invalid) {
      this.snackBar.open("Bitte geben Sie eine gültige E-Mail Adresse ein.", "", {duration: 3000});
      return;
    }

    const email = this.emailAddressFormControl.value!.trim();
    const normalizedLicensePlate = this.globalStateService.normalizedLicensePlate;

    const selectedInvoiceIds = this.selectedInvoices.map(invoice => invoice.invoice.invoice_id);
    this.isSendingEmail = true;
    this.apiCallHelper.call(
      this.apiRoutesService.sendInvoicesToEmail(normalizedLicensePlate, selectedInvoiceIds, email)
    ).then(() => {
      this.isSendingEmail = false;
      this.lastSentInvoiceIdStrings = this.selectedInvoices.map(invoice => invoice.invoice.invoice_id_string);
    });
  }

  getFormattedTotalFee(invoice: Invoice): string {
    const totalFees = invoice.parking_operations
      .reduce((sum, parkingOperation) => sum + parkingOperation.fee, 0);

    return this.feeService.getFormattedFee(totalFees);
  }

  downloadInvoicePdf(invoice: Invoice) {
    this.downloadingInvoiceIds = this.downloadingInvoiceIds.concat([invoice.invoice_id]);
    this.invoiceDownloadService.downloadInvoice(invoice.invoice_id, invoice.invoice_id_string).then(() => {
      this.downloadingInvoiceIds = this.downloadingInvoiceIds.filter(id => id != invoice.invoice_id);
    });
  }

  downloadReceiptPdf(invoice: Invoice) {
    this.downloadingInvoiceIds = this.downloadingInvoiceIds.concat([invoice.invoice_id]);
    this.invoiceDownloadService.downloadReceiptPdf(invoice.invoice_id, invoice.invoice_id_string).then(() => {
      this.downloadingInvoiceIds = this.downloadingInvoiceIds.filter(id => id != invoice.invoice_id);
    });
  }

  isDownloadingInvoice(invoice: Invoice, currentlyDownloadingInvoiceIds: string[]): boolean {
    return currentlyDownloadingInvoiceIds.includes(invoice.invoice_id);
  }

  isDownloadingReceipt(invoice: Invoice, currentlyDownloadingInvoiceIds: string[]): boolean {
    return currentlyDownloadingInvoiceIds.includes(invoice.invoice_id);
  }

  async initiatePayment(paymentWebsiteInvoiceWithPaymentLink: PaymentWebsiteInvoiceWithPaymentLink) {
    this.cancelPayment = false
    this.checkoutInProgress = true;
    this.paymentError = null
    this.paymentFailed = false
    this.successfulPayment = false
    console.log("in checkout")
    let paymentLink = paymentWebsiteInvoiceWithPaymentLink.paymentLink
    let invoice = paymentWebsiteInvoiceWithPaymentLink.invoice
    if (!paymentLink) {
      return
    }

    try {
      this.waitingOnPaymentSuccess = true
      let paymentResult = await this.handlePayment(paymentLink.payment_link_url, paymentLink.external_payment_link_id, this.globalStateService.normalizedLicensePlate, paymentWebsiteInvoiceWithPaymentLink)

      if (paymentResult.success) {
        this.successfulPayment = true
        this.paymentError = null
        this.paymentFailed = false
        this.paymentStates[paymentWebsiteInvoiceWithPaymentLink.invoice.invoice_id_string] = 'success';
        await this.delay(1000)
      } else {
        this.successfulPayment = false
        this.paymentFailed = true
        this.paymentError = paymentResult.error
      }
    } catch (error) {
      this.paymentFailed = true;

    } finally {
      this.closePaymentWindow()
      await this.loadInvoicesWithPaymentLink();
      this.waitingOnPaymentSuccess = false
      this.checkoutInProgress = false;
    }
  }

  private paymentWindow: Window | null = null;

  async handlePayment(paymentLinkUrl: string, paymentLinkId: string, licensePlate: string, invoiceWithPaymentLink: PaymentWebsiteInvoiceWithPaymentLink): Promise<PaymentPollingResult> {
    try {
      console.log('Opening payment window');
      this.openPaymentLink(paymentLinkUrl).subscribe({
        next: async ({status}) => {
          switch (status) {
            case 'completed':
              console.log('Payment was completed successfully');
              this.paymentStates[invoiceWithPaymentLink.invoice.invoice_id_string] = 'success';
              break;
            case 'closed':
              console.log('User closed the payment window');
              //reset state

              this.cancelPayment = true;

              await this.delay(1500)
              this.paymentFailed = true;
              this.successfulPayment = false;
              this.waitingOnPaymentSuccess = false;
              this.checkoutInProgress = false;
              await this.loadInvoicesWithPaymentLink();

              break;
          }
        }
      });
      this.pollingPaymentStatus = true;
      this.paymentError = null;
      const result = await this.paymentHandlingService.startPollingPaymentStatus(paymentLinkId, licensePlate, false);
      this.pollingPaymentStatus = false;
      this.paymentError = result.error
      return result;
    } catch (error) {
      console.error('Payment failed:', error);
      return {success: false, error: 'Zahlung fehlgeschlagen'};
    }
  }

  openPaymentLink(paymentLinkUrl: string): Observable<{ status: 'completed' | 'closed' }> {
    this.paymentWindow = window.open(
      paymentLinkUrl,
      'PaymentWindow',
      'width=800,height=600'
    );

    const windowClosed$ = new Observable<'closed'>(observer => {
      const checkWindow = setInterval(() => {
        if (this.paymentWindow?.closed) {
          observer.next('closed');
          observer.complete();
          clearInterval(checkWindow);
        }
      }, 500);

      return () => clearInterval(checkWindow);
    });

    const paymentComplete$ = fromEvent<MessageEvent>(window, 'message').pipe(
      filter(event => event.data?.type === 'PAYMENT_COMPLETE'),
      map(() => 'completed' as const)
    );

    return race(
      paymentComplete$,
      windowClosed$
    ).pipe(
      take(1),
      map(status => ({status})),
      finalize(() => {
        if (this.paymentWindow && !this.paymentWindow.closed) {
          this.paymentWindow.close();
        }
      })
    );
  }

  getLoadingStatusString() {
    if (this.cancelPayment) {
      return "Zahlung abgebrochen..."
    } else if (this.waitingOnPaymentSuccess) {
      return "Zahlungsvorgang..."
    } else if (this.successfulPayment) {
      return "Zahlung erfolgreich"
    } else {
      return "Laden..."
    }
  }


  private async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  isPaymentLinkExpired(expirationDateSeconds: number | undefined | null, paymentLinkStatus: string | undefined): boolean {
    if (!expirationDateSeconds) {
      return false;
    }
    if (paymentLinkStatus?.toUpperCase() === 'APPROVED') {
      return false;
    }
    const expirationDate = new Date(expirationDateSeconds * 1000);
    const now = new Date();
    return expirationDate < now;
  }

  getLeftPaymentTime(unixSeconds: number | undefined | null): string {
    if (!unixSeconds) {
      return "Kein Ablaufdatum";
    }
    const now = Math.floor(Date.now() / 1000); // Current time in unix seconds
    const diffSeconds = unixSeconds - now;

    // If date is in the past
    if (diffSeconds <= 0) {
      return "Abgelaufen";
    }

    // Calculate days and hours
    const days = Math.floor(diffSeconds / (24 * 60 * 60));
    const remainingSeconds = diffSeconds % (24 * 60 * 60);
    const hours = Math.floor(remainingSeconds / (60 * 60));

    // Format the output in German
    const daysText = days === 1 ? "Tag" : "Tage";
    const hoursText = hours === 1 ? "Stunde" : "Stunden";

    // Handle different cases
    if (days > 0 && hours > 0) {
      return `${days} ${daysText} und ${hours} ${hoursText}`;
    } else if (days > 0) {
      return `${days} ${daysText}`;
    } else if (hours > 0) {
      return `${hours} ${hoursText}`;
    } else {
      return "Weniger als eine Stunde";
    }
  }

  closePaymentWindow(): void {
    if (this.paymentWindow) {
      this.paymentWindow.close();
      this.paymentWindow = null;
    }
  }

  getPaymentLinkErrorMessage(paymentLink: PaymentWebsitePaymentLink | undefined | null): string {
    return "Kein Cash"
  }

  notifySupport(invoiceWithPaymentLink: PaymentWebsiteInvoiceWithPaymentLink) {
    const subject = `Zahlungsproblem - Rechnung ${invoiceWithPaymentLink.invoice.invoice_id_string}`;
    const body = `Problem mit Zahlung für Rechnung ${invoiceWithPaymentLink.invoice.invoice_id_string}\n` +
      `Status: ${invoiceWithPaymentLink.paymentLink?.payment_status}\n` +
      `Ihr Anliegen: `;

    // Open default email client
    window.location.href = `mailto:${this.configService.getString("email")}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    alert("Support benachrichtigt")
  }

  isPaymentApproved(invoice: any): boolean {
    return invoice?.paymentLink?.payment_status?.toUpperCase() === 'APPROVED';
  }

  isPaymentFailed(invoice: any): boolean {
    return invoice?.paymentLink?.payment_status?.toUpperCase() === 'FAILED';
  }

  UNRECOVERABLE_PAYMENT_STATUSES = ['FRAUD', 'BLOCKED', 'SECURITY_VIOLATION']

  hasUnrecoverablePaymentError(invoice: PaymentWebsiteInvoiceWithPaymentLink): boolean {
    return this.UNRECOVERABLE_PAYMENT_STATUSES.includes(invoice?.paymentLink?.payment_status?.toUpperCase() || "");

  }


}
