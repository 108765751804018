// src/app/services/config.service.ts
import { Injectable } from '@angular/core';
import { customer1Config } from '../config/customer1.config';
import { customer2Config } from '../config/customer2.config';
import {CustomerConfig} from "../CustomerConfig";
import {environment} from "../../environments/defaultEnvironment";
import {defaultConfig} from "../config/default.config";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: CustomerConfig;

  constructor() {
    this.config = this.getConfig();
  }

  private getConfig(): CustomerConfig {
    switch (environment.customer) {
      case 'customer1':
        return customer1Config;
      case 'customer2':
        return customer2Config;
      default:
        return defaultConfig; // fallback to default
    }
  }

  getIcon(key: keyof CustomerConfig['icons']): string {
    return this.config.icons[key];
  }

  getString(key: keyof CustomerConfig['strings']): string {
    return this.config.strings[key];
  }
}
