<div class="page-container">

  <div class="scs-flex-c" style="max-width: 1000px">

    <img src="../../../assets/images/datenschutz-sued.jpg" alt="Datenschutz-Sued"
         style="width: 10vw; align-self: start">

    <h1 class="color-primary days-one font-size-h1" style="margin-top: 5rem">
      Datenschutzerklärung
    </h1>

    <p>
      Wir freuen uns über Ihren Besuch auf unserer Paymentwebseite.
      Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13 Datenschutz-Grundverordnung (DSGVO)
      informieren.
    </p>

    <p>
      Bitte beachten Sie, dass diese Datenschutzerklärung Änderungen bei Verfahrensanpassungen unterliegen kann.
    </p>

    <h4 class="days-one">
      Verantwortlicher
    </h4>

    <p>
      Verantwortlich für die nachfolgend dargestellten Datenverarbeitungen ist die im Impressum genannte Stelle.
    </p>

    <h4 class="days-one">
      Nutzungsdaten
    </h4>

    <p>
      Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver temporär sogenannte Nutzungsdaten zu
      statistischen
      Zwecken als Protokoll ausgewertet, um die Qualität unserer Webseiten zu verbessern. Dieser Datensatz besteht aus
    </p>

    <ul>
      <li>dem Namen und der Adresse der angeforderten Inhalte,</li>
      <li>dem Datum und der Uhrzeit der Abfrage,</li>
      <li>der übertragenen Datenmenge,</li>
      <li>dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden),</li>
      <li>der Beschreibung des verwendeten Webbrowsers und des Betriebssystems,</li>
      <li>dem Referral-Link, der angibt, von welcher Seite Sie auf unsere gelangt sind,</li>
      <li>der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar
        ist.
      </li>
    </ul>

    <p>
      Die genannten Protokolldaten werden nur anonymisiert ausgewertet.
    </p>

    <p>
      Rechtsgrundlage der Verarbeitung der Nutzungsdaten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Verarbeitung erfolgt
      in
      dem berechtigten Interesse, die Inhalte der Webseite bereitzustellen und eine Geräte- sowie Browseroptimierte
      Darstellung sicherzustellen.
    </p>

    <p>
      Beschreibung der Datenverarbeitung, Zwecke und Datenarten im Rahmen der Nutzung der Paymentfunktion auf unserer
      Webseite
    </p>

    <p>
      Auf unserer Webseite wird Ihnen die Möglichkeit eröffnet, Ihr Parkticket online zu bezahlen bzw. sich eine
      entsprechende Rechnung zuschicken zu lassen. Hierfür können Sie das Kennzeichen Ihres geparkten Kfz´s eingeben und
      den entsprechenden Parkvorgang auswählen um dann den Bezahlvorgang zu starten. Aktuell senden wir Ihnen die für
      Ihren Parkvorgang relevante Rechnung per E-Mail zu. Sie müssen daher im Rahmen des Bezahlvorgangs uns eine
      entsprechende E-Mail-adresse angeben.
    </p>

    <p>
      Je nach Art und Umfang der Nutzung der Paymentwebseite werden verschiedene Arten von Daten erhoben bzw.
      verarbeitet. Hierzu gehören insbesondere:
    </p>

    <ul>
      <li>Kfz-Kennzeichen</li>
      <li>E-Mail- Adresse</li>
    </ul>

    <p>
      Nachfolgend möchten wir Sie näher über den Umfang der Datenverarbeitung informieren.
    </p>

    <h3 class="days-one">
      Erforderliche Daten und Funktionen
    </h3>

    <p>
      Um unsere Paymentfunktion nutzen zu können, müssen Sie Ihr Kfz-Kennzeichen und den dazugehörigen Parkvorgang
      angeben. Danach senden wir Ihnen die konkrete Rechnung an die von Ihnen angegebene E-Mail-Adresse. Im Anschluss
      können Sie dann die entsprechende Rechnung bezahlen.
    </p>

    <p>
      Im Übrigen werden sonst keine Daten unserer Kunden im Rahmen der Nutzung der Paymentwebseite benötigt.
    </p>

    <h4 class="days-one">
      Rechtsgrundlagen für die Datenverarbeitung
    </h4>

    <p>
      Die Verarbeitung Ihrer Daten erfolgt im Rahmen der Nutzung unserer Paymentwebseite auf Grundlage von Art. 6 Abs.1
      S.1 lit. a DSGVO freiwillig. Sie können unseren Paymentservice nutzen müssen dies aber nicht zwingend. Sie können
      Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Bitte beachten Sie, dass Verarbeitungen, die
      vor
      dem Widerruf erfolgt sind, davon nicht betroffen sind. Wenden Sie sich dann an unseren Datenschutzbeauftragten,
      wenn
      Sie Ihre Einwilligung widerrufen wollen.
    </p>

    <h4 class="days-one">
      Weitergabe Ihrer Daten
    </h4>

    <p>
      Wir übermitteln Ihre Daten grundsätzlich nicht an Dritte. Eine Weitergabe erfolgt nur, sofern die Daten gerade zur
      Weitergabe bestimmt sind, Sie vorher ausdrücklich in die Übermittlung eingewilligt haben oder wir aufgrund
      gesetzlicher Vorschriften hierzu verpflichtet bzw. berechtigt sind.
    </p>

    <h4 class="days-one">
      Speicherort der personenbezogenen Daten
    </h4>

    <p>
      Bei der Verarbeitung Ihrer personenbezogenen Daten, welche Sie im Rahmen der Nutzung unserer Paymentwebseite zur
      Verfügung stellen, werden wir von Auftragsverarbeitern unterstützt. Alle beauftragten Unternehmen sind nach den
      Regelungen der DSGVO gemäß Art. 28 DSGVO zur weisungsgebundenen Datenverarbeitung verpflichtet. Als
      Verantwortlicher
      haben wir das ausschließliche Weisungsrecht und können die Verarbeitung der personenbezogenen Daten durchgängig
      kontrollieren und sicherstellen
    </p>

    <p>
      Die personenbezogenen Daten werden, sofern nichts anderes nachfolgend beschrieben wird, ausschließlich auf den
      Servern innerhalb Europäischen Union verarbeitet.
    </p>

    <h4 class="days-one">
      Löschung Ihrer Daten
    </h4>

    <p>
      Wir verarbeiten Ihre Daten grundsätzlich nur solange, wie sie für die Zwecke, für die sie erhoben worden sind,
      erforderlich sind. Sobald Ihnen Ihre Rechnung per E-Mail zugestellte wurde, werden Ihre Daten umgebend gelöscht.
      Ihre Daten werden daher nicht gespeichert.
    </p>

    <h4 class="days-one">
      Ihre Rechte als betroffene Person
    </h4>

    <p>
      Sie haben gemäß Art. 15 DSGVO das Recht auf Auskunft seitens des Verantwortlichen über die Sie betreffenden
      personenbezogenen Daten sowie auf Berichtigung unrichtiger Daten gemäß Art. 16 DSGVO oder auf Löschung, sofern
      einer
      der in Art. 17 DSGVO genannten Gründe vorliegt, z.B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt
      werden. Sie haben zudem das Recht auf Einschränkung der Verarbeitung, wenn eine der in Art. 18 DSGVO genannten
      Voraussetzungen vorliegt und in den Fällen des Art. 20 DSGVO das Recht auf Datenübertragbarkeit. In Fällen, in
      denen
      wir Ihre personenbezogenen Daten auf der Rechtsgrundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeiten, haben
      Sie
      zudem das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit Widerspruch einzulegen.
      Wir
      verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende
      schutzwürdige
      Gründe für die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen, oder die
      Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
    </p>

    <p>
      Sie haben zudem das Recht hat auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die
      Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht
      kann
      insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat des Aufenthaltsorts der betroffenen Person oder des
      Orts des mutmaßlichen Verstoßes geltend gemacht werden.
    </p>

    <h4 class="days-one">
      Kontaktdaten der Datenschutzbeauftragten
    </h4>

    <p>
      Unser externer Datenschutzbeauftragter steht Ihnen gerne für Auskünfte zum Thema Datenschutz unter folgenden
      Kontaktdaten zur Verfügung:
    </p>

    <p>
      Rechtsanwalt Laumann<br>
      Floßplatz 11<br>
      04107 Leipzig
    </p>

    <p>
      Wenn Sie sich an unseren Datenschutzbeauftragten wenden, geben Sie hierbei bitte auch den Verantwortlichen an.
    </p>

  </div>

</div>
