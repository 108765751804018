import {Component, Inject, OnInit} from '@angular/core';
import {Invoice} from "../../../../../../models/Invoice";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiCallWrapperService} from "../../../../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../../../../services/api/api-routes.service";
import {GlobalStateService} from "../../../../../../services/global-state.service";

export interface SendInvoiceToEmailDialogComponentData {
  invoice: Invoice
}

@Component({
    selector: 'app-send-invoice-to-email-dialog',
    templateUrl: './send-invoice-to-email-dialog.component.html',
    styleUrls: ['./send-invoice-to-email-dialog.component.css'],
    standalone: false
})
export class SendInvoiceToEmailDialogComponent implements OnInit {

  emailAddressFormControl = new FormControl('', [Validators.required, Validators.email]);

  sendingEmailInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SendInvoiceToEmailDialogComponentData,
    private apiCallHelper: ApiCallWrapperService,
    private apiRouteService: ApiRoutesService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SendInvoiceToEmailDialogComponent>,
    private globalStateService: GlobalStateService
  ) {

  }

  ngOnInit() {
    if (this.data.invoice.email != null) {
      this.emailAddressFormControl.setValue(this.data.invoice.email);
    }
  }

  onConfirm() {
    if (this.sendingEmailInProgress) {
      return;
    }

    const emailAddress = this.emailAddressFormControl.value;

    if (this.emailAddressFormControl.invalid || emailAddress == null) {
      this.snackBar.open("Bitte geben Sie eine gültige E-Mail-Adresse ein.", "", {duration: 3000});
      return;
    }

    this.sendingEmailInProgress = true;
    let normalizedLicensePlate = this.globalStateService.toNormalizedLicensePlate(this.data.invoice.license_plate)
    this.apiCallHelper.call(this.apiRouteService.sendInvoicesToEmail(
      normalizedLicensePlate,
      [this.data.invoice.invoice_id],
      emailAddress
    )).then(() => {
        this.sendingEmailInProgress = false;
        this.snackBar.open("Rechnung wurde erfolgreich an " + emailAddress + " gesendet.", "", {duration: 3000});
        this.dialogRef.close();
      },
      () => {
        this.sendingEmailInProgress = false;
        this.snackBar.open("Rechnung konnte nicht an " + emailAddress + " gesendet werden.", "", {duration: 3000});
      });
  }

}
