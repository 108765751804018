import {Component, OnInit} from '@angular/core';
import {ApiCallWrapperService} from "../../../../services/api/api-call-wrapper.service";
import {ManagementApiRoutesService} from "../../../../services/api/management-api-routes";
import {ParkingLotsCacheService} from "../../../../services/parking-lots-cache.service";
import {ParkingLot} from "../../../../models/ParkingLot";
import {TariffStructure} from "../../../../models/TariffStructure";
import {BehaviorSubject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {TariffSettingDialogComponent} from "../../../user-page/tariff-setting-dialog/tariff-setting-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {
  SendInvoiceToEmailDialogComponent
} from "../invoice-management-tab/invoice-details-dialog/send-invoice-to-email-dialog/send-invoice-to-email-dialog.component";
import {
  TariffSettingEditDialogComponent,
  TariffSettingEditDialogComponentData
} from "./tariff-setting-edit-dialog/tariff-setting-edit-dialog.component";
import {CustomTariffDescription} from "../../../../models/CustomTariffDescription";
import {ApiRoutesService} from "../../../../services/api/api-routes.service";
import {
  CustomTariffInfoDialogComponent
} from "../../../user-page/custom-tariff-info-dialog/custom-tariff-info-dialog.component";

@Component({
    selector: 'app-tariff-setting-management-tab',
    templateUrl: './tariff-setting-management-tab.component.html',
    styleUrls: ['./tariff-setting-management-tab.component.css'],
    standalone: false
})
export class TariffSettingManagementTabComponent implements OnInit {

  parkingLotFilter: string = '';

  parkingLots: ParkingLot[] = [];
  tariffStructures: BehaviorSubject<TariffStructure[]> = new BehaviorSubject<TariffStructure[]>([]);
  customTariffStructures: BehaviorSubject<CustomTariffDescription[]> = new BehaviorSubject<CustomTariffDescription[]>([]);

  //Existing tariff structure table data setup
  displayedColumnsExistingTariffStructuresTable: string[] = [
    'parking_lot_id',
    'parking_lot',
    'tariff-info',
    'tariff-change'
  ];
  dataSourceExistingTariffStructuresTable: MatTableDataSource<TariffStructure> = new MatTableDataSource<TariffStructure>([]);
  dataSourceCustomTariffStructures: MatTableDataSource<CustomTariffDescription> = new MatTableDataSource<CustomTariffDescription>([]);

  displayedColumnsExistingCustomTariffStructuresTable: string[] = [
    'parking_lot_id',
    'name',
    'tariff-info',
  ];

  //Not existing tariff structure table data setup
  displayedColumnsNotExistingTariffStructuresTable: string[] = [
    'parking_lot_id',
    'parking_lot',
    'tariff-creation'
  ];
  dataSourceNotExistingTariffStructuresTable: MatTableDataSource<number> = new MatTableDataSource<number>([]);

  constructor(
    private apiCallHelper: ApiCallWrapperService,
    private managementApiRoutesService: ManagementApiRoutesService,
    private apiRoutesService: ApiRoutesService,
    private parkingLotsCache: ParkingLotsCacheService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.parkingLotsCache.getParkingLots().then((parkingLots) => {
      this.parkingLots = parkingLots;

      this.reloadTariffStructures();
    });
  }

  async reloadTariffStructures() {
    await this.apiCallHelper.call(
      this.managementApiRoutesService.getAllExistingTariffStructures()
    ).then((tariffStructures) => {
      this.tariffStructures.next(tariffStructures?.sort((a, b) => a.parking_lot_id - b.parking_lot_id) ?? []);
      this.updateExistingTariffStructures();
    });

    await this.apiCallHelper.call(
      this.apiRoutesService.getParkingLotsWithCustomTariff()
    ).then((customTariffStructures) => {

      this.customTariffStructures.next(customTariffStructures?.sort((a, b,) => a.parking_lot_id - b.parking_lot_id) ?? []);
      this.updateCustomTariffStructures();
    });

    this.updateDataSourceNotExistingTariffStructuresTable();
  }

  updateDataSourceNotExistingTariffStructuresTable() {
    const existingTariffStructureParkingLotIds = new Set(
      this.tariffStructures.value.map(tariffStructure =>
        tariffStructure.parking_lot_id)
    );

    const existingCustomTariffStructureIds = new Set(
      this.customTariffStructures.value.map(customTariffStructure =>
        customTariffStructure.parking_lot_id)
    );

    this.dataSourceNotExistingTariffStructuresTable.data = this.parkingLots.filter(parkingLot =>
      !existingTariffStructureParkingLotIds.has(parkingLot.id) && !existingCustomTariffStructureIds.has(parkingLot.id)
    ).map(parkingLot => parkingLot.id);
  }

  onFilterChange() {
    this.updateExistingTariffStructures();
    this.updateCustomTariffStructures();
  }

  getParkingLot(parkingLotId: number): string {
    return this.parkingLots.find(parkingLot => parkingLot.id === parkingLotId)?.name ?? "-";
  }

  openTariffInfoDialog(parkingLotId: number) {
    const parkingLot = this.parkingLots.find(parkingLot => parkingLot.id == parkingLotId);
    if (parkingLot == null) {
      console.log("could not find the parking lot")
      return;
    }

    this.dialog.open(TariffSettingDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh',
      data: {
        parkingLot: parkingLot
      },
      autoFocus: false
    });
  }

  openCustomTariffInfoDialog(customTariffDescription: CustomTariffDescription) {
    this.dialog.open(CustomTariffInfoDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh',
      data: {
        customTariffDescription: customTariffDescription
      },
      autoFocus: false
    });
  }

  openEditTariffStructureDialog(parkingLotId: number, tariffStructure: TariffStructure | null) {
    const parkingLot = this.parkingLots.find(parkingLot => parkingLot.id == parkingLotId);
    if (parkingLot == null) {
      return;
    }

    this.dialog.open(TariffSettingEditDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: {
        parkingLot: parkingLot,
        currentTariffStructure: tariffStructure
      },
      autoFocus: false
    }).afterClosed().subscribe(() => {
      this.reloadTariffStructures();
    });
  }

  parkingLotFilterFunction: (tariffStructure: TariffStructure) => boolean = (tariffStructure: TariffStructure) => {
    const parkingLotName = this.getParkingLot(tariffStructure.parking_lot_id);
    return parkingLotName.toLowerCase().includes(this.parkingLotFilter.toLowerCase().trim());
  };

  customTariffStructureFilterFunction: (customTariffDescription: CustomTariffDescription) => boolean = (customTariffDescription: CustomTariffDescription) => {
    return customTariffDescription.name.toLowerCase().includes(this.parkingLotFilter.toLowerCase().trim());
  }

  updateExistingTariffStructures() {
    this.dataSourceExistingTariffStructuresTable.data = this.tariffStructures.value.filter(this.parkingLotFilterFunction);
  }

  updateCustomTariffStructures() {
    this.dataSourceCustomTariffStructures.data = this.customTariffStructures.value.filter(this.customTariffStructureFilterFunction)
  }

}
