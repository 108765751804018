<mat-card
  [@paymentSuccess]="paymentStates[invoiceWithPaymentLink.invoice.invoice_id_string]"
  [ngClass]="{
  'pulsating-card': isPaymentFailed(invoiceWithPaymentLink) && !isPaymentLinkExpired(invoiceWithPaymentLink),
  'unrecoverable-error-card': hasUnrecoverablePaymentError(invoiceWithPaymentLink)
}" style="margin: 15px; border-radius: 24px; padding: 10px 0;">

  <div style="display: flex; flex-direction: row; align-items: center;justify-content: center;">

    <!--          <mat-checkbox color="primary" style="width: 2rem; min-width: 2rem"-->
    <!--                        (change)="selectionChange(invoiceWithPaymentLink, $event.checked)">-->
    <!--          </mat-checkbox>-->

    <div style="display: flex; flex-direction: column; padding: 5px;">

      <div style="display: flex; flex-direction: row; align-items: center; width: fit-content;margin-bottom:10px;">
                <span class="font-size-h3 days-one" style="width: 150px; max-width: 150px; word-break: break-all;color: #198cc8;">
                  #{{ invoiceWithPaymentLink.invoice.invoice_id_string }}
                </span>

<!--        <span class="">{{ invoiceWithPaymentLink.invoice.invoice_id_string }}</span>-->
      </div>

      <div style="display: flex; flex-direction: row; align-items: center; width: fit-content">
                <span class="color-primary" style="width: 150px; max-width: 150px; word-break: normal">
                 Erstellt:
                </span>

        <span class="days-one font-size-h3">
                {{ invoiceWithPaymentLink.invoice.created_at * 1000 | date: 'dd.MM.yyyy HH:mm' }}
              </span>
      </div>

      <div style="display: flex; flex-direction: row; align-items: center; width: fit-content">
                <span class="color-primary " style="width: 150px; max-width: 150px; word-break: normal;">
                  Parkvorgänge:
                </span>

        <app-parking-operation-with-hover
          [invoice]="invoiceWithPaymentLink.invoice"
          [parkingLots]="parkingLots"
          class="parking-operations-margin">
        </app-parking-operation-with-hover>
      </div>

      <div style="display: flex; flex-direction: row; align-items: center; width: fit-content">
                <span class="color-primary" style="width: 150px; max-width: 150px; word-break: normal;">
                  Betrag:
                </span>

        <span class="days-one font-size-h3">
                    {{ getFormattedTotalFee(invoiceWithPaymentLink.invoice) }}
                  </span>
      </div>


      <div
        *ngIf="!invoiceWithPaymentLink.paymentLink?.payment_date && !isPaymentLinkExpired(invoiceWithPaymentLink)"
        style="display: flex; flex-direction: row; align-items: center; justify-content: center; gap: 8px">
  <span class="color-primary d-flex align-items-center">
    <mat-icon
      [style.color]="getUrgencyColor(invoiceWithPaymentLink.invoice.due_date)"
      [class.pulse-animation]="isWithin12Hours(invoiceWithPaymentLink.invoice.due_date)"
      style="transform: scale(1.2)">
      schedule
    </mat-icon>
  </span>
        <span class="d-flex align-items-center">
    <ng-container *ngIf="invoiceWithPaymentLink.invoice.due_date; else noExpirationdate">
      <ng-container *ngIf="!isPaymentLinkExpired(invoiceWithPaymentLink)">
        <span
          class="days-one font-size-h3"
          style="font-size: 16px;"
          [style.color]="getUrgencyColor(invoiceWithPaymentLink.invoice.due_date)"
        >
          {{ getLeftPaymentTime(invoiceWithPaymentLink.invoice.due_date) }}
        </span>
      </ng-container>
    </ng-container>
    <ng-template #noExpirationdate>
      /
    </ng-template>
  </span>
      </div>

      <div *ngIf="invoiceWithPaymentLink.paymentLink?.payment_status === 'FAILED'"
           style="display: flex; flex-direction: row; align-items: center; width: fit-content;">
    <span class="color-primary days-one" style="width: 150px; max-width: 150px; word-break: normal; color: #dc3545;">
      Zahlungsfehler:
    </span>
        <span style="color: #dc3545;">
      {{ getPaymentLinkErrorMessage(invoiceWithPaymentLink?.paymentLink) }}
    </span>
      </div>

      <div style="display: flex; flex-direction: row; align-items: center; width: fit-content;margin-top:10px;">

        <div class="scs-flex-r"
             style="width: fit-content; min-width: fit-content; margin: 0 10px; text-align: center">
          <button mat-fab extended

                  style="height: 40px;  box-shadow: none !important;
"

                  (click)="downloadInvoicePdf(invoiceWithPaymentLink.invoice)">
            <mat-icon>download</mat-icon>
            Rechnung
          </button>

          <!--                <ng-template #spinner>-->
          <!--                  <mat-spinner color="primary" style="width: 2rem; height: 2rem"></mat-spinner>-->
          <!--                  <span style="font-size: smaller; margin-left: 5px; align-self: center">laden...</span>-->
          <!--                </ng-template>-->
        </div>

        <div class="scs-flex-r"
             style="width: fit-content; min-width: fit-content; margin: 0 10px; text-align: center">
          <button mat-fab extended
                  style="height: 40px;  box-shadow: none !important;"
                  [disabled]="invoiceWithPaymentLink.paymentLink?.payment_date==null"
                  (click)="downloadReceiptPdf(invoiceWithPaymentLink.invoice)">
            <mat-icon>download</mat-icon>
            Quittung
          </button>

          <!--                <ng-template #spinner>-->
          <!--                  <mat-spinner color="primary" style="width: 2rem; height: 2rem"></mat-spinner>-->
          <!--                  <span style="font-size: smaller; margin-left: 5px; align-self: center">laden...</span>-->
          <!--                </ng-template>-->
        </div>
      </div>


      <div>
        <button
          mat-icon-button
          style="position: absolute; right: 10px; top: 10px; transform: scale(1.5);"
          (click)="notifySupport(invoiceWithPaymentLink)"
          matTooltip="Support kontaktieren"
        >
          <mat-icon style="color: #808080">help_outline</mat-icon>
        </button>
      </div>

    </div>


  </div>


  <div style="display: flex; flex-direction: row; align-items: center; width: 100%;justify-content: center;">

    <ng-container *ngIf="invoiceWithPaymentLink.paymentLink; else noPaymentLink">
      <!-- Payment Status Container -->
      <ng-container [ngSwitch]="true">

        <!-- Approved Payment -->
        <span *ngSwitchCase="isPaymentApproved(invoiceWithPaymentLink)"
              class="payment-status-approved"
              style="height:40px;width:80%;margin:0 10px;">
      <mat-icon class="payment-success-icon">check_circle</mat-icon>
      <span class="payment-status-text">Bezahlt</span>
    </span>

        <!-- Unrecoverable Error -->
        <div *ngSwitchCase="hasUnrecoverablePaymentError(invoiceWithPaymentLink)"
             class="payment-error-notice">
          <mat-icon color="warn">error</mat-icon>
          <span>Zahlung wurde aus Sicherheitsgründen gesperrt.</span>
        </div>

        <!-- Default Payment Flow -->
        <ng-container *ngSwitchDefault>
          <ng-container
            *ngIf="!isPaymentLinkExpired(invoiceWithPaymentLink); else paymentLinkExpired">
            <!-- Initial Payment Button -->
            <button
              *ngIf="!checkoutInProgress"
              mat-fab extended
              [style.background-color]="isPaymentFailed(invoiceWithPaymentLink) ? '#ff9900' : '#198cc8'"
              style="height:40px;width:80%;margin:10px;padding: 1.5rem;box-shadow:none;color:white;"
              (click)="initiatePayment(invoiceWithPaymentLink)"
            >
              <mat-icon>payment</mat-icon>
              <span>{{ isPaymentFailed(invoiceWithPaymentLink) ? 'Erneut versuchen' : 'Bezahlen' }}</span>
            </button>

            <!-- Loading Button -->
            <button
              mat-fab
              extended
              class="pay-button"
              color="primary"
              style="height:40px;width:80%;margin:10px;box-shadow:none;padding: 1.5rem;"
              [disabled]="true"
              *ngIf="checkoutInProgress"
            >
              <div class="spinner-container">
                <mat-spinner diameter="20"></mat-spinner>
              </div>
              {{ getLoadingStatusString() }}
            </button>
          </ng-container>

          <!-- Expired Payment Link -->
          <ng-template #paymentLinkExpired>
        <span class="payment-status-expired">
          <span class="payment-status-text" style="color:#d32f2f">Frist abgelaufen</span>
        </span>
          </ng-template>
        </ng-container>

      </ng-container>
    </ng-container>

    <ng-template #noPaymentLink>
      <ng-container>
        <ng-container
          *ngIf="!isPaymentLinkExpired(invoiceWithPaymentLink); else paymentLinkExpired">
          <!-- Initial Payment Button -->
          <button
            *ngIf="!checkoutInProgress"
            mat-fab extended
            [style.background-color]="isPaymentFailed(invoiceWithPaymentLink) ? '#ff9900' : '#198cc8'"
            style="height:40px;width:80%;margin:10px;padding: 1.5rem;box-shadow:none;color:white;"
            (click)="initiatePaymentWithMissingPaymentLink(invoiceWithPaymentLink)"
          >
            <mat-icon>payment</mat-icon>
            <span>{{ isPaymentFailed(invoiceWithPaymentLink) ? 'Erneut versuchen' : 'Bezahlen' }}</span>
          </button>

          <!-- Loading Button -->
          <button
            mat-fab
            extended
            class="pay-button"
            color="primary"
            style="height:40px;width:80%;margin:10px;box-shadow:none;padding: 1.5rem;"
            [disabled]="true"
            *ngIf="checkoutInProgress"
          >
            <div class="spinner-container">
              <mat-spinner diameter="20"></mat-spinner>
            </div>
            {{ getLoadingStatusString() }}
          </button>
        </ng-container>

        <!-- Expired Payment Link -->
        <ng-template #paymentLinkExpired>
        <span class="payment-status-expired">
          <span class="payment-status-text" style="color:#d32f2f">Frist abgelaufen</span>
        </span>
        </ng-template>
      </ng-container>

    </ng-template>

  </div>

</mat-card>
