import {Component} from '@angular/core';
import {GlobalStateService} from "../../../../services/global-state.service";

@Component({
    selector: 'app-support-center-tab',
    templateUrl: './support-center-tab.component.html',
    styleUrls: ['./support-center-tab.component.css'],
    standalone: false
})
export class SupportCenterTabComponent {

  constructor(
    public globalStateService: GlobalStateService
  ) {
  }


}
