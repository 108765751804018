import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TariffStructureService} from "../../../services/tariff-structure.service";
import {TariffStructure} from "../../../models/TariffStructure";
import {BehaviorSubject} from "rxjs";
import {ParkingLot} from "../../../models/ParkingLot";
import {ApiCallWrapperService} from "../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../services/api/api-routes.service";
import {CustomTariffDescription} from "../../../models/CustomTariffDescription";

export interface TariffSettingDialogComponentData {
  parkingLot: ParkingLot
}

@Component({
    selector: 'app-tariff-setting-dialog',
    templateUrl: './tariff-setting-dialog.component.html',
    styleUrls: ['./tariff-setting-dialog.component.css'],
    standalone: false
})
export class TariffSettingDialogComponent implements OnInit {

  tariffStructure: BehaviorSubject<TariffStructure | null | undefined> = new BehaviorSubject<TariffStructure | null | undefined>(null);
  customTariff: CustomTariffDescription | null = null;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TariffSettingDialogComponentData,
    private tariffStructureService: TariffStructureService,
    private apiCallWrapperService: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService
  ) {

  }

  async ngOnInit() {
    this.loading = true;

    let customTariff = await this.getCustomTariff(this.data.parkingLot.id);
    if (customTariff != null) {
      this.customTariff = customTariff;
      this.loading = false;
      return;
    }
    this.tariffStructureService.getOrFetchTariffStructure(
      this.data.parkingLot.id
    ).then(tariffStructure => {
      this.loading = false;
      this.tariffStructure.next(tariffStructure);
    });
  }

  async getCustomTariff(parkingLotId: number): Promise<CustomTariffDescription | null> {
    let customTariffs = await this.apiCallWrapperService.call(this.apiRoutesService.getParkingLotsWithCustomTariff());
    if (customTariffs == null) {
      return null;
    }
    let customTariff = customTariffs.find((customTariff) => customTariff.parking_lot_id == parkingLotId);
    return customTariff || null
  }

}
