<div class="page-container" style="display: flex; flex-direction: row"
>

  <mat-tab-group [(selectedIndex)]="parkingOperationsPageTabIndexService.tabIndex"
                 style="display: flex; flex-grow: 1; margin-top: 10px" color="accent">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab-label">
          Parkvorgänge
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-parking-operations-page
      >
        </app-parking-operations-page>
      </ng-template>
    </mat-tab>

    <mat-tab label="Rechnungen einsehen">
      <ng-template mat-tab-label>
        <div class="desktop-mode-only" style="max-width: 40vw">
          Rechnungen
        </div>
        <div class="mobile-mode-only" style="max-width: 40vw">
          Rechnungen
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-invoices-page></app-invoices-page>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
