<div class="full-width">

  @if (feeIntervals().length === 0) {
    <p style="text-align: center">Es gibt noch keine Staffelung</p>
  }
  <mat-list class="full-width">
    <mat-list-item *ngFor="let item of feeIntervals(); let i = index" class="fee-entry">
      <ng-container *ngIf="!item.isEditing; else editingTemplate" style="width:100%;">
        <span>{{ item.currencyControl.getFormattedFeeValueOnly() }} {{ getEntryDescription(item, i) }} {{ item.interval }} Minuten</span>
        <button mat-icon-button (click)="editItem(i)" [disabled]="editingDifferentItem(i)">
          <mat-icon>edit</mat-icon>
        </button>
      </ng-container>
      <ng-template #editingTemplate style="width:100%;">
        <mat-form-field appearance="outline" style="width:100px;">
          <input matInput required [formControl]="item.currencyControl.control" placeholder="Gebühr"
                 type="number" min="0" step="0.01"
                 style="text-align: end">
        </mat-form-field>
        <span style="margin:20px;">{{ getEntryDescription(item, i) }}</span>
        <mat-form-field appearance="outline" style="width:70px;">
          <input matInput required [(ngModel)]="item.editingInterval" type="number" placeholder="Interval (Minutes)">
        </mat-form-field>
        <button mat-icon-button (click)="saveItem(i)">
          <mat-icon>check</mat-icon>
        </button>
      </ng-template>
      @if (editingThis(i)) {
        <button mat-icon-button (click)="cancelEditing(i)" matTooltip="Editieren abbrechen">
          <mat-icon>cancel</mat-icon>
        </button>
      }

      @if (!editingThis(i)) {
        <button mat-icon-button (click)="deleteItem(i)" [disabled]="editingDifferentItem(i)" matTooltip="Entfernen">
          <mat-icon>close</mat-icon>
        </button>
      }
    </mat-list-item>
  </mat-list>
</div>
<button mat-raised-button color="primary" (click)="addNewItem()" [disabled]="anyEditing()">
  Neue Staffelung hinzufügen
</button>
