import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ParkingLot} from "../../../models/ParkingLot";
import {CustomTariffDescription} from "../../../models/CustomTariffDescription";

export interface CustomTariffInfoDialogComponentData {
  customTariffDescription: CustomTariffDescription
}

@Component({
    selector: 'app-custom-tariff-info-dialog',
    templateUrl: './custom-tariff-info-dialog.component.html',
    styleUrls: ['./custom-tariff-info-dialog.component.css'],
    standalone: false
})
export class CustomTariffInfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomTariffInfoDialogComponentData,
  ) {
  }
}
