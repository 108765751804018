// src/app/config/customer1.config.ts


import {CustomerConfig} from "../CustomerConfig";

export const defaultConfig: CustomerConfig = {
  icons: {
    tabIcon: 'SCS_Logo_transparent_blue_bold.png',
    icon: 'SCS_Logo_transparent_blue_bold.png',
  },
  strings: {
    title: 'SCS Paymentwebsite',
    footerText: "Sheesh",
    email: "info@smart-city-system.com",
    number: "+1234 5678",
    contactRef: "#",
    dataProtectionRef: "#"
  }
};
