<div class="weekday-selector">
  <button
    *ngFor="let day of weekdays"
    class="day-button"
    [class.selected]="isSelected(day.value)"
    (click)="toggleDay(day.value)"
  >
    {{ day.label }}
  </button>
</div>
