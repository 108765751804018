import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatButton, MatFabButton} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgIf} from "@angular/common";
import {GlobalStateService} from "../../../../services/global-state.service";
import {InvoiceDownloadService} from "../../../../services/invoice-download-service";
import {FeeService} from "../../../../services/fee.service";
import {filter, fromEvent, map, Observable, Subscription, switchMap, take, takeWhile, tap, timer} from "rxjs";
import {ApiCallWrapperService} from "../../../../services/api/api-call-wrapper.service";
import {TelecashPaymentServiceService} from "../../../../services/payment/telecash-payment-service.service";
import {MatIcon} from "@angular/material/icon";
import {ParkingOperationsPageTabIndexService} from "../../../../services/parking-operations-page-tab-index.service";

export interface PaymentLinkPaymentHintDialogComponentData {
  totalFee: number,
  invoiceId: string,
  invoiceIdString: string,
  emailSentSuccessfully: boolean,
  paymentLinkUrl: string,
  paymentLinkExpirationDate: number,
  externalPaymentLinkId: string,
  licensePlate: string
}


@Component({
    selector: 'app-payment-link-payment-hint-dialog',
    imports: [
        MatButton,
        MatDialogActions,
        MatDialogClose,
        MatProgressSpinner,
        NgIf,
        MatIcon,
        MatFabButton
    ],
    templateUrl: './payment-link-payment-hint-dialog.component.html',
    styleUrl: './payment-link-payment-hint-dialog.component.css'
})
export class PaymentLinkPaymentHintDialogComponent implements OnDestroy, OnInit {

  isDownloadingInvoice = false;
  private readonly ERROR_STATES:string[] = [ ];
  private readonly UNRECOVERABLE_ERROR_STATUSES = ['FRAUD','DENIED', 'FAILED', 'REJECTED'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentLinkPaymentHintDialogComponentData,
    public globalStateService: GlobalStateService,
    private invoiceDownloadService: InvoiceDownloadService,
    private feeService: FeeService,
    private apiCallWrapperService: ApiCallWrapperService,
    private telecashPaymentServiceService: TelecashPaymentServiceService,
  private dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    this.handlePayment()
  }

  private pollingSubscription?: Subscription;
  paymentApproved = false;
  pollingPaymentStatus = false;
  pollCount = 0;
  paymentError: string | null = null; // Reset error state

  ngOnDestroy() {
    // Cleanup subscription when component is destroyed
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }

  private startPollingPaymentStatus(paymentLinkId: string, licensePlate: string, testMode: boolean = false) {
    const startTime = Date.now();
    const MAX_POLLING_TIME = 4 * 120000; // 2 minutes
    const POLLING_INTERVAL = 4000; // 4 seconds
    this.pollCount = 0;
    this.pollingPaymentStatus = true;
    this.paymentError = null; // Reset error state

    this.pollingSubscription = timer(0, POLLING_INTERVAL).pipe(
      tap(() => this.pollCount++),
      takeWhile(() => {
        const timeElapsed = Date.now() - startTime;
        const withinTimeLimit = timeElapsed < MAX_POLLING_TIME &&
          !this.paymentApproved &&
          !this.paymentError;

        if (testMode) {
          return this.pollCount <= 2; // Stop after second poll in test mode
        }
        return withinTimeLimit;
      }),
      switchMap(() => this.apiCallWrapperService.call(this.telecashPaymentServiceService.getPaymentLink(paymentLinkId, licensePlate)))
    ).subscribe({
      next: (response) => {
        if (response?.payment_status.toUpperCase() === 'APPROVED') {
          this.paymentApproved = true;
          this.pollingPaymentStatus = false;
          this.closePaymentWindow();
          this.pollingSubscription?.unsubscribe();
        } else if (this.ERROR_STATES.includes(response?.payment_status.toUpperCase() || '')) {
          this.paymentError = this.getErrorMessage(response?.payment_status.toUpperCase() || '');
          this.pollingPaymentStatus = false;
          this.closePaymentWindow();
          this.pollingSubscription?.unsubscribe();
        }
      },
      error: (error) => {
        console.error('Error polling payment status:', error);
        this.paymentError = 'Ein technischer Fehler ist aufgetreten';
        this.pollingPaymentStatus = false;
        this.closePaymentWindow();
        this.pollingSubscription?.unsubscribe();
      },
      complete: () => {
        console.log(`Polling completed after ${this.pollCount} polls`);
        this.pollingPaymentStatus = false;
        if (!this.paymentApproved && !this.paymentError) {
          this.paymentError = 'Zeitüberschreitung bei der Zahlung';
        }
      }
    });
  }

  private getErrorMessage(status: string): string {
    switch (status) {
      case 'DENIED':
        return 'Zahlung wurde verweigert';
      case 'FAILED':
        return 'Zahlung ist fehlgeschlagen';
      case 'REJECTED':
        return 'Zahlung wurde abgelehnt';
      default:
        return 'Ein Fehler ist bei der Zahlung aufgetreten';
    }
  }

  handlePayment(): void {
    const paymentLinkUrl = this.data.paymentLinkUrl
    this.startPollingPaymentStatus(this.data.externalPaymentLinkId, this.data.licensePlate, false);

    this.openPaymentLink(paymentLinkUrl)
      .subscribe({
        next: () => {
          console.log('Payment completed successfully');
          // Handle successful payment
          this.handleSuccessfulPayment();
        },
        error: (error) => {
          console.error('Payment failed:', error);
          // Handle payment error
          this.handlePaymentError(error);
        },
        complete: () => {
          this.pollingPaymentStatus = false;
          this.pollingSubscription?.unsubscribe();
          // this.closePaymentWindow();
        }
      });
  }

  private handleSuccessfulPayment(): void {
    this.pollingPaymentStatus = false;
    this.paymentApproved = true;
    this.paymentError = null;
    this.pollingSubscription?.unsubscribe();
  }

  private handlePaymentError(error: any): void {
    this.pollingPaymentStatus = false;
    this.paymentApproved = false;
    this.paymentError = 'Ein Fehler ist bei der Zahlung aufgetreten';
    this.pollingSubscription?.unsubscribe();
  }

  private paymentWindow: Window | null = null;

  openPaymentLink(paymentLinkUrl: string): Observable<void> {
    // Open payment link in new window

    this.paymentWindow = window.open(
      paymentLinkUrl,
      'PaymentWindow',
      'width=800,height=600'
    );

    // Return an observable that completes when the window is closed
    return fromEvent<MessageEvent>(window, 'message').pipe(
      filter((event) => {

        // Check if the message indicates payment completion
        return event.data?.type === 'PAYMENT_COMPLETE';
      }),
      map(() => void 0),
      take(1)  // Complete after first matching event
    );
  }

  closePaymentWindow(): void {
    if (this.paymentWindow) {
      this.paymentWindow.close();
      this.paymentWindow = null;
    }
  }

  downloadInvoice() {
    this.isDownloadingInvoice = true;
    this.invoiceDownloadService.downloadInvoice(this.data.invoiceId, this.data.invoiceIdString).then(() => {
      this.isDownloadingInvoice = false;
    });
  }

  downloadReceipt() {
    this.isDownloadingInvoice = true;
    this.invoiceDownloadService.downloadReceiptPdf(this.data.invoiceId, this.data.invoiceIdString).then(() => {
      this.isDownloadingInvoice = false;
    });
  }

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

  getLocalDateTime(dateUnixSeconds: number): string {
    console.log(dateUnixSeconds)
    let localDate = new Date(dateUnixSeconds * 1000).toLocaleString();
    return localDate;
  }
}
