import {Injectable} from "@angular/core";
import {ParkingLot} from "../models/ParkingLot";
import {ApiCallWrapperService} from "./api/api-call-wrapper.service";
import {ApiRoutesService} from "./api/api-routes.service";
import {CustomTariffDescription} from "../models/CustomTariffDescription";

@Injectable({
  providedIn: 'root'
})
export class ParkingLotsCacheService {

  private parkingLots: ParkingLot[] | null = null;
  private parkingLotsWithCustomTariff: CustomTariffDescription[] | null = null;
  constructor(
    private apiRoutesService: ApiRoutesService,
    private apiCallHelper: ApiCallWrapperService
  ) {

  }

  /**
   * Queries all available parking lots.
   */
  async getParkingLots(): Promise<ParkingLot[]> {
    if (this.parkingLots != null) {
      return this.parkingLots;
    }

    return this.apiCallHelper.call(this.apiRoutesService.getParkingLots())
      .then((parkingLots) => {
        this.parkingLots = parkingLots;
        return parkingLots ?? [];
      });
  }

  async getParkingLotsWithCustomTariff(): Promise<CustomTariffDescription[]> {
    if (this.parkingLotsWithCustomTariff != null) {
      return this.parkingLotsWithCustomTariff;
    }

    return this.apiCallHelper.call(this.apiRoutesService.getParkingLotsWithCustomTariff())
      .then((parkingLotsWithCustomTariff) => {
        this.parkingLotsWithCustomTariff = parkingLotsWithCustomTariff;
        return parkingLotsWithCustomTariff ?? [];
      });
  }

}
