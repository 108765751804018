<div class="scs-flex-c scs-gap-m" style="margin: 20px">

  <h1 class="color-primary days-one font-size-h1">Parkvorgänge der Rechnung {{data.invoice.invoice_id_string}}</h1>

  <!--invoice table-->
  <table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="parking_lot">
      <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Parkplatz</th>
      <td mat-cell *matCellDef="let item">
        {{ getParkingLot(item) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Start</th>
      <td mat-cell *matCellDef="let item">
        {{ item.start_time * 1000 | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="end">
      <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Ende</th>
      <td mat-cell *matCellDef="let item">
        {{ item.end_time * 1000 | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fee">
      <th mat-header-cell class="color-primary days-one" *matHeaderCellDef>Gebühr</th>
      <td mat-cell *matCellDef="let item">
        {{getFee(item)}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div mat-dialog-actions>
    <button mat-flat-button class="color-grey" matDialogClose>Schließen</button>
  </div>

</div>
