import {Component, OnInit} from '@angular/core';
import {GlobalStateService} from "../../../../../services/global-state.service";
import {BehaviorSubject} from "rxjs";
import {ParkingLot} from "../../../../../models/ParkingLot";
import {ParkingLotsCacheService} from "../../../../../services/parking-lots-cache.service";

@Component({
    selector: 'app-parking-operation-management-step',
    templateUrl: './parking-operation-management-step.component.html',
    styleUrls: ['./parking-operation-management-step.component.css'],
    standalone: false
})
export class ParkingOperationManagementStepComponent implements OnInit {

  parkingLotSubject = new BehaviorSubject<ParkingLot[]>([]);
  reloadTriggerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public globalStateService: GlobalStateService,
    private parkingLotsCacheService: ParkingLotsCacheService
  ) {

  }

  ngOnInit(): void {
    this.parkingLotsCacheService.getParkingLots().then(parkingLots => {
      this.parkingLotSubject.next(parkingLots ?? []);
    });
  }

  triggerReload() {
    this.reloadTriggerSubject.next(null);
  }

}
