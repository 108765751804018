<div class="page-container">
  <div style="display: flex; flex-direction: row">
    <mat-tab-group style="display: flex; flex-grow: 1; margin-top: 10px" color="accent">
      <mat-tab label="Support-Center">
        <ng-template matTabContent>
          <app-support-center-tab></app-support-center-tab>
        </ng-template>
      </mat-tab>

      <mat-tab label="Rechnungsübersicht">
        <ng-template matTabContent>
          <app-invoice-management-tab></app-invoice-management-tab>
        </ng-template>
      </mat-tab>

      <mat-tab label="Tarif-Einstellungen verwalten">
        <ng-template matTabContent>
          <app-tariff-setting-management-tab></app-tariff-setting-management-tab>
        </ng-template>
      </mat-tab>

<!--      <mat-tab label="Bezahlungsstatus">-->
<!--        <ng-template matTabContent>-->
<!--          <app-payment-link-payment-status-tab></app-payment-link-payment-status-tab>-->
<!--        </ng-template>-->
<!--      </mat-tab>-->
    </mat-tab-group>

  </div>
</div>
