<meta name='viewport' content='width=device-width, initial-scale=1'>

<div>

  <div class="desktop-mode-only" style="display: flex; flex-direction: row">
    <h1 class="color-primary days-one font-size-h1" style="display: flex; flex-grow: 1">
      Rechnungen für das Kennzeichen {{ globalStateService.licensePlateInput }} erneut herunterladen oder an eine E-Mail
      Adresse versenden
    </h1>
  </div>

  <div class="mobile-mode-only" style="display: flex; flex-direction: column;margin:20px;">
    <h2 class="color-primary days-one font-size-h2" style="display: flex; flex-grow: 1; margin-bottom: 0">
      Rechnungen verwalten
    </h2>
    <h2 class="color-primary days-one font-size-h2" style="margin-top: 0">
      für {{ globalStateService.licensePlateInput }}</h2>
    <button
      mat-button
      style="width: fit-content; min-width: 50px;box-shadow: none !important;"
      class="days-one"
      color="primary"
      (click)="clearStateAndGoToLanding()">
      Kennzeichen ändern
    </button>
  </div>


  <div class="text-sm text-gray-400 " style="text-align: center;height:20px;" *ngIf="lastUpdateTime">
    <p *ngIf="finishedUpdating">Letzte Aktualisierung vor {{ getTimeSinceLastUpdate() }}</p>
    <div class="spinner" *ngIf="isUpdating;"></div>
  </div>

  <div [hidden]="invoicesWithPaymentLink.length == 0">

    <!--this version of the parking operation list will be displayed if the screen is in landscape mode-->
    <div class="landscape-mode-only" style="flex-direction: column; margin: 20px">
      <mat-card style="width: fit-content; height: fit-content;">

        <div style="display: flex; flex-direction: row; min-width: fit-content">
          <!--          <span class="color-primary days-one" style="width: 2rem; min-width: 2rem;">&lt;!&ndash;selection&ndash;&gt;</span>-->
          <span class="color-primary days-one"
                style="width: 12rem; min-width: fit-content">Rechnungsnummer</span>
          <span class="color-primary days-one"
                style="width: 12rem; min-width: fit-content">Datum der<br>Rechnungserstellung</span>
          <span class="color-primary days-one"
                style="width: 15rem; min-width: fit-content">Anzahl an Parkvorgängen<br>in der Rechnung</span>
          <span class="color-primary days-one"
                style="width: 12rem; min-width: fit-content">Rechnungsbetrag</span>
          <span class="color-primary days-one"
                style="width: 12rem; min-width: fit-content">Rechnung<br>herunterladen</span>
          <span class="color-primary days-one"
                style="width: 12rem; min-width: fit-content">Quittung <br> herunterladen</span>
          <span class="color-primary days-one"
                style="width: 12rem; min-width: fit-content">Bezahlungsfrist</span>
          <span class="color-primary days-one"
                style="width: 12rem; min-width: fit-content">Bezahlungsstatus</span>
        </div>

        <div *ngFor="let invoiceWithPaymentLink of invoicesWithPaymentLink">
          <mat-divider style="margin: 10px"></mat-divider>
          <app-invoice-with-payment-link-desktop
            [parkingLots]="parkingLots"
            [invoiceWithPaymentLink]="invoiceWithPaymentLink">
          </app-invoice-with-payment-link-desktop>
        </div>
      </mat-card>
    </div>


    <!--this version of the parking operation list will be displayed if the screen is in portrait mode-->
    <div class="portrait-mode-only" style="flex-direction: column">


      <app-invoice-with-payment-link
        *ngFor="let invoiceWithPaymentLink of invoicesWithPaymentLink"

        [invoiceWithPaymentLink]="invoiceWithPaymentLink">

      </app-invoice-with-payment-link>

      <!--      <mat-card *ngFor="let invoiceWithPaymentLink of invoicesWithPaymentLink"-->
      <!--                [@paymentSuccess]="paymentStates[invoiceWithPaymentLink.invoice.invoice_id_string]"-->
      <!--                [ngClass]="{-->
      <!--  'pulsating-card': isPaymentFailed(invoiceWithPaymentLink) && !isPaymentLinkExpired(invoiceWithPaymentLink.paymentLink?.expiration_date, invoiceWithPaymentLink.paymentLink?.payment_status),-->
      <!--  'unrecoverable-error-card': hasUnrecoverablePaymentError(invoiceWithPaymentLink)-->
      <!--}" style="margin: 15px; border-radius: 24px; padding: 10px 0;">-->

      <!--        <div style="display: flex; flex-direction: row; align-items: center;justify-content: center;">-->

      <!--          &lt;!&ndash;          <mat-checkbox color="primary" style="width: 2rem; min-width: 2rem"&ndash;&gt;-->
      <!--          &lt;!&ndash;                        (change)="selectionChange(invoiceWithPaymentLink, $event.checked)">&ndash;&gt;-->
      <!--          &lt;!&ndash;          </mat-checkbox>&ndash;&gt;-->

      <!--          <div style="display: flex; flex-direction: column; padding: 5px;">-->

      <!--            <div style="display: flex; flex-direction: row; align-items: center; width: fit-content">-->
      <!--                <span class="color-primary days-one" style="width: 150px; max-width: 150px; word-break: break-all">-->
      <!--                  Rechnung:-->
      <!--                </span>-->

      <!--              <span>{{ invoiceWithPaymentLink.invoice.invoice_id_string }}</span>-->
      <!--            </div>-->

      <!--            <div style="display: flex; flex-direction: row; align-items: center; width: fit-content">-->
      <!--                <span class="color-primary days-one" style="width: 150px; max-width: 150px; word-break: normal">-->
      <!--                  Datum der Erstellung:-->
      <!--                </span>-->

      <!--              <span>-->
      <!--                {{ invoiceWithPaymentLink.invoice.created_at * 1000 | date: 'dd.MM.yyyy HH:mm' }}-->
      <!--              </span>-->
      <!--            </div>-->

      <!--            <div style="display: flex; flex-direction: row; align-items: center; width: fit-content">-->
      <!--                <span class="color-primary days-one" style="width: 150px; max-width: 150px; word-break: normal;">-->
      <!--                  Parkvorgänge:-->
      <!--                </span>-->

      <!--              <app-parking-operation-with-hover-->
      <!--                [invoice]="invoiceWithPaymentLink.invoice"-->
      <!--                [parkingLots]="parkingLots"-->
      <!--                class="parking-operations-margin">-->
      <!--              </app-parking-operation-with-hover>-->
      <!--            </div>-->

      <!--            <div style="display: flex; flex-direction: row; align-items: center; width: fit-content">-->
      <!--                <span class="color-primary days-one" style="width: 150px; max-width: 150px; word-break: normal;">-->
      <!--                  Betrag:-->
      <!--                </span>-->

      <!--              <span>-->
      <!--                    {{ getFormattedTotalFee(invoiceWithPaymentLink.invoice) }}-->
      <!--                  </span>-->
      <!--            </div>-->


      <!--            <div-->
      <!--              *ngIf="invoiceWithPaymentLink.paymentLink?.payment_date== null && !isPaymentLinkExpired(invoiceWithPaymentLink.paymentLink?.expiration_date, invoiceWithPaymentLink.paymentLink?.payment_status)"-->
      <!--              style="display: flex; flex-direction: row; align-items: center; width: fit-content">-->
      <!--              <span class="color-primary days-one"-->

      <!--                    style="width: 150px; max-width: 150px; word-break: normal;">-->
      <!--                Verbleibende Zeit:-->
      <!--              </span>-->
      <!--              <span>-->
      <!--  <ng-container *ngIf="invoiceWithPaymentLink.paymentLink?.expiration_date; else noExpirationdate">-->
      <!--    <ng-container-->
      <!--      *ngIf=" !isPaymentLinkExpired(invoiceWithPaymentLink.paymentLink?.expiration_date, invoiceWithPaymentLink.paymentLink?.payment_status)">-->
      <!--      <span-->

      <!--        style="font-size: 14px;"-->
      <!--        [style.color]="isPaymentLinkExpired(invoiceWithPaymentLink.paymentLink?.expiration_date, invoiceWithPaymentLink.paymentLink?.payment_status) ? '#d32f2f' : 'black'"-->
      <!--      >-->
      <!--        {{ getLeftPaymentTime(invoiceWithPaymentLink.paymentLink?.expiration_date) }}-->
      <!--      </span>-->
      <!--    </ng-container>-->

      <!--  </ng-container>-->
      <!--  <ng-template #noExpirationdate>-->
      <!--    /-->
      <!--  </ng-template>-->
      <!--          </span>-->
      <!--            </div>-->

      <!--            <div *ngIf="invoiceWithPaymentLink.paymentLink?.payment_status === 'FAILED'"-->
      <!--                 style="display: flex; flex-direction: row; align-items: center; width: fit-content;">-->
      <!--    <span class="color-primary days-one" style="width: 150px; max-width: 150px; word-break: normal; color: #dc3545;">-->
      <!--      Zahlungsfehler:-->
      <!--    </span>-->
      <!--              <span style="color: #dc3545;">-->
      <!--      {{ getPaymentLinkErrorMessage(invoiceWithPaymentLink?.paymentLink) }}-->
      <!--    </span>-->
      <!--            </div>-->

      <!--            <div style="display: flex; flex-direction: row; align-items: center; width: fit-content;margin-top:10px;">-->

      <!--              <div class="scs-flex-r"-->
      <!--                   style="width: fit-content; min-width: fit-content; margin: 0 10px; text-align: center">-->
      <!--                <button mat-fab extended-->

      <!--                        style="height: 40px;  box-shadow: none !important;-->
      <!--"-->

      <!--                        (click)="downloadInvoicePdf(invoiceWithPaymentLink.invoice)">-->
      <!--                  <mat-icon>download</mat-icon>-->
      <!--                  Rechnung-->
      <!--                </button>-->

      <!--                &lt;!&ndash;                <ng-template #spinner>&ndash;&gt;-->
      <!--                &lt;!&ndash;                  <mat-spinner color="primary" style="width: 2rem; height: 2rem"></mat-spinner>&ndash;&gt;-->
      <!--                &lt;!&ndash;                  <span style="font-size: smaller; margin-left: 5px; align-self: center">laden...</span>&ndash;&gt;-->
      <!--                &lt;!&ndash;                </ng-template>&ndash;&gt;-->
      <!--              </div>-->

      <!--              <div class="scs-flex-r"-->
      <!--                   style="width: fit-content; min-width: fit-content; margin: 0 10px; text-align: center">-->
      <!--                <button mat-fab extended-->
      <!--                        style="height: 40px;  box-shadow: none !important;"-->
      <!--                        [disabled]="invoiceWithPaymentLink.paymentLink?.payment_date==null"-->
      <!--                        (click)="downloadReceiptPdf(invoiceWithPaymentLink.invoice)">-->
      <!--                  <mat-icon>download</mat-icon>-->
      <!--                  Quittung-->
      <!--                </button>-->

      <!--                &lt;!&ndash;                <ng-template #spinner>&ndash;&gt;-->
      <!--                &lt;!&ndash;                  <mat-spinner color="primary" style="width: 2rem; height: 2rem"></mat-spinner>&ndash;&gt;-->
      <!--                &lt;!&ndash;                  <span style="font-size: smaller; margin-left: 5px; align-self: center">laden...</span>&ndash;&gt;-->
      <!--                &lt;!&ndash;                </ng-template>&ndash;&gt;-->
      <!--              </div>-->
      <!--            </div>-->


      <!--            <div>-->
      <!--              <button-->
      <!--                mat-icon-button-->
      <!--                style="position: absolute; right: 10px; top: 10px; transform: scale(1.5);"-->
      <!--                (click)="notifySupport(invoiceWithPaymentLink)"-->
      <!--                matTooltip="Support kontaktieren"-->
      <!--              >-->
      <!--                <mat-icon style="color: #808080">help_outline</mat-icon>-->
      <!--              </button>-->
      <!--            </div>-->

      <!--          </div>-->


      <!--        </div>-->


      <!--        <div style="display: flex; flex-direction: row; align-items: center; width: 100%;justify-content: center;">-->

      <!--          <ng-container *ngIf="invoiceWithPaymentLink.paymentLink; else noPaymentLink">-->
      <!--            &lt;!&ndash; Payment Status Container &ndash;&gt;-->
      <!--            <ng-container [ngSwitch]="true">-->

      <!--              &lt;!&ndash; Approved Payment &ndash;&gt;-->
      <!--              <span *ngSwitchCase="isPaymentApproved(invoiceWithPaymentLink)"-->
      <!--                    class="payment-status-approved"-->
      <!--                    style="height:40px;width:80%;margin:0 10px;">-->
      <!--      <mat-icon class="payment-success-icon">check_circle</mat-icon>-->
      <!--      <span class="payment-status-text">Bezahlt</span>-->
      <!--    </span>-->

      <!--              &lt;!&ndash; Unrecoverable Error &ndash;&gt;-->
      <!--              <div *ngSwitchCase="hasUnrecoverablePaymentError(invoiceWithPaymentLink)"-->
      <!--                   class="payment-error-notice">-->
      <!--                <mat-icon color="warn">error</mat-icon>-->
      <!--                <span>Zahlung wurde aus Sicherheitsgründen gesperrt.</span>-->
      <!--              </div>-->

      <!--              &lt;!&ndash; Default Payment Flow &ndash;&gt;-->
      <!--              <ng-container *ngSwitchDefault>-->
      <!--                <ng-container-->
      <!--                  *ngIf="!isPaymentLinkExpired(invoiceWithPaymentLink.paymentLink?.expiration_date, invoiceWithPaymentLink.paymentLink?.payment_status); else paymentLinkExpired">-->
      <!--                  &lt;!&ndash; Initial Payment Button &ndash;&gt;-->
      <!--                  <button-->
      <!--                    *ngIf="!checkoutInProgress"-->
      <!--                    mat-fab extended-->
      <!--                    [style.background-color]="isPaymentFailed(invoiceWithPaymentLink) ? '#ff9900' : '#198cc8'"-->
      <!--                    style="height:40px;width:80%;margin:10px;padding: 1.5rem;box-shadow:none;color:white;"-->
      <!--                    (click)="initiatePayment(invoiceWithPaymentLink)"-->
      <!--                  >-->
      <!--                    <mat-icon>payment</mat-icon>-->
      <!--                    <span>{{ isPaymentFailed(invoiceWithPaymentLink) ? 'Erneut versuchen' : 'Bezahlen' }}</span>-->
      <!--                  </button>-->

      <!--                  &lt;!&ndash; Loading Button &ndash;&gt;-->
      <!--                  <button-->
      <!--                    mat-fab-->
      <!--                    extended-->
      <!--                    class="pay-button"-->
      <!--                    color="primary"-->
      <!--                    style="height:40px;width:80%;margin:10px;box-shadow:none;padding: 1.5rem;"-->
      <!--                    [disabled]="true"-->
      <!--                    *ngIf="checkoutInProgress"-->
      <!--                  >-->
      <!--                    <div class="spinner-container">-->
      <!--                      <mat-spinner diameter="20"></mat-spinner>-->
      <!--                    </div>-->
      <!--                    {{ getLoadingStatusString() }}-->
      <!--                  </button>-->
      <!--                </ng-container>-->

      <!--                &lt;!&ndash; Expired Payment Link &ndash;&gt;-->
      <!--                <ng-template #paymentLinkExpired>-->
      <!--        <span class="payment-status-expired">-->
      <!--          <span class="payment-status-text" style="color:#d32f2f">Frist abgelaufen</span>-->
      <!--        </span>-->
      <!--                </ng-template>-->
      <!--              </ng-container>-->

      <!--            </ng-container>-->
      <!--          </ng-container>-->

      <!--          <ng-template #noPaymentLink>-->
      <!--            /-->
      <!--          </ng-template>-->

      <!--        </div>-->

      <!--      </mat-card>-->

    </div>


    <!--    <div *ngIf="!isSendingEmail" class="scs-flex-r scs-wrap scs-gap-s">-->

    <!--      <p>-->
    <!--        Geben Sie eine E-Mail Adresse ein, an die Sie die ausgewählten Rechnungen versenden möchten.-->
    <!--      </p>-->

    <!--      <mat-form-field appearance="outline" style="width: 25rem">-->
    <!--        <mat-label>E-Mail Adresse [Optional]</mat-label>-->
    <!--        <input matInput type="email" [formControl]="emailAddressFormControl" name="email_address">-->
    <!--        <mat-error *ngIf="emailAddressFormControl.hasError('email')">-->
    <!--          Ungültige E-Mail Adresse-->
    <!--        </mat-error>-->
    <!--      </mat-form-field>-->

    <!--      <button mat-flat-button color="primary" style="width: 25rem; height: 3rem; margin-top: 5px"-->
    <!--              [disabled]="!emailAddressFormControl.valid || (emailAddressFormControl.value?.length ?? 0) < 3 || selectedInvoices.length <= 0"-->
    <!--              (click)="onSendToEmail()">-->
    <!--        ausgewählte Rechnungen an E-Mail versenden-->
    <!--      </button>-->
    <!--    </div>-->

    <div *ngIf="isSendingEmail" class="scs-flex-r scs-gap-m">
      <mat-spinner style="width: 4rem; height: 4rem"></mat-spinner>
      <span style="align-self: center">
      E-Mail wird versandt, bitte warten...
    </span>
    </div>

    <div *ngIf="lastSentInvoiceIdStrings.length > 0">
      <h4 class="days-one color-primary">Zuletzt versandte Rechnungen:</h4>
      <ul>
        <li *ngFor="let invoiceId of lastSentInvoiceIdStrings">
          {{ invoiceId }}
        </li>
      </ul>
    </div>
  </div>

  <h2 *ngIf="invoicesWithPaymentLink.length == 0" class="days-one  font-size-h2">
    Es sind noch keine Rechnungen vorhanden
  </h2>

</div>
