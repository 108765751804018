import {Injectable} from '@angular/core';
import {catchError, firstValueFrom, lastValueFrom, map, Observable, of, throwError, timer} from "rxjs";

type SuccessMessageCallback<T> = (a: T | null) => string

@Injectable({
  providedIn: 'root'
})
export class ApiCallWrapperService {

  constructor(
    private snackBar: MatSnackBar
  ) {
  }

  /**
   * Executes and returns the result of it or null if the response code
   * was not 200. Opens a snackbar with the error message on error and optionally
   * on success
   */
  async call<T>(apiCall: Observable<T>, successMessage: SuccessMessageCallback<T> | null = null, displayError: boolean = true): Promise<T | null> {

    return lastValueFrom(apiCall.pipe(
      retryWithDelay(1000, 2),
      catchError(err => {
        console.log(err)
        if (displayError && err.status != 0) {
          this.snackBar.open(
            "Es ist ein Fehler aufgetreten " + err.status + (err.error == null ? "" : (": " + err.error)),
            "Schließen",
            {duration: 3000}
          );
        }
        return throwError(() => new Error(err.status))
      }),
      map((data) => {
        if (successMessage != null)
          this.snackBar.open(
            successMessage(data!),
            "Schließen",
            {duration: 3000}
          );
        return data
      })
    ).pipe(
      catchError(err => {
        return of(null)
      }),
      map((data) => {
        return data
      })
    ));

  }
}

import {MonoTypeOperatorFunction} from 'rxjs';
import {delay as delayOperator, retryWhen, scan} from 'rxjs/operators';
import {HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";

export function shouldRetry(error: HttpErrorResponse) {
  if (error.status === 503) {
    return timer(1000); // Adding a timer from RxJS to return observable to delay param.
  }

  throw error;
}

/**
 * Does not retry for status code 404
 * @param delay
 * @param count
 */
export function retryWithDelay<T>(
  delay: number,
  count = 1
): MonoTypeOperatorFunction<T> {
  return (input) =>
    input.pipe(
      retryWhen((errors) =>
        errors.pipe(
          scan((acc, error) => ({count: acc.count + 1, error}), {
            count: 0,
            error: undefined as any,
          }),
          map((current) => {
            let statusCode = current.error.status
            if (current.count > count || statusCode == 404) {
              throw current.error;
            }
            return current;
          }),
          delayOperator(delay)
        )
      )
    );
}

export function sequentialMultiRequests(apiCalls: (() => Promise<any>)[]): Promise<any> {
  if (apiCalls.length <= 0) {
    return firstValueFrom(of({}));
  }

  let nextCall = apiCalls[0];
  let remainingCalls = apiCalls.slice(1);

  return nextCall().then(() => sequentialMultiRequests(remainingCalls));
}
