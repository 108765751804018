<div class="scs-flex-c scs-gap-m" style="padding: 1rem">

  <h1 class="color-primary days-one font-size-h1" style="margin: 0">
    Tarifstruktur auf dem Parkplatz
  </h1>
  <h3 class="days-one" style="margin: 0">
    {{ data.parkingLot.name }}
  </h3>

  <div *ngIf="tariffStructure?.value != null ||customTariff!= null; else noTariffStructure">
    <app-tariff-setting-quick-overview *ngIf="tariffStructure?.value != null"
                                       [tariffStructureSubject]="tariffStructure">
    </app-tariff-setting-quick-overview>

    <div *ngIf="customTariff?.description">
      <h3>Custom Tariff</h3>
      <p>{{ customTariff?.description }}</p>
    </div>

  </div>

  <ng-template #noTariffStructure>
    <mat-progress-spinner *ngIf="loading" mode="indeterminate">Lade...</mat-progress-spinner>
    <div *ngIf="!loading">
      <h2 class="days-one">
        Auf diesem Parkplatz ist noch keine Tarifstruktur vorhanden
      </h2>
    </div>
  </ng-template>

  <div mat-dialog-actions>
    <button mat-flat-button class="color-grey" matDialogClose>Schließen</button>
  </div>

</div>
