import { Component } from '@angular/core';

@Component({
    selector: 'app-data-protection-page',
    templateUrl: './data-protection-page.component.html',
    styleUrls: ['./data-protection-page.component.css'],
    standalone: false
})
export class DataProtectionPageComponent {

}
