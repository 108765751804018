import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatButton} from "@angular/material/button";
import {NgIf} from "@angular/common";
interface PaymentData {
  totalFee: number;
  invoiceId: string;
  invoiceIdString: string;
  sentEmail: string;
  paymentLink: string;
  expirationDate: string;
  externalPaymentLinkId: string;
  licensePlate: string;
}

@Component({
    selector: 'app-payment-page',
    imports: [
        MatButton,
        NgIf
    ],
    templateUrl: './payment-page.component.html',
    styleUrl: './payment-page.component.css'
})
export class PaymentPageComponent {
  paymentData: PaymentData | null = null;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    // Get the navigation state data
    const navigation = this.router.getCurrentNavigation();
    this.paymentData = navigation?.extras?.state?.['data'];
  }

  ngOnInit() {
    // You can also handle initialization logic here
  }

  cancel() {
    this.router.navigate(['/license-plate']);
  }


  navigateToLicensePlate() {
    this.router.navigate(['/license-plate'], {
      state: { invoiceTab: true }
    });
  }
}
