<div class="spinner-container" *ngIf="isLoading; else parkingOperations">
  <mat-spinner>Suche Parkvorgänge...</mat-spinner>
</div>

<ng-template #parkingOperations>

  <app-no-existing-parking-operations-page
    *ngIf="useNoExistingParkingOperationsPage && (parkingLots.length > 0 || parkingLotsWithCustomTariffDescription.length > 0)"
    [parkingLots]="parkingLots"
    [parkingLotsWithCustomTariffDescription]="parkingLotsWithCustomTariffDescription"
    [parkingOperationsWithBookingsAndFeesSelections]="parkingOperationsWithBookingsAndFeesSelections"
    (reloadParkingOperationsTrigger)="reloadParkingOperations()">
  </app-no-existing-parking-operations-page>

  <app-existing-parking-operations-page *ngIf="!useNoExistingParkingOperationsPage"
                                        [parkingLots]="parkingLots"
                                        [parkingLotsWithCustomTariffDescription]="parkingLotsWithCustomTariffDescription"
                                        [parkingOperationsWithBookingsAndFeesSelections]="parkingOperationsWithBookingsAndFeesSelections"
                                        (reloadParkingOperationsTrigger)="reloadParkingOperations()">
  </app-existing-parking-operations-page>

</ng-template>
