import {Component, effect, input, Input, OnDestroy, OnInit, output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {BehaviorSubject, Subscription} from "rxjs";
import {TariffTimes} from "../../../../../../../models/TariffStructure";

export interface ConsecutiveFeeDescription {
  fees: number[];
  billing_interval_minutes: number;
}

@Component({
    selector: 'app-consecutive-fee-input',
    templateUrl: './consecutive-fee-input.component.html',
    styleUrls: ['./consecutive-fee-input.component.css'],
    standalone: false
})
export class ConsecutiveFeeInputComponent implements OnInit{

  mainbillingIntervalMinutes = input.required<number>();
  tariffTime = input.required<TariffTimes>();

  outputConsecutiveFee = output<ConsecutiveFeeDescription>();


  consecutiveFees: number[] = [];
  consecutiveBillingIntervals: number[] = [];

  formControl = new FormControl([]);

  constructor() {
    effect(() => {
      console.log("Billing interval change", this.mainbillingIntervalMinutes())
    });
    effect(() => {
      console.log("Tariff time change", this.tariffTime())
    });
  }

  getBillingIntervalMinutes() {
    return this.tariffTime().billing_interval_minutes || this.mainbillingIntervalMinutes();
  }

  ngOnInit() {

    this.consecutiveFees = this.tariffTime().consecutive_fees || [];
    this.consecutiveBillingIntervals = this.tariffTime().consecutive_billing_interval_minutes || [];

  }

  remove(index: number) {
    if (index >= 0 && index < this.consecutiveFees.length) {
      this.consecutiveFees.splice(index, 1);
      console.log("emiting new consecutive fees", this.consecutiveFees)
      // this.consecutiveFeeSubject.next(this.consecutiveFeeInputs.slice());
    }
  }

  add(event: MatChipInputEvent): void {
    const value = +(event.value || '').trim();

    const fee = +value.toFixed(2)

    if (!isNaN(fee)) {
      this.consecutiveFees.push(fee);
      console.log("emiting new consecutive fees", this.consecutiveFees)
      // this.consecutiveFeeSubject.next(this.consecutiveFeeInputs.slice());
    } else {
      console.log("invalid input!")
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.consecutiveFees, event.previousIndex, event.currentIndex);
    console.log("emiting new consecutive fees", this.consecutiveFees)
    // this.consecutiveFeeSubject.next(this.consecutiveFeeInputs.slice());
  }

}
