<div class="scs-flex-c" style="padding: 1rem">

  <div>
    <h1 class="days-one color-primary" style="margin-bottom: 0">Parkberechtigung hinzufügen</h1>
    <h4 class="days-one color-primary" style="margin-top: 0">{{data.parkingLot.name}}</h4>
  </div>

  <div class="scs-flex-r scs-gap-xxl">

    <div class="scs-flex-c scs-gap-l">

      <div class="scs-flex-c">
        <h4 class="days-one" style="margin-top: 0">Offener Parkvorgang:</h4>
        <div>
          <div class="scs-flex-r">
            <span style="width: 200px">Parkbeginn:</span>
            <span *ngIf="data.parkingOperation.parking_operation.start_event != null">
          {{data.parkingOperation.parking_operation.start_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm'}}
        </span>
            <span *ngIf="data.parkingOperation.parking_operation.start_event == null">unbekannt</span>
          </div>

          <div class="scs-flex-r">
            <span style="width: 200px">Parkende:</span>
            <span *ngIf="data.parkingOperation.parking_operation.end_event != null">
          {{data.parkingOperation.parking_operation.end_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm'}}
        </span>
            <span *ngIf="data.parkingOperation.parking_operation.end_event == null">unbekannt</span>
          </div>

          <div class="scs-flex-r">
            <span style="width: 200px">Parkdauer:</span>
            <span>{{getParkingDuration(data.parkingOperation)}}</span>
          </div>

          <div class="scs-flex-r">
            <span style="width: 200px">Bereits vorhanden:</span>

            <div class="scs-flex-c">
              <div class="scs-flex-r scs-gap-s" *ngFor="let booking of data.parkingOperation.bookings">

                <span>{{booking.start_time * 1000 | date: 'dd.MM.yyyy HH:mm'}}</span>
                <span> - </span>
                <span>{{booking.end_time * 1000 | date: 'dd.MM.yyyy HH:mm'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h4 class="days-one">Neue Parkberechtigung:</h4>

        <div class="scs-flex-r">
          <div class="scs-flex-c">
            <h4 class="days-one" style="margin: 0">Startzeitpunkt</h4>

            <mat-form-field appearance="outline" style="width: 200px; min-width: 100px; margin: 0 10px;">
              <input matInput type="datetime-local" placeholder="Start eingeben"
                     [formControl]="startTimeFormControl">
              <mat-error *ngIf="startTimeFormControl.hasError('startAfterEnd')">
                Start muss vor Ende liegen
              </mat-error>
              <mat-error *ngIf="startTimeFormControl.hasError('required')">
                Ungültige Startzeit
              </mat-error>
            </mat-form-field>
          </div>

          <div class="scs-flex-c">
            <h4 class="days-one" style="margin: 0">Endzeitpunkt</h4>

            <mat-form-field appearance="outline" style="width: 200px; min-width: 100px; margin: 0 10px;">
              <input matInput type="datetime-local" placeholder="Ende eingeben"
                     [formControl]="endTimeFormControl">
              <mat-error *ngIf="endTimeFormControl.hasError('startAfterEnd')">
                Start muss vor Ende liegen
              </mat-error>
              <mat-error *ngIf="endTimeFormControl.hasError('required')">
                Ungültige Endzeit
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>

      <button mat-flat-button color="primary"
              [disabled]="startTimeFormControl.invalid || endTimeFormControl.invalid"
              (click)="addBooking()">
        Parkberechtigung hinzufügen
      </button>

    </div>

    <div>
      <app-tariff-setting-quick-overview
        [tariffStructureSubject]="tariffStructureSubject">
      </app-tariff-setting-quick-overview>
    </div>

  </div>

</div>
