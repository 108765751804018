<div class="scs-flex-c scs-gap-m" style="margin: 20px">

  <h1 class="days-one" style="margin: 0">Rechnung {{data.invoice.invoice_id_string}} per E-Mail verschicken</h1>

  <p>
    Definieren Sie die E-Mail Adresse, an die diese Rechnung versandt werden soll.<br>
    Das Eingabefeld wird automatisch mit der optional definierten E-Mail Adresse des Kunden vorausgefüllt.
  </p>

  <mat-form-field appearance="outline" style="width: 30rem">
    <mat-label>E-Mail Adresse</mat-label>
    <input matInput type="email" [formControl]="emailAddressFormControl" name="email_address">
    <mat-error *ngIf="emailAddressFormControl.hasError('email')">
      Ungültige E-Mail Adresse
    </mat-error>
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-flat-button class="color-grey" matDialogClose>Schließen</button>

    <button mat-flat-button color="primary"
            [disabled]="emailAddressFormControl.invalid || sendingEmailInProgress"
            (click)="onConfirm()">
      Rechnung versenden
      <mat-progress-bar *ngIf="sendingEmailInProgress" mode="indeterminate" color="primary"></mat-progress-bar>
    </button>
  </div>

</div>
