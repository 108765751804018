import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ParkingLot} from "../../../../../../models/ParkingLot";
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {FeeService} from "../../../../../../services/fee.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ApiCallWrapperService} from "../../../../../../services/api/api-call-wrapper.service";
import moment from "moment";
import {TIME_FORMAT} from "../../../../../../models/constants";
import {TariffSettingDialogComponent} from "../../../../tariff-setting-dialog/tariff-setting-dialog.component";
import {ParkingOperationWithBookingsAndFees} from "../../../../../../models/ParkingOperation";
import {ApiRoutesService} from "../../../../../../services/api/api-routes.service";

@Component({
  selector: 'app-parking-operation-list',
  templateUrl: './parking-operation-list.component.html',
  styleUrls: ['./parking-operation-list.component.css'],
  standalone: false
})
export class ParkingOperationListComponent implements OnInit, OnDestroy {

  @Input() parkingLots!: ParkingLot[];
  @Input() parkingOperationsWithBookingsAndFeesSelections!: ParkingOperationWithBookingsAndFeesWithSelection[];
  @Input() recomputeSelectedFeesTrigger!: Observable<any>;

  @Output() selectedParkingOperationsChange = new EventEmitter<ParkingOperationWithBookingsAndFeesWithSelection[]>();
  @Output() totalSelectionFeeChange = new EventEmitter<number>();

  private subscriptions: Subscription[] = [];

  constructor(
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    private feeService: FeeService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.recomputeSelectedFees();
    this.subscriptions.push(
      this.recomputeSelectedFeesTrigger.subscribe(() => this.recomputeSelectedFees())
    );
  }

  onEditedStartTime(parkingOperationWithBookingsAndFeesWithSelection: ParkingOperationWithBookingsAndFeesWithSelection) {
    const unixStartSeconds = moment(parkingOperationWithBookingsAndFeesWithSelection.editedStartTime, TIME_FORMAT).unix();

    if (parkingOperationWithBookingsAndFeesWithSelection.editedStartTime == null ||
      parkingOperationWithBookingsAndFeesWithSelection.editedStartTime.length == 0) {
      this.recomputeSelectedFees();
      return;
    }

    parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.start_event = {
      parking_lot_id: parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.end_event?.parking_lot_id ?? -1,
      driving_direction: "in",
      time: unixStartSeconds
    }

    if (unixStartSeconds >= parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.end_event!.time) {
      this.snackBar.open("Die Startzeit muss vor der Endzeit liegen.", "", {duration: 3000});
      this.recomputeSelectedFees();
      return;
    }

    parkingOperationWithBookingsAndFeesWithSelection.endTimeFormControl.updateValueAndValidity();

    this.queryParkingOperationFee(parkingOperationWithBookingsAndFeesWithSelection);
  }

  openStartTimeEdit(parkingOperationWithSelection: ParkingOperationWithBookingsAndFeesWithSelection) {
    // Create a temporary input element
    const input = document.createElement('input');
    input.type = 'datetime-local';
    input.style.display = 'none';
    document.body.appendChild(input);

    // Set current value if exists
    if (parkingOperationWithSelection.editedStartTime) {
      input.value = parkingOperationWithSelection.editedStartTime;
    }

    // Handle the input change
    input.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      parkingOperationWithSelection.editedStartTime = target.value;
      this.onEditedStartTime(parkingOperationWithSelection);
      document.body.removeChild(input);
    });

    input.click();
  }

  openEndTimeEdit(parkingOperationWithSelection: ParkingOperationWithBookingsAndFeesWithSelection) {
    // Create a temporary input element
    const input = document.createElement('input');
    input.type = 'datetime-local';
    input.style.display = 'none';
    document.body.appendChild(input);

    // Set current value if exists
    if (parkingOperationWithSelection.editedEndTime) {
      input.value = parkingOperationWithSelection.editedEndTime;
    }

    // Handle the input change
    input.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      parkingOperationWithSelection.editedEndTime = target.value;
      this.onEditedEndTime(parkingOperationWithSelection);
      document.body.removeChild(input);
    });

    input.click();
  }

  isSameDay(parkingOperation: any): boolean {
    if (!parkingOperation.parking_operation.end_event) {
      return true; // or handle differently based on your requirements
    }

    const startDate = new Date(parkingOperation.parking_operation.start_event.time * 1000);
    const endDate = new Date(parkingOperation.parking_operation.end_event.time * 1000);

    return startDate.getDate() === endDate.getDate() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear();
  }
  onEditedEndTime(parkingOperationWithBookingsAndFeesWithSelection: ParkingOperationWithBookingsAndFeesWithSelection) {
    const unixEndSeconds = moment(parkingOperationWithBookingsAndFeesWithSelection.editedEndTime, TIME_FORMAT).unix();

    if (parkingOperationWithBookingsAndFeesWithSelection.editedEndTime == null ||
      parkingOperationWithBookingsAndFeesWithSelection.editedEndTime.length == 0) {
      this.recomputeSelectedFees();
      return;
    }

    parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.end_event = {
      parking_lot_id: parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.start_event?.parking_lot_id ?? -1,
      driving_direction: "out",
      time: unixEndSeconds
    }

    if (unixEndSeconds <= parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.start_event!.time) {
      this.snackBar.open("Die Endzeit muss nach der Startzeit liegen.", "", {duration: 3000});
      this.recomputeSelectedFees();
      return;
    }

    parkingOperationWithBookingsAndFeesWithSelection.startTimeFormControl.updateValueAndValidity();

    this.queryParkingOperationFee(parkingOperationWithBookingsAndFeesWithSelection);
  }

  private queryParkingOperationFee(parkingOperationWithBookingsAndFeesWithSelection: ParkingOperationWithBookingsAndFeesWithSelection) {
    this.apiCallHelper.call(this.apiRoutesService.getParkingFee(
      parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation
    )).then(parkingOperationWithFee => {
      if (parkingOperationWithFee == null) {
        this.snackBar.open("Leider ist bei der Abfrage der Parkgebühren etwas schief gelaufen, bitte versuchen Sie es erneut.", "", {duration: 3000})
        return;
      }
      // set end_date_editable to true

      parkingOperationWithBookingsAndFeesWithSelection.parking_operation = parkingOperationWithFee
      parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.end_event_editable = true;

      this.recomputeSelectedFees();
    });
  }

  recomputeSelectedFees() {
    const selectedParkingOperations = this.getSelectedParkingOperations();
    this.selectedParkingOperationsChange.emit(selectedParkingOperations);

    const currentTotalFee = selectedParkingOperations
      .reduce((sum, parkingOperationWithBookingsAndFeesSelection) =>
        sum + parkingOperationWithBookingsAndFeesSelection.parking_operation.fee, 0);
    this.totalSelectionFeeChange.emit(currentTotalFee);
  }

  private getSelectedParkingOperations(): ParkingOperationWithBookingsAndFeesWithSelection[] {
    return this.parkingOperationsWithBookingsAndFeesSelections.filter(parkingOperationWithBookingsAndFeesSelection =>
      parkingOperationWithBookingsAndFeesSelection.selected &&
      !this.isParkingOperationDisabled(parkingOperationWithBookingsAndFeesSelection));
  }

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

  openTariffStructureDialog(parkingLotId: number) {
    const parkingLot = this.parkingLots.find(parkingLot => parkingLot.id == parkingLotId);
    if (parkingLot == null) {
      console.log("could not find the parking lot")
      return;
    }

    this.dialog.open(TariffSettingDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh',
      data: {
        parkingLot: parkingLot
      },
      autoFocus: false
    });
  }

  getParkingLotOfParkingOperation(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): ParkingLot | null {
    const startEvent = parkingOperationWithBookingsAndFees.parking_operation.start_event;
    const endEvent = parkingOperationWithBookingsAndFees.parking_operation.end_event;

    const parkingLotId = startEvent?.parking_lot_id ?? endEvent?.parking_lot_id;
    if (parkingLotId == null) {
      return null;
    }

    return this.parkingLots.find(parkingLot => parkingLot.id == parkingLotId) ?? null;
  }

  getParkingDuration(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): string {
    const startEvent = parkingOperationWithBookingsAndFees.parking_operation.start_event;
    const endEvent = parkingOperationWithBookingsAndFees.parking_operation.end_event;

    if (startEvent == null || endEvent == null) {
      return "";
    }

    const durationSeconds = endEvent.time - startEvent.time;
    const durationMinutes = Math.ceil(durationSeconds / 60);

    const days = Math.floor(durationMinutes / 60 / 24);
    const hours = Math.floor(durationMinutes / 60) % 24;
    const minutes = durationMinutes % 60;

    return (days > 0 ? days + "T " : "") + (hours > 0 ? hours + "h " : "") + minutes + "min";
  }

  getPaymentStatus(
    parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees
  ): string {

    if (parkingOperationWithBookingsAndFees.parking_operation.start_event == null ||
      parkingOperationWithBookingsAndFees.parking_operation.end_event == null) {
      return "";
    }

    if (parkingOperationWithBookingsAndFees.parking_operation.longer_than_allowed) {
      return "Zu lang";
    }

    // if (parkingOperationWithBookingsAndFees.fee == 0 && parkingOperationWithBookingsAndFees.bookings.length > 0) {
    //   return "Abgerechnet";
    // }

    if (parkingOperationWithBookingsAndFees.fee == 0) {
      return "Kostenlos";
    }

    if (parkingOperationWithBookingsAndFees.bookings.length == 0) {
      return "Offen"
    }

    return "Offen";//teilweise abgerechnet
  }

  deleteParkingOperation(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees) {
    const index = this.parkingOperationsWithBookingsAndFeesSelections.findIndex(parkingOperationWithBookingsAndFeesSelection =>
      parkingOperationWithBookingsAndFeesSelection.parking_operation === parkingOperationWithBookingsAndFees
    );
    if (index < 0) {
      return;
    }

    this.parkingOperationsWithBookingsAndFeesSelections.splice(index, 1);

    this.recomputeSelectedFees();
  }

  isParkingOperationComplete(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): boolean {
    return parkingOperationWithBookingsAndFees.parking_operation.start_event != null &&
      parkingOperationWithBookingsAndFees.parking_operation.end_event != null;
  }

  isStartEditable(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): boolean {
    return parkingOperationWithBookingsAndFees.parking_operation.start_event == null ||
      parkingOperationWithBookingsAndFees.parking_operation.start_event_editable;
  }

  isEndEditable(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): boolean {
    return parkingOperationWithBookingsAndFees.parking_operation.end_event == null ||
      parkingOperationWithBookingsAndFees.parking_operation.end_event_editable;
  }



  isParkingOperationDisabled(
    parkingOperationWithBookingsAndFeesWithSelection: ParkingOperationWithBookingsAndFeesWithSelection
  ): boolean {
    const parkingOperation = parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation;
    if (parkingOperationWithBookingsAndFeesWithSelection.parking_operation.fee == 0) {
      parkingOperationWithBookingsAndFeesWithSelection.selected = false;
      return true;
    }
    if (parkingOperation.start_event == null ||
      parkingOperation.end_event == null) {
      return false;
    }

    if (parkingOperation.longer_than_allowed) {
      return true;
    }

    return !this.isParkingOperationComplete(parkingOperationWithBookingsAndFeesWithSelection.parking_operation) ||
      parkingOperationWithBookingsAndFeesWithSelection.startTimeFormControl.invalid ||
      parkingOperationWithBookingsAndFeesWithSelection.endTimeFormControl.invalid ||
      parkingOperationWithBookingsAndFeesWithSelection.parking_operation.parking_operation.longer_than_allowed
  }

  isParkingOperationDeletable(parkingOperationWithBookingsAndFeesWithSelection: ParkingOperationWithBookingsAndFeesWithSelection): boolean {
    return parkingOperationWithBookingsAndFeesWithSelection.editedStartTime != null &&
      parkingOperationWithBookingsAndFeesWithSelection.editedEndTime != null
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
