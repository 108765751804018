
export const WEEKDAY_DISPLAY: string[] = [
    'Mo',
    'Di',
    'Mi',
    'Do',
    'Fr',
    'Sa',
    'So'
  ];
