import {Component, Input, Output, EventEmitter, OnInit, signal, effect, computed} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TariffTimes} from "../../../../../../../models/TariffStructure";
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatList, MatListItem} from "@angular/material/list";
import {MatFormField} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {NgForOf, NgIf, NgSwitch} from "@angular/common";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {MatTooltip} from "@angular/material/tooltip";
import {FeeService} from "../../../../../../../services/fee.service";
import {CurrencyHandler} from "../../../../../../../CurrencyHandler";

interface FeeInterval {
  fee: number;
  interval: number;
  isEditing: boolean;
  editingInterval: number;
  currencyControl: CurrencyHandler
}

@Component({
    selector: 'app-consecutive-intervals-edit',
    imports: [
        MatCardTitle,
        MatCardHeader,
        MatCard,
        MatCardContent,
        MatList,
        MatListItem,
        MatFormField,
        ReactiveFormsModule,
        MatIcon,
        NgForOf,
        MatIconButton,
        MatInput,
        MatButton,
        NgSwitch,
        FormsModule,
        NgIf,
        MatTooltip
    ],
    templateUrl: './consecutive-intervals-edit.component.html',
    styleUrl: './consecutive-intervals-edit.component.css'
})
export class ConsecutiveIntervalsEditComponent {
  @Input() tariffTimes!: TariffTimes;
  @Input() mainBillingIntervalMinutes!: number;
  @Output() feesChanged = new EventEmitter<{ fees: number[], intervals: number[] }>();

  feeIntervals = signal<FeeInterval[]>([]);

  constructor(
    private feeService: FeeService
  ) {
  }

  ngOnInit() {
    this.initializeFeeIntervals();
  }

  initializeFeeIntervals() {
    const {consecutive_fees, consecutive_billing_interval_minutes} = this.tariffTimes;
    this.feeIntervals.set(consecutive_fees.map((fee, index) => {
        let feeFormControl = new FormControl('')
        if (fee != null) {
          feeFormControl.setValue(this.feeService.getFormattedFeeValueOnly(fee).replace(",", "."))
        }
        return {
          fee,
          interval: consecutive_billing_interval_minutes[index] || this.mainBillingIntervalMinutes,
          isEditing: false,
          editingInterval: consecutive_billing_interval_minutes[index] || this.mainBillingIntervalMinutes,
          currencyControl: new CurrencyHandler(fee)
        }
      }
    ));
  }

  getEntryDescription(item: FeeInterval, index: number) {
    const entries = this.feeIntervals();
    if (entries.length === 1) {
      return `€ für alle`;
    } else if (index === 0) {
      return `€ für die ersten`;
    } else if (index === entries.length - 1) {
      return `€ für alle weiteren`;
    } else {
      return `€ für die nächsten`;
    }
  };

  editItem(index: number) {
    this.feeIntervals.update(intervals =>
      intervals.map((item, i) => i === index ? {...item, isEditing: true} : item)
    );
  }

  saveItem(index: number) {
    this.feeIntervals.update(intervals =>
      intervals.map((item, i) => {
        if (i === index) {
          let fee = item.currencyControl.getRawCurrencyValue() ||0;
          console.log("fee", fee)
          return {
            fee: fee,
            interval: item.editingInterval,
            isEditing: false,
            editingInterval: item.editingInterval,
            currencyControl: new CurrencyHandler(fee)
          };
        } else {
          return item;
        }
      })
    );
    this.emitChanges();
  }

  deleteItem(index: number) {
    this.feeIntervals.update(intervals => intervals.filter((_, i) => i !== index));
    this.emitChanges();
  }

  addNewItem() {

    this.feeIntervals.update(intervals => [
      ...intervals,
      {
        fee: 0,
        interval: this.mainBillingIntervalMinutes,
        isEditing: true,
        editingInterval: this.mainBillingIntervalMinutes,
        currencyControl: new CurrencyHandler(0)
      }
    ]);
  }

  emitChanges() {
    const intervals = this.feeIntervals();
    const fees = intervals.map(item => item.fee);
    const billingIntervals = intervals.map(item => item.interval);
    this.feesChanged.emit({fees, intervals: billingIntervals});
  }

  anyEditing() {
    return this.feeIntervals().some(item => item.isEditing);
  }

  cancelEditing(i: number) {
    this.feeIntervals.update(intervals =>
      intervals.map((item, index) => {
        if (index === i) {
          return {
            fee: item.fee,
            editingInterval: item.interval,
            interval: item.interval,
            isEditing: false,
            currencyControl: new CurrencyHandler(item.fee)
          }
        } else {
          return item
        }
      })
    );
  }

  editingThis(i: number) {
    return this.feeIntervals()[i].isEditing;
  }

  editingDifferentItem(i: number) {
    return this.anyEditing() && !this.editingThis(i);
  }


}
