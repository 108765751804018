<div class="scs-flex-c scs-gap-m" style="margin: 1rem">
  <mat-slide-toggle
    [style.--mdc-switch-selected-track-color]="'#2c3b7d'"
    [style.--mdc-switch-selected-handle-color]="'#ffffff'"
    [checked]="multiDayCheck()" (change)="toggleChecked($event.checked)">
    Mehrtägig
  </mat-slide-toggle>
  <app-week-day-selector
   *ngIf="multiDayCheck()"
    [selectedDays]="groupedTariffTime.days"
    (daysChanged)="onSelectedDaysChange($event)"
  ></app-week-day-selector>
  <div class="scs-flex-r scs-gap-l">

    <div class="scs-flex-c scs-gap-xs" style="align-items: center">
        <span style="height: 24px; line-height: 24px">
          Startzeit
        </span>
      <div class="scs-flex-r scs-gap-s">
        <mat-form-field appearance="outline" *ngIf="!multiDayCheck()">
          <mat-select [(value)]="startDaySelection"
                      (valueChange)="onStartDayChange()">
            <mat-option *ngFor="let weekDay of weekDays; let i = index" [value]="i">
              {{ weekDay }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="time"
                 [formControl]="startTimeFormControl"
                 (change)="onStartTimeChange()"
                 name="start_time" required>
          <mat-error *ngIf="startTimeFormControl.hasError('required')">
            Pflichtfeld
          </mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="scs-flex-c scs-gap-xs" style="align-items: center">
      <div class="scs-flex-r scs-gap-m">
        <span style="height: 24px; line-height: 24px">
          Endzeit
        </span>

        <mat-icon
          color="primary"
          matTooltip="00:00 entspricht hier 24:00">
          info_outline
        </mat-icon>
      </div>

      <div class="scs-flex-r scs-gap-s">
        <mat-form-field appearance="outline" *ngIf="!multiDayCheck()">
          <mat-select [(value)]="endDaySelection"
                      (valueChange)="onEndDayChange()">
            <mat-option *ngFor="let weekDay of weekDays; let i = index" [value]="i">
              {{ weekDay }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input matInput type="time"
                 [formControl]="endTimeFormControl"
                 (change)="onEndTimeChange()"
                 name="end_time" required>
          <mat-error *ngIf="endTimeFormControl.hasError('required')">
            Pflichtfeld
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="scs-flex-c scs-gap-xs" style="align-items: center">
      <div class="scs-flex-r scs-gap-m">
        <span style="height: 24px; line-height: 24px">
          Freiparkzeit (in Minuten)
        </span>

        <mat-icon
          color="primary"
          matTooltip="Erst nach der Freiparkzeit muss der Parkvorgang bezahlt werden">
          info_outline
        </mat-icon>
      </div>

      <div class="scs-flex-r scs-gap-s">

        <mat-form-field appearance="outline">
          <input matInput type="number"

                 [formControl]="freeParkingMinutesFormControl"
                 (change)="onFreeParkingMinutesChange()"
                 name="free_parking_duration">
        </mat-form-field>
      </div>
    </div>
    <div class="scs-flex-c scs-gap-xs" style="align-items: center">

        <span style="height: 24px; line-height: 24px">
          Gebührenmodell
        </span>
      <mat-button-toggle-group name="fee_type" [(ngModel)]="feeType"
                               style="width: fit-content; align-self: center; height: 2rem">
        <mat-button-toggle value="fee">Standard</mat-button-toggle>
        <mat-button-toggle value="flat_fee">Pauschale</mat-button-toggle>
        <mat-button-toggle value="consecutive_fees">Gestaffelt</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>

  <div class="scs-flex-c scs-gap-m">

    <div *ngIf="feeType === 'fee'" class="scs-flex-r scs-gap-s" style="align-items: center">
      <span style="margin-bottom: 22px; width: 20vw">
        Anfallende Gebühr:
      </span>

      <mat-form-field appearance="outline">
        <input matInput type="number" min="0" step="0.01"
               style="text-align: end"
               [formControl]="feeFormControl"
               (change)="onFeeChange()"
               name="fee">
      </mat-form-field>

      <span style="margin-bottom: 22px">
        EUR pro angefangene {{ billingIntervalMinutesOnTariffTimes.value }} Minuten
      </span>

      <mat-form-field appearance="outline">
        <input matInput type="number" min="0" step="5"
               style="text-align: end"
               [formControl]="billingIntervalMinutesOnTariffTimes"
               (change)="onBillingIntervalMinutesChange()" required
               name="fee">
        <mat-error *ngIf="billingIntervalMinutesOnTariffTimes.hasError('required')">
                      Pflichtfeld
                    </mat-error>
      </mat-form-field>

      <span style="margin-bottom: 22px">
       Minuten
      </span>
    </div>


    <div *ngIf="feeType === 'flat_fee'" class="scs-flex-r scs-gap-s" style="align-items: center">
      <span style="margin-bottom: 22px; width: 20vw">
        Pauschale Parkgebühr für gesamten Zeitbereich:
      </span>

      <mat-form-field appearance="outline">
        <input matInput type="number" min="0" step="0.01"
               style="text-align: end"
               [formControl]="flatFeeFormControl"
               (change)="onFeeChange()"
               name="fee">
        <mat-error *ngIf="flatFeeFormControl.hasError('invalid_character')">
          Ungültiges Zeichen verwendet
        </mat-error>
      </mat-form-field>

      <span style="margin-bottom: 22px">
        EUR
      </span>
    </div>

    <div *ngIf="feeType === 'consecutive_fees'">

      <app-consecutive-intervals-edit
        [tariffTimes]="editTariffTime"
        [mainBillingIntervalMinutes]="billing_interval_minutes"
        (feesChanged)="onConsecutiveChange($event)"
      ></app-consecutive-intervals-edit>

    </div>

  </div>
</div>
