<mat-card
  class="mat-card-add-parking-operation-container"
  style="box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);border-radius: 24px;"
>
  <div
   class="add-parking-operation-container">
    <div style="display: flex; flex-direction: column; margin: 5px 10px">
      <span class="color-primary days-one" style="height: 24px;">Parkplatz</span>

      <mat-form-field appearance="outline" style="width: 10vw; min-width: 230px; margin-top: 5px"
                      color="primary">
        <mat-select [(ngModel)]="addParkingOperationSelectedParkingLot"
                    class="small-placeholder color-primary"
                    style="font-size: 14px;"
                    placeholder="Parkplatz auswählen"
                    [formControl]="addParkingOperationParkingLotFormControl">
          <mat-option class="color-primary separated" style="padding:0 5px; font-size:14px;"
                      *ngFor="let parkingLot of allParkingLots"
                      [value]="parkingLot">
            {{ parkingLot.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addParkingOperationParkingLotFormControl.hasError('required')">Pflichtfeld</mat-error>
      </mat-form-field>
    </div>

    <div style="display: flex; flex-direction: column; margin: 5px 10px">

      <div class="scs-flex-r scs-align-space-between-stretch" style="width: 10vw; min-width: 230px">
        <span class="color-primary days-one" style="height: 24px;">Parkbeginn</span>
        <!--        <mat-icon class="noClick">calendar_today</mat-icon>-->
      </div>

<!--      <app-form-input-->
<!--        label="Parkbeginn"-->
<!--        type="datetime-local"-->
<!--        placeholder="dd.mm.yyyy, &#45;&#45;:&#45;&#45;"-->
<!--        [control]="addParkingOperationStartTimeFormControl">-->
<!--      </app-form-input>-->

      <mat-form-field appearance="outline"
                      style="width: 10vw; min-width: 230px; margin-top: 5px; border-radius: 8px;"
                      class="custom-input-field"
                      color="primary">
        <input matInput type="datetime-local" placeholder="Parkbeginn eingeben" class="color-primary"
               style="font-size: 16px;"
               [(ngModel)]="addParkingOperationSelectedStartDateTime"
               [formControl]="addParkingOperationStartTimeFormControl"
               (ngModelChange)="addParkingOperationEndTimeFormControl.updateValueAndValidity()">
        <mat-error *ngIf="addParkingOperationStartTimeFormControl.hasError('startAfterEnd')">Start muss vor Ende
          liegen
        </mat-error>
        <mat-error *ngIf="addParkingOperationStartTimeFormControl.hasError('required')">Ungültige Startzeit
        </mat-error>
      </mat-form-field>
    </div>

    <div style="display: flex; flex-direction: column; margin: 5px 10px">

      <div class="scs-flex-r scs-align-space-between-stretch" style="width: 10vw; min-width: 230px">
        <span class="color-primary days-one" style="height: 24px;">Parkende</span>
        <!--        <mat-icon class="noClick">calendar_today</mat-icon>-->
      </div>

<!--      <app-form-input-->
<!--        label="Parkende"-->
<!--        type="datetime-local"-->
<!--        placeholder="dd.mm.yyyy, &#45;&#45;:&#45;&#45;"-->
<!--        [control]="addParkingOperationEndTimeFormControl">-->
<!--      </app-form-input>-->

      <mat-form-field appearance="outline" style="width: 10vw; min-width: 230px; margin-top: 5px"
                      color="primary">
        <input matInput type="datetime-local" placeholder="Parkende eingeben" class="color-primary"
               [(ngModel)]="addParkingOperationSelectedEndDateTime"
               [formControl]="addParkingOperationEndTimeFormControl"
               (ngModelChange)="addParkingOperationStartTimeFormControl.updateValueAndValidity()">
        <mat-error *ngIf="addParkingOperationEndTimeFormControl.hasError('endBeforeStart')">Ende muss vor Start
          liegen
        </mat-error>
        <mat-error *ngIf="addParkingOperationEndTimeFormControl.hasError('required')">Ungültige Endzeit
        </mat-error>
      </mat-form-field>
    </div>

    <button
      [disabled]="addParkingOperationSelectedParkingLot == null ||
        addParkingOperationStartTimeFormControl.invalid ||
        addParkingOperationEndTimeFormControl.invalid"
      mat-fab extended
      color="primary"
      style=" padding: 1.5rem 1.5rem; margin: 15px 20px;height:40px;  box-shadow: none !important;"
      (click)="onAddParkingOperationClick()">
      Hinzufügen
    </button>

  </div>
</mat-card>
