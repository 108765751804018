<mat-form-field style="height: fit-content; width: 100%">
  <mat-chip-grid #chipGrid
                 class="mat-mdc-chip-set-stacked drag-and-drop-chip"
                 aria-label="Enter keywords"
                 [formControl]="formControl"
                 cdkDropList
                 cdkDropListOrientation="vertical"
                 (cdkDropListDropped)="drop($event)">

    <mat-chip-row *ngFor="let keyword of consecutiveFees; let index = index; let first = first; let last = last"
                  cdkDrag class="drag-and-drop"
                  (removed)="remove(index)">

      {{keyword}} EUR
      <span *ngIf="first && consecutiveFees.length > 1">in den ersten {{getBillingIntervalMinutes()}} Minuten</span>
      <span *ngIf="!first && !last && consecutiveFees.length > 1">in den folgenden {{getBillingIntervalMinutes()}}
        Minuten</span>
      <span *ngIf="last && consecutiveFees.length > 1">pro angefangene {{getBillingIntervalMinutes()}} Minuten</span>
      <span *ngIf="consecutiveFees.length == 1">pro angefangene {{getBillingIntervalMinutes()}} Minuten</span>

      <button matChipRemove aria-label="remove">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>

  </mat-chip-grid>

  <input placeholder="Nächster gestaffelter Betrag..."
         type="number" min="0" step="0.01"
         [matChipInputFor]="chipGrid"
         (matChipInputTokenEnd)="add($event)"/>

</mat-form-field>
