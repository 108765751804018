<div class="scs-flex-c scs-align-center">

  <h1 class="days-one color-primary">Nach Parkvorgängen suchen</h1>


  <div style="display: flex; flex-direction: column; text-align: start; width: fit-content; margin-top: 1rem">
    <span class="color-primary" style="margin: 0 5px"><b>Kennzeichen</b></span>
    <form (ngSubmit)="onClickParkingOperationManagement()">
      <mat-form-field appearance="outline" class="standard-input-dimensions" style="margin-bottom: 10px">
        <input matInput
               [formControl]="licensePlateFormControl" placeholder="Beispiel: N-PC234"
               name="licensePlate" required>
        <mat-error
          *ngIf="licensePlateFormControl.hasError('minlength') && !licensePlateFormControl.hasError('required')">
          Mindestens 3 Zeichen erwartet
        </mat-error>
        <mat-error
          *ngIf="licensePlateFormControl.hasError('maxlength') && !licensePlateFormControl.hasError('required')">
          Maximal 15 Zeichen erwartet
        </mat-error>
        <mat-error *ngIf="licensePlateFormControl.hasError('required')">
          Pflichtfeld
        </mat-error>
      </mat-form-field>
    </form>

    <button mat-flat-button color="primary" class="standard-input-dimensions"
            [disabled]="!licensePlateFormControl.valid"
            (click)="onClickParkingOperationManagement()">
      Parkvorgänge verwalten
      <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
    </button>
  </div>

</div>
