import {Injectable} from '@angular/core';
import {Subscription, switchMap, takeWhile, tap, timer} from "rxjs";
import {FeeService} from "../fee.service";
import {ApiCallWrapperService} from "../api/api-call-wrapper.service";
import {TelecashPaymentServiceService} from "./telecash-payment-service.service";

export interface PaymentPollingResult {
  success: boolean,
  error: string | null,
}

@Injectable({
  providedIn: 'root'
})
export class PaymentHandlingService {

  constructor(
    private feeService: FeeService,
    private apiCallHelper: ApiCallWrapperService,
    private telecashPaymentService: TelecashPaymentServiceService,
  ) {
  }


  private readonly ERROR_STATES: string[] = ['FAILED',];
  private readonly UNRECOVERABLE_ERROR_STATUSES = ['FRAUD', 'DENIED', 'REJECTED'];

  private getErrorMessage(status: string): string {
    switch (status) {
      case 'DENIED':
        return 'Zahlung wurde verweigert';
      case 'FAILED':
        return 'Zahlung ist fehlgeschlagen';
      case 'REJECTED':
        return 'Zahlung wurde abgelehnt';
      default:
        return 'Ein Fehler ist bei der Zahlung aufgetreten';
    }
  }

  startPollingPaymentStatus(paymentLinkId: string, licensePlate: string, testMode: boolean = false): Promise<PaymentPollingResult> {

    return new Promise((resolve, reject) => {
      let pollCount = 0;
      const startTime = Date.now();
      const MAX_POLLING_TIME = 4 * 120000; // 2 minutes
      const POLLING_INTERVAL = 4000; // 4 seconds
      let paymentError: string | null = null;
      let paymentApproved = false
      let pollingSubscription: Subscription | null = null;

      pollingSubscription = timer(0, POLLING_INTERVAL).pipe(
        tap(() => pollCount++),
        takeWhile(() => {
          const timeElapsed = Date.now() - startTime;
          const withinTimeLimit = timeElapsed < MAX_POLLING_TIME &&
            !paymentApproved &&
            !paymentError;

          if (testMode) {
            return pollCount <= 2;
          }
          return withinTimeLimit;
        }),
        switchMap(() => this.apiCallHelper.call(this.telecashPaymentService.getPaymentLink(paymentLinkId, licensePlate)))
      ).subscribe({
        next: (response) => {
          if (response?.payment_status.toUpperCase() === 'APPROVED') {
            paymentApproved = true;
            pollingSubscription?.unsubscribe();
            resolve({success: true, error: null});

          } else if (this.UNRECOVERABLE_ERROR_STATUSES.includes(response?.payment_status.toUpperCase() || '')) {
            paymentError = this.getErrorMessage(response?.payment_status.toUpperCase() || '');

            pollingSubscription?.unsubscribe();
            reject({success: false, error: paymentError});
          }
        },
        error: (error) => {
          console.error('Error polling payment status:', error);

          reject({success: false, error: paymentError});
        },
        complete: () => {
          if (!paymentApproved && !paymentError) {
            paymentError = 'Zeitüberschreitung bei der Zahlung';
            reject({success: false, error: paymentError});
          }
        }
      });
    });
  }
}
