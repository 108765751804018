import {FormControl} from '@angular/forms';

export class CurrencyHandler {
  control: FormControl;

  constructor(initialFee: number = 0) {
    // Initialize FormControl with formatted value
    this.control = new FormControl('');
    this.control.setValue((initialFee / 100).toString())
  }

  // Methode um den rohen Währungswert in Cent zurückzugeben
  getRawCurrencyValue(): number {
    const input = this.control.value;
    if (input == null) {
      return 0
    } else {
      return (+(+input).toFixed(2) * 100)
    }
  }

  /**
   * Fees are given in the smallest currency. This method returns the value in the bigger currency unit.
   * E.g. if the value is 100 -> 1,00 is getting returned
   * 50 -> 0,50
   */
  getFormattedFee() {
    const fee = this.control.value;
    return fee.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'});
  }

  getFormattedFeeValueOnly(useCommaSeparator: boolean = true): string {
    const value = (this.getRawCurrencyValue()/100).toFixed(2)
    if (useCommaSeparator) {
      return value.replace(".", ",");
    }
    return value;
  }
}
