import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParkingLot} from "../../../../../models/ParkingLot";
import {Router} from "@angular/router";
import {ApiCallWrapperService} from "../../../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../../../services/api/api-routes.service";
import {GlobalStateService} from "../../../../../services/global-state.service";
import {ParkingOperationsPageTabIndexService} from "../../../../../services/parking-operations-page-tab-index.service";
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {BehaviorSubject} from "rxjs";
import {CustomTariffDescription} from "../../../../../models/CustomTariffDescription";
import {LocalStorageService} from "../../../../../services/local-storage.service";

@Component({
  selector: 'app-no-existing-parking-operations-page',
  templateUrl: './no-existing-parking-operations-page.component.html',
  styleUrls: ['./no-existing-parking-operations-page.component.css'],
  standalone: false
})
export class NoExistingParkingOperationsPageComponent implements OnInit {

  @Input() parkingLots!: ParkingLot[];
  @Input() parkingLotsWithCustomTariffDescription!: CustomTariffDescription[];
  @Input() parkingOperationsWithBookingsAndFeesSelections!: ParkingOperationWithBookingsAndFeesWithSelection[];

  @Output() reloadParkingOperationsTrigger = new EventEmitter<any>();

  hideAddAdditionalParkingOperationsView = false;
  recomputeSelectedFeesTriggerSubject = new EventEmitter<any>();

  selectedParkingOperations = new BehaviorSubject<ParkingOperationWithBookingsAndFeesWithSelection[]>([]);
  totalSelectionFee = new BehaviorSubject<number>(0);

  constructor(
    private router: Router,
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    private parkingOperationsPageTabIndexService: ParkingOperationsPageTabIndexService,
    public globalStateService: GlobalStateService,
    private localStorageService: LocalStorageService
  ) {
    if (globalStateService.normalizedLicensePlate.length == 0 || globalStateService.licensePlateInput.length == 0) {
      this.router.navigateByUrl("/");
    }
  }

  showAddManualParkingOperationView() {
    return !this.hideAddAdditionalParkingOperationsView || this.parkingOperationsWithBookingsAndFeesSelections.length == 0;
  }

  emitNewParkignOperationAndHide() {
    this.hideAddAdditionalParkingOperationsView = true;
    this.recomputeSelectedFeesTriggerSubject.emit();
  }

  ngOnInit(): void {
    if (!this.parkingOperationsPageTabIndexService.tabIndexChanged) {
      //When we are in this view, we know there were no parking operations for the license plate.
      //Automatically switch to the invoice view if there is at least an invoice for the license plate.
      this.apiCallHelper.call(
        this.apiRoutesService.getInvoicesOfLicensePlate(this.globalStateService.normalizedLicensePlate)
      ).then(invoices => {
        if (invoices != null && invoices.length > 0) {
          this.parkingOperationsPageTabIndexService.switchToInvoiceTab();
        }
      });
    }
  }

  clearStateAndGoToLanding() {
    this.globalStateService.clearState();
    this.localStorageService.removeLicensePlateData();
    this.router.navigateByUrl("/");
  }
}
