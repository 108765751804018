<div class="landing-page-container scs-flex-c scs-align-center">

  <div class="width-limit-container" style="align-self: center; text-align: center">
    <h1 class="color-primary days-one font-size-h1" style="margin-top: 1rem; margin-bottom: 2rem">Herzlich Willkommen</h1>

    <div class="days-one text-block" style="margin-top: 1rem; margin-bottom: 1rem; text-align: center">
      Bezahlen Sie Parkgebühren oder <br> sehen Sie ältere Vorgänge ein

    </div>


    <div style="display: flex; flex-direction: row;">

      <!-- spacer -->
      <div style="flex-grow: 1"></div>

      <div style="display: flex; flex-direction: column; text-align: start; width: fit-content; margin-top: 1rem">
        <span class="color-primary" style="margin: 0 5px;text-align: center;"><b>Kennzeichen eingeben</b></span>
        <form (ngSubmit)="onClickParkingOperations()">
          <mat-form-field appearance="outline" class="standard-input-dimensions">
            <input matInput
                   [formControl]="licensePlateFormControl" placeholder="Beispiel: N-PC234"
                   name="licensePlate" required>
            <mat-error
              *ngIf="licensePlateFormControl.hasError('minlength') && !licensePlateFormControl.hasError('required')">
              Mindestens 3 Zeichen erwartet
            </mat-error>
            <mat-error
              *ngIf="licensePlateFormControl.hasError('maxlength') && !licensePlateFormControl.hasError('required')">
              Maximal 15 Zeichen erwartet
            </mat-error>
<!--            <mat-error *ngIf="licensePlateFormControl.hasError('required')">-->
<!--              Pflichtfeld-->
<!--            </mat-error>-->
          </mat-form-field>
        </form>

        <button  mat-fab extended color="primary" class="standard-input-dimensions"
                 style="  box-shadow: none !important;"
                [disabled]="!licensePlateFormControl.valid"
                (click)="onClickParkingOperations()">
          Weiter
          <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
        </button>
      </div>

      <!-- spacer -->
      <div style="flex-grow: 1"></div>

    </div>

  </div>

</div>
