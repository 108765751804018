import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import moment from "moment";
import {TIME_FORMAT} from "../../../../../../../models/constants";
import {ParkingOperationWithBookingsAndFees} from "../../../../../../../models/ParkingOperation";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ParkingLot} from "../../../../../../../models/ParkingLot";
import {ApiCallWrapperService} from "../../../../../../../services/api/api-call-wrapper.service";
import {ManagementApiRoutesService} from "../../../../../../../services/api/management-api-routes";
import {GlobalStateService} from "../../../../../../../services/global-state.service";
import {TariffStructureService} from "../../../../../../../services/tariff-structure.service";
import {TariffStructure} from "../../../../../../../models/TariffStructure";
import {BehaviorSubject} from "rxjs";

export interface AddSupportBookingDialogData {
  parkingOperation: ParkingOperationWithBookingsAndFees,
  parkingLot: ParkingLot,
  onNewBookingCreated: () => void
}

@Component({
    selector: 'app-add-support-booking-dialog',
    templateUrl: './add-support-booking-dialog.component.html',
    styleUrls: ['./add-support-booking-dialog.component.css'],
    standalone: false
})
export class AddSupportBookingDialogComponent implements OnInit, AfterViewInit {

  startTimeFormControl: FormControl;
  endTimeFormControl: FormControl;

  tariffStructureSubject = new BehaviorSubject<TariffStructure | null | undefined>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddSupportBookingDialogData,
    private snackBar: MatSnackBar,
    private apiCallHelper: ApiCallWrapperService,
    private managementApiRoutesService: ManagementApiRoutesService,
    private globalStateService: GlobalStateService,
    private tariffStructureService: TariffStructureService,
    private dialogRef: MatDialogRef<AddSupportBookingDialogComponent>
  ) {

    this.startTimeFormControl = new FormControl('', [Validators.required, (control: AbstractControl) => {
      if (this.data.parkingOperation.parking_operation.end_event == null && (this.endTimeFormControl.value?.length ?? 0) == 0) {
        return null;
      }
      if (control.value == null || control.value.length == 0) {
        return null;
      }
      const unixStartSeconds = moment(control.value, TIME_FORMAT).unix();
      const unixEndSeconds = this.data.parkingOperation.parking_operation.end_event?.time ?? moment(this.endTimeFormControl.value, TIME_FORMAT).unix();
      return unixStartSeconds < unixEndSeconds ? null : {startAfterEnd: true};
    }]);

    this.endTimeFormControl = new FormControl('', [Validators.required, (control: AbstractControl) => {
      if (this.data.parkingOperation.parking_operation.start_event == null && (this.startTimeFormControl.value?.length ?? 0) == 0) {
        return null;
      }
      if (control.value == null || control.value.length == 0) {
        return null;
      }
      const unixStartSeconds = this.data.parkingOperation.parking_operation.start_event?.time ?? moment(this.startTimeFormControl.value, TIME_FORMAT).unix();
      const unixEndSeconds = moment(control.value, TIME_FORMAT).unix();
      return unixStartSeconds < unixEndSeconds ? null : {endBeforeStart: true};
    }]);

  }

  ngOnInit(): void {

    if (this.data.parkingOperation.parking_operation.start_event != null) {
      this.startTimeFormControl.setValue(
        moment(this.data.parkingOperation.parking_operation.start_event?.time * 1000).format(TIME_FORMAT)
      );
    }

    if (this.data.parkingOperation.parking_operation.end_event != null) {
      this.endTimeFormControl.setValue(
        moment(this.data.parkingOperation.parking_operation.end_event?.time * 1000).format(TIME_FORMAT)
      );
    }

  }

  ngAfterViewInit() {
    this.tariffStructureService.getOrFetchTariffStructure(this.data.parkingLot.id).then(tariffStructure => {
      this.tariffStructureSubject.next(tariffStructure);
    });
  }

  getParkingDuration(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): string {
    const startEvent = parkingOperationWithBookingsAndFees.parking_operation.start_event;
    const endEvent = parkingOperationWithBookingsAndFees.parking_operation.end_event;

    if (startEvent == null || endEvent == null) {
      return "";
    }

    const durationSeconds = endEvent.time - startEvent.time;
    const durationMinutes = Math.ceil(durationSeconds / 60);

    const days = Math.floor(durationMinutes / 60 / 24);
    const hours = Math.floor(durationMinutes / 60) % 24;
    const minutes = durationMinutes % 60;

    return (days > 0 ? days + "T " : "") + (hours > 0 ? hours + "h " : "") + minutes + "min";
  }

  addBooking() {
    const unixStartSeconds = moment(this.startTimeFormControl.value, TIME_FORMAT).unix();
    const unixEndSeconds = moment(this.endTimeFormControl.value, TIME_FORMAT).unix();

    this.apiCallHelper.call(
      this.managementApiRoutesService.createBooking(
        this.data.parkingLot.id,
        this.globalStateService.normalizedLicensePlate,
        this.globalStateService.licensePlateInput,
        unixStartSeconds,
        unixEndSeconds + 60// add 60 seconds because the form control does not allow seconds
      )
    ).then(() => {
        this.data.onNewBookingCreated();
        this.dialogRef.close();
      },
      error => {
        this.snackBar.open("Failed to create booking", "", {duration: 3000});
      }
    );
  }

}
