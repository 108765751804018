import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {TokenBody} from "./models/TokenBody";
import {AuthService} from "../auth.service";
import {HttpHeadersWrapper} from "./models/HttpHeadersWrapper";
import {ParkingOperationWithBookingsAndFees} from "../../models/ParkingOperation";
import {Invoice} from "../../models/Invoice";
import {BASE_URL} from "./url";
import {TariffStructure, TariffWithTariffTimes} from "../../models/TariffStructure";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../models/InvoiceWithPaymentLink";

@Injectable({
  providedIn: 'root'
})
export class ManagementApiRoutesService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {

  }

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  private getAuthenticatedHttpOptions(contentType: string | undefined = undefined): HttpHeadersWrapper {
    return {
      headers: new HttpHeaders({
        'Content-Type': contentType ?? 'application/json',
        'X-Auth-Token': this.authService.getToken() ?? ""
      })
    };
  }

  login(username: string, password: string): Observable<TokenBody> {
    let body = {username, password};
    return this.http.post<TokenBody>(BASE_URL + 'auth', body);
  }

  createBooking(parkingLotId: number, normalizedLicensePlate: string, licensePlate: string, start: number, end: number): Observable<any> {
    let headers = this.getAuthenticatedHttpOptions("application/json")
    return this.http.post<never>(
      BASE_URL + `payment-website/${parkingLotId}/booking`,
      {
        normalized_license_plate: normalizedLicensePlate,
        license_plate: licensePlate,
        start_time: start,
        end_time: end
      },
      headers
    );
  }

  /**
   * Queries the parking operations including all matched existing bookings and the fee of that parking operation
   * for a given normalized license plate.
   */
  getParkingOperationsWithBookingsHistory(normalizedLicensePlate: string, licensePlate: string, start: number, end: number): Observable<ParkingOperationWithBookingsAndFees[]> {
    return this.http.post<ParkingOperationWithBookingsAndFees[]>(
      BASE_URL + `payment-website/license-plate/paid-parking-operations?start=${start}&end=${end}`,
      {
        normalized_license_plate: normalizedLicensePlate,
        license_plate: licensePlate,
      },
      this.httpOptions
    );
  }

  downloadInvoicePdf(normalizedLicensePlate: string, invoiceId: string): Observable<any> {
    return this.http.post<Blob>(
      BASE_URL + `invoices/${invoiceId}/pdf`,
      {
        license_plate: normalizedLicensePlate
      },
      {
        headers: this.getAuthenticatedHttpOptions('application/pdf').headers,
        responseType: 'blob' as any
      }
    );
  }

  getInvoicesOfLicensePlate(normalizedLicensePlate: string): Observable<Invoice[]> {
    return this.http.post<Invoice[]>(
      BASE_URL + `license_plate/invoices`,
      {
        normalized_license_plate: normalizedLicensePlate
      },
      this.getAuthenticatedHttpOptions()
    );
  }

  getAllInvoicesBetween(start: number, end: number): Observable<Invoice[]> {
    return this.http.post<Invoice[]>(
      BASE_URL + `payment-website/invoices?start=${start}&end=${end}`,
      {
        start_time: start,
        end_time: end
      },
      this.getAuthenticatedHttpOptions("application/json")
    );
  }

  getAllInvoicesWithPaymentLinkBetween(start: number, end: number): Observable<PaymentWebsiteInvoiceWithPaymentLink[]> {
    return this.http.post<PaymentWebsiteInvoiceWithPaymentLink[]>(
      BASE_URL + `payment-website/invoices-with-payment-link?start=${start}&end=${end}`,
      {
        start_time: start,
        end_time: end
      },
      this.getAuthenticatedHttpOptions("application/json")
    );
  }

  downloadMergedInvoicesPdf(invoiceIds: string[]): Observable<any> {
    return this.http.post<Blob>(
      BASE_URL + `payment-website/download-invoice-zip`,
      {
        invoice_ids: invoiceIds
      },
      {
        headers: this.getAuthenticatedHttpOptions('application/json').headers,
        responseType: 'blob' as any
      }
    );
  }

  /**
   * Queries the tariff structure for a given parking lot.
   */
  getAllExistingTariffStructures(): Observable<TariffStructure[]> {
    return this.http.get<TariffWithTariffTimes[]>(BASE_URL + `payment-website/tariffs`, this.httpOptions)
      .pipe(
        map((backendResponse: TariffWithTariffTimes[]) => {
          return backendResponse.map((item: TariffWithTariffTimes) => {
            return {
              parking_lot_id: item.tariff.parking_lot_id,
              tariff_id: -1,
              billing_interval_minutes: item.tariff.billing_interval_minutes,
              maximum_duration_minutes: item.tariff.maximum_duration_minutes,
              maximum_fee: item.tariff.maximum_fee,
              currency: item.tariff.currency,
              subtract_free_parking_duration: item.tariff.subtract_free_parking_duration,
              max_daily_fee: item.tariff.max_daily_fee,
              daily_max_fee_reset_time: item.tariff.daily_max_fee_reset_time,
              tariff_times: item.tariff_times.map(tariffTime => {
                return {
                  tariff_id: tariffTime.tariff_id,
                  start_time: tariffTime.start_time,
                  end_time: tariffTime.end_time,
                  day_of_week_start: tariffTime.day_of_week_start - 1,
                  day_of_week_end: tariffTime.day_of_week_end - 1,
                  fee: tariffTime.fee,
                  flat_fee: tariffTime.flat_fee,
                  consecutive_fees: tariffTime.consecutive_fees,
                  billing_interval_minutes: tariffTime.billing_interval_minutes,
                  consecutive_billing_interval_minutes: tariffTime.consecutive_billing_interval_minutes,
                  free_parking_duration_minutes: tariffTime.free_parking_duration_minutes

                }
              })
            };
          });
        })
      );
  }

  /**
   * Uploads new settings for a tariff structure.
   * Creates a new tariff structure if there ain't one yet.
   */
  createOrUpdateTariffStructure(tariffStructure: TariffStructure): Observable<any> {

    let tariffWithTariffTimes: TariffWithTariffTimes = {
      tariff: {
        parking_lot_id: tariffStructure.parking_lot_id,
        billing_interval_minutes: tariffStructure.billing_interval_minutes,
        maximum_duration_minutes: tariffStructure.maximum_duration_minutes,
        maximum_fee: tariffStructure.maximum_fee,
        currency: tariffStructure.currency,
        subtract_free_parking_duration: tariffStructure.subtract_free_parking_duration,
        max_daily_fee: tariffStructure.max_daily_fee,
        daily_max_fee_reset_time: tariffStructure.daily_max_fee_reset_time
      },
      tariff_times: tariffStructure.tariff_times.map(tariffTime => {
        return {
          tariff_id: tariffStructure.tariff_id,
          start_time: tariffTime.start_time,
          parking_lot_id: tariffStructure.parking_lot_id,
          end_time: tariffTime.end_time,
          day_of_week_start: tariffTime.day_of_week_start + 1,
          day_of_week_end: tariffTime.day_of_week_end + 1,
          fee: tariffTime.fee,
          flat_fee: tariffTime.flat_fee,
          consecutive_fees: tariffTime.consecutive_fees,
          billing_interval_minutes: tariffTime.billing_interval_minutes,
          consecutive_billing_interval_minutes: tariffTime.consecutive_billing_interval_minutes,
          free_parking_duration_minutes: tariffTime.free_parking_duration_minutes
        }
      })
    }
    let parkingLotId = tariffStructure.parking_lot_id;
    return this.http.post<TariffWithTariffTimes>(
      BASE_URL + `parkinglots/${parkingLotId}/tariff`,
      tariffWithTariffTimes,
      this.getAuthenticatedHttpOptions()
    );
  }

}
