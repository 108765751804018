import {Component} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ConfigService} from "./services/config.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false
})
export class AppComponent {
  constructor(
    private titleService: Title,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.configService.getString('title'));
  }

  getHeaderIcon() {
    return "../assets/images/" + this.configService.getIcon("tabIcon")
  }

  getEmail() {
    return this.configService.getString('email')
  }

  getNumber() {
    return this.configService.getString('number')
  }

  getDataProtectionRef() {
    return this.configService.getString('dataProtectionRef')
  }

  getContactRef() {
    return this.configService.getString('contactRef')
  }

  showFooter() {
    return window.location.pathname === '/';
  }
}
