import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GlobalStateService} from "../../services/global-state.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  standalone: false
})
export class LandingPageComponent implements OnInit {

  licensePlateFormControl = new FormControl('',
    [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(15)
    ]
  );

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private globalStateService: GlobalStateService,
    private localStorageService: LocalStorageService,
  ) {

  }

  ngOnInit() {

  }

  /**
   * Reads the normalized license plate from the input field.
   * The normalized license plate is the license plate without spaces and dashes and with an E at the end (for electric vehicles).
   */
  private readNormalizedLicensePlate(): string {
    const licensePlateInput = this.licensePlateFormControl.getRawValue() ?? "";
    return this.globalStateService.toNormalizedLicensePlate(licensePlateInput);
  }

  /**
   * Returns true only if the license plate was valid.
   */
  private fetchLicensePlateIntoGlobalState(): boolean {
    this.globalStateService.licensePlateInput = this.licensePlateFormControl.getRawValue()?.toUpperCase() ?? "";
    this.globalStateService.normalizedLicensePlate = this.readNormalizedLicensePlate();
    return !(this.globalStateService.normalizedLicensePlate.length < 3 || this.globalStateService.normalizedLicensePlate.length > 15);
  }

  onClickParkingOperations() {
    if (this.licensePlateFormControl.invalid) {
      return;
    }

    if (this.fetchLicensePlateIntoGlobalState()) {
      this.router.navigateByUrl("/license-plate");

    } else {
      this.snackBar.open("Bitte geben Sie ein gültiges Kennzeichen ein.", "", {duration: 3000});
    }
  }

}
