<div class="scroll-indicator"
     *ngIf="shouldShowScroll "
     (click)="scrollToPayButton()">
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    class="arrow-svg"
  >
    <rect x="5" y="5" width="30" height="30" rx="8"
          stroke="#2C3B7D"
          stroke-width="2"
          fill="none"/>
    <path
      d="M13 18 L20 25 L27 18"
      stroke="#2C3B7D"
      stroke-width="3"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</div>
