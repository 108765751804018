<div class="scs-flex-c scs-gap-m" style="margin: 20px">

  <h1 class="days-one" style="margin: 0">Rechnung {{data.invoice.invoice_id_string}}</h1>

  <div class="scs-flex-r scs-align-space-between-center">
    <div class="scs-flex-c scs-gap-s">
      <div class="scs-flex-r">
        <span style="min-width: 200px; width: 200px">Rechnungsdatum:</span>
        <span>{{data.invoice.created_at * 1000 | date : 'dd.MM.yyyy HH:mm'}}</span>
      </div>

      <div class="scs-flex-r">
        <span style="min-width: 200px; width: 200px">Kennzeichen:</span>
        <span>{{data.invoice.license_plate}}</span>
      </div>

      <div class="scs-flex-r">
        <span style="min-width: 200px; width: 200px">Zahlungsart:</span>
        <span>{{data.invoice.payment_method}}</span>
      </div>
    </div>

    <div class="scs-flex-c scs-align-space-between-center scs-gap-s">
      <button mat-stroked-button color="primary" style="min-width: fit-content; width: 200px; align-self: end"
              (click)="downloadInvoicePdf()">
        PDF herunterladen
        <mat-progress-bar *ngIf="isDownloadingPdf" mode="indeterminate" color="primary"></mat-progress-bar>
      </button>

      <button mat-stroked-button color="primary" style="min-width: fit-content; width: 200px; align-self: end"
              (click)="openSendEmailDialog()">
        An E-Mail versenden
      </button>
    </div>
  </div>

  <h4 class="days-one" style="margin-bottom: 0">
    Enthaltene Parkberechtigungen:
  </h4>

  <!--invoice table-->
  <table mat-table matSort [dataSource]="dataSource">

    <ng-container matColumnDef="parking_lot_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="color-primary days-one">Parkplatz</th>
      <td mat-cell *matCellDef="let item">
        {{ getParkingLotName(item.parking_lot_id) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="start">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="color-primary days-one">Parkbeginn</th>
      <td mat-cell *matCellDef="let item">
        {{ item.start_time * 1000 | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="end">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="color-primary days-one">Parkende</th>
      <td mat-cell *matCellDef="let item">
        {{ item.end_time * 1000 | date: 'dd.MM.yyyy HH:mm' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="color-primary days-one">Parkdauer</th>
      <td mat-cell *matCellDef="let item">
        {{ getParkingDuration(item) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fee">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="color-primary days-one">Parkgebühr</th>
      <td mat-cell *matCellDef="let item">
        {{getFormattedFee(item.fee)}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>

  <div mat-dialog-actions>
    <button mat-flat-button class="color-grey" matDialogClose>Schließen</button>
  </div>

</div>
