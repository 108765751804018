import {Injectable} from '@angular/core';
import {ApiCallWrapperService} from "./api/api-call-wrapper.service";
import {TariffStructure} from "../models/TariffStructure";
import {ApiRoutesService} from "./api/api-routes.service";

@Injectable({
  providedIn: 'root'
})
export class TariffStructureService {

  private tariffStructureCache: Map<number, TariffStructure> = new Map<number, TariffStructure>();

  constructor(
    private apiCallHelper: ApiCallWrapperService,
    private cameraParkingOperationService: ApiRoutesService
  ) {

  }

  /**
   * Returns the cached tariff structure for the parking lot or fetches it from the server.
   */
  getOrFetchTariffStructure(parkingLotId: number): Promise<TariffStructure> {
    if (this.tariffStructureCache.has(parkingLotId)) {
      return Promise.resolve(this.tariffStructureCache.get(parkingLotId)!);
    }
    return this.apiCallHelper.call(this.cameraParkingOperationService.getTariffStructure(parkingLotId)).then(tariffStructure => {
      if (tariffStructure == null) {
        console.log("Failed to load tariffs for parking lot " + parkingLotId);
        return Promise.reject("Failed to load tariffs for this parking lot");
      }
      this.tariffStructureCache.set(parkingLotId, tariffStructure);
      return Promise.resolve(tariffStructure);
    });
  }

  invalidateCache(parkingLotId: number) {
    this.tariffStructureCache.delete(parkingLotId);
  }

}
