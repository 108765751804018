import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from "./components/landing-page/landing-page.component";
import {LoginPageComponent} from "./components/management/login-page/login-page.component";
import {ManagementTabsPageComponent} from "./components/management/management-tabs-page/management-tabs-page.component";
import {AuthGuard} from "./guards/auth-guard.service";
import {UserPageComponent} from "./components/user-page/user-page.component";
import {DataProtectionPageComponent} from "./components/data-protection-page/data-protection-page.component";
import {PaymentPageComponent} from "./components/user-page/payment-page/payment-page.component";

const routes: Routes = [
  {path: '', component: LandingPageComponent},
  {path: 'license-plate', component: UserPageComponent},
  {path: 'payment', component: PaymentPageComponent},
  {path: 'management/login', component: LoginPageComponent},
  {path: 'management', component: ManagementTabsPageComponent, canActivate: [AuthGuard]},

  {path: 'datenschutz', component: DataProtectionPageComponent},

  {path: '**', component: LandingPageComponent},  //fallback
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
