import {Injectable} from "@angular/core";

/**
 * Service to toggle the currently active tab in the parking operations page.
 * tabIndex = 0 -> parking operations tab
 * tabIndex = 1 -> invoice tab
 */
@Injectable({
  providedIn: 'root'
})
export class ParkingOperationsPageTabIndexService {

  tabIndex = 0;

  /**
   * Indicates the tab has been changed at least once.
   */
  tabIndexChanged = false;

  constructor() {
  }

  switchToParkingOperationsTab() {
    this.tabIndex = 0;
    this.tabIndexChanged = true;
  }

  switchToInvoiceTab() {
    this.tabIndex = 1;
    this.tabIndexChanged = true;
  }

}
