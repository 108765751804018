<div class="scs-flex-c scs-align-center">

  <h1 class="days-one color-primary">
    Parkvorgänge und Buchungen für das Kennzeichen {{globalStateService.licensePlateInput}}
  </h1>

  <div>
    <mat-card style="margin: 20px 10px; width: fit-content; height: fit-content">
      <app-open-parking-operation-list-management
        [parkingLotSubject]="parkingLotSubject"
        [reloadTriggerSubject]="reloadTriggerSubject"
        (onNewBookingCreated)="triggerReload()">
      </app-open-parking-operation-list-management>
    </mat-card>

    <mat-card style="margin: 20px 10px; width: fit-content; height: fit-content">
      <app-booked-parking-operation-list-management
        [parkingLotSubject]="parkingLotSubject"
        [reloadTriggerSubject]="reloadTriggerSubject">
      </app-booked-parking-operation-list-management>
    </mat-card>
  </div>

</div>
