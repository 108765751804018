<div *ngIf="!invoiceCreationInProgress" class="scs-flex-c" style="padding: 1rem">

  <div style="display: flex; flex-direction: row">
    <h1 class="color-primary days-one font-size-h1" style="display: flex; flex-grow: 1">
      Bezahlen für {{ globalStateService.licensePlateInput }}
    </h1>
  </div>

  <div class="scs-flex-c ">

    <div *ngFor="let parkingLotWithParkingOperation of parkingLotToParkingOperationsMap | keyvalue">

      <h3 class="font-size-h3">{{ getParkingLotName(parkingLotWithParkingOperation.key) }}</h3>
      <ul style="margin: 0;list-style:none">
        <li *ngFor="let parkingOperation of parkingLotWithParkingOperation.value">
              <span>
              {{ parkingOperation.parking_operation.start_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm' }} -
                {{ parkingOperation.parking_operation.end_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm' }}
              </span>
          <span style="margin-left: 1rem">
                {{ getFormattedFee(parkingOperation.fee) }}
              </span>
        </li>
      </ul>

    </div>
    <div class="font-size-h2"
         style="padding-top:2rem;padding-bottom: 1.5rem; width: 90%; display: flex; justify-content: flex-end;">
      <b>Gesamt: {{ getFormattedTotalFee(parkingLotToParkingOperationsMap) }}</b>
    </div>
    <!--    <h2 class="font-size-h2" style="padding: 1rem; width: 90%">-->
    <!--      Gesamt: {{ getFormattedTotalFee(parkingLotToParkingOperationsMap) }}-->
    <!--    </h2>-->
  </div>

  <div class="scs-flex-c scs-gap-xs" style="padding: 1rem; width: 90%">



    <div mat-dialog-actions class="button-container">
      <button mat-fab extended matDialogClose class="cancel-button">
        <mat-icon>cancel</mat-icon>
        Abbrechen
      </button>

      <button mat-fab extended color="primary" class="payment-button"
              [disabled]="selectedPaymentOption == null "
              (click)="onConfirm()">
        <mat-icon>payment</mat-icon>
        <span>Zur Bezahlung</span>
      </button>
    </div>


  </div>

</div>


<div *ngIf="invoiceCreationInProgress"
     class="scs-flex-c scs-gap-m scs-align-center" style="padding: 1rem">
  <mat-spinner color="primary"></mat-spinner>
  Ihr Zahlungslink wird erstellt...<br>
  Dies kann einige Sekunden dauern.
</div>
