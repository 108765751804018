import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Invoice, InvoiceParkingOperation} from "../../../../../models/Invoice";
import {MatTableDataSource} from "@angular/material/table";
import {ParkingLot} from "../../../../../models/ParkingLot";
import {FeeService} from "../../../../../services/fee.service";

export interface ParkingOperationDetailsDialogComponentData {
  invoice: Invoice,
  parkingLots: ParkingLot[]
}

@Component({
    selector: 'app-parking-operation-details-dialog',
    templateUrl: './parking-operation-details-dialog.component.html',
    styleUrls: ['./parking-operation-details-dialog.component.css'],
    standalone: false
})
export class ParkingOperationDetailsDialogComponent {

  // Table data setup
  displayedColumns: string[] = [
    'parking_lot',
    'start',
    'end',
    'fee'
  ];
  dataSource: MatTableDataSource<InvoiceParkingOperation> = new MatTableDataSource<InvoiceParkingOperation>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ParkingOperationDetailsDialogComponentData,
    private feeService: FeeService
  ) {
    this.dataSource.data = data.invoice.parking_operations.sort((a, b) => a.start_time - b.start_time);
  }

  getParkingLot(invoiceParkingOperation: InvoiceParkingOperation): string {
    return this.data.parkingLots.find(parkingLot => parkingLot.id === invoiceParkingOperation.parking_lot_id)?.name ?? "-";
  }

  getFee(invoiceParkingOperation: InvoiceParkingOperation): string {
    return this.feeService.getFormattedFee(invoiceParkingOperation.fee);
  }

}
