<div class="scs-flex-c scs-gap-l class.mat-elevation-z2 class.box-wrapper">

  <span class="days-one">Tarifübersicht</span>

  <div *ngIf="tariffStructure != null; else noTariffStructure"
       class="scs-flex-r scs-gap-l layout-container">

    <div class="scs-flex-c scs-gap-s">

      <!-- weekday bar setting -->
      <div class="scs-flex-r scs-gap-s">
        <app-tariff-setting-color-bar
          *ngFor="let dayBar of dayToColoredHoursMap() | keyvalue"
          [hourArray]="dayBar.value"
          [day]="dayBar.key"
        >
        </app-tariff-setting-color-bar>
      </div>

    </div>

    <div class="scs-flex-c scs-gap-m">

      <div *ngFor="let descriptions of tariffTimeGroupDescriptions(); index as i"
           class="scs-flex-r"
           style="white-space: nowrap">

        <div class="color-box" style="align-self: center"
             [ngStyle]="{ 'background-color': getColorOfTariffTimeGroupIndex(i) }"
        ></div>

        <div class="scs-flex-c scs-gap-xs">
          <div [innerHTML]="descriptions[0]"></div>
          <div [innerHTML]="descriptions[1]"></div>
        </div>
      </div>

      <div *ngIf="tariffStructure != null" class="scs-flex-c scs-gap-l  scs-align-space-between-stretch">
        <div *ngIf="tariffStructure.maximum_duration_minutes != null" class="scs-flex-c">
          <b>Höchstparkdauer</b>
          {{ getMaxPayableParkingDurationDescription(tariffStructure) }}
        </div>

        @if (tariffStructure.max_daily_fee != null) {
          <div class="scs-flex-c  scs-align-space-between-stretch">
            <b>Höchstparkgebühr pro Tag</b>
            {{ getMaxDailyFeeDescription(tariffStructure) }}
          </div>
        }

        @if (tariffStructure.daily_max_fee_reset_time != null) {
          <div class="scs-flex-c scs-align-space-between-stretch">
            <div class="scs-flex-r scs-gap-m scs-align-space-between-stretch">
              <b>Tagesgebühr-Rücksetzungszeitpunkt</b>
              <mat-icon
                matTooltip="Definiert die Uhrzeit ab der die Gebühren wieder steigen können, falls sie durch die maximale Tagesgebühr begrenzt wurden. Typischerweiße wird sie für einen Nachttarif oder 00:00 verwendet."
                color="primary" style="scale: 1.25">info_outline
              </mat-icon>
            </div>
            {{ getDailyMaxFeeResetTimeDescription(tariffStructure) }}
          </div>
        }

        <div *ngIf="tariffStructure.maximum_fee != null" class="scs-flex-c">
          <b>Höchstparkgebühr insgesamt</b>
          {{ getMaxParkingFeeDescription(tariffStructure) }}
        </div>

      </div>

    </div>
  </div>

  <ng-template #noTariffStructure>
    <h4 class="days-one">Es sind keine Tarife hinterlegt!</h4>
  </ng-template>

</div>
