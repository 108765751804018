<div class="scs-flex-c">
  <p *ngIf="totalSelectionFee.value > 0; else please_select" class="days-one"
     style="margin-left:auto;margin-right:10px;font-size: 1rem;">
    Gesamt: <span class="color-accent" style="margin-left:10px; font-size: 1.3rem;">{{ getFormattedFee(totalSelectionFee.value) }}</span> </p>
  <ng-template #please_select>
    <p class="days-one"  *ngIf="selectedParkingOperations.value.length>0">
      Bitte wählen Sie einen Parkvorgang zum Bezahlen aus.
    </p>
  </ng-template>

  <div class="email-input">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Optionale E-Mail für Quittung</mat-label>
      <input matInput
             [disabled]="totalSelectionFee.value <= 0"
             type="email"
             [formControl]="emailAddressFormControl"
             name="email_address"
             autocomplete="email"
            >  <!-- Adjust this value as needed -->
      <mat-error *ngIf="emailAddressFormControl.hasError('email')">
        Ungültige E-Mail Adresse
      </mat-error>
    </mat-form-field>

  </div>


  <app-pay-now-button
    [totalSelectionFee]="totalSelectionFee"
    [selectedParkingOperations]="selectedParkingOperations"
    [email]="getEmail()"
  ></app-pay-now-button>
</div>
