import { Component } from '@angular/core';

@Component({
    selector: 'app-management-tabs-page',
    templateUrl: './management-tabs-page.component.html',
    styleUrls: ['./management-tabs-page.component.css'],
    standalone: false
})
export class ManagementTabsPageComponent {

}
