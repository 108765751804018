import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private payButtonElement: HTMLElement | null = null;

  setPayButton(element: HTMLElement) {
    this.payButtonElement = element;
  }

  getPayButtonElement(): HTMLElement | null {
    return this.payButtonElement;
  }

  scrollToPayButton() {
    if (this.payButtonElement) {
      this.payButtonElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }
}
