<div class="color-accent" style="min-width: fit-content; cursor: pointer; text-decoration:underline;"
     (click)="openParkingOperationDetailsDialog()">
        <span *ngIf="invoice.parking_operations.length == 1"
              class="days-one font-size-h3"
              style="min-width: fit-content">
          {{invoice.parking_operations.length}} Parkvorgang
        </span>

  <span *ngIf="invoice.parking_operations.length != 1"
        class="days-one font-size-h3"
        style="min-width: fit-content">
              {{invoice.parking_operations.length}} Parkvorgänge
        </span>
</div>
