// src/app/config/customer1.config.ts


import {CustomerConfig} from "../CustomerConfig";

export const customer1Config: CustomerConfig = {
  icons: {
    tabIcon: 'logo.png',
    icon: 'logo.png',
  },
  strings: {
    title: 'K+S Payment Website',
    footerText: "Sheesh",
    email: "info@parkraum.de",
    number: "+49 0341 33157290",
    contactRef: "https://www.parkraum.de/impressum/",
    dataProtectionRef: "https://www.parkraum.de/datenschutz/"
  }
};
