/**
 * Groups the given list of objects by key of the object.
 * Please make sure to the object has key as a property name otherwise
 * the returned object contains undefined as key
 * @param list
 * @param key
 */
export function groupArrayOfObjectByProperty(list: Array<any>, key: string) {
  return list.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

/**
 * Returns an object with key of the object as index.
 * Please make sure to the object has key as a property name otherwise
 * the returned object contains undefined as key.
 * If more than one object contains the same value for key, the latest value is getting used.
 * Should only be used for unique key values (like primary keys)
 * @param list
 * @param key
 */
export function arrayOfObjectsToObject(list: Array<any>, key: string) {
  return list.reduce((rv, x) => {
    rv[x[key]] = x;
    return rv;
  }, {});
}

/**
 * Creates a map from the given obj.
 * Keys are always of type string! Make sure to handle that
 * @param obj
 */
export function objectToMap(obj: Object): Map<any, any> {
  return new Map(Object.entries(obj))
}

export type KeyMappingFunction<K> = (a: string) => K;

export function objectToMapTypePreserving<K, V>(obj: Object, keyMapper: KeyMappingFunction<K>): Map<K, V> {
  let map: Map<K, V> = new Map()
  for (let entry of Object.entries(obj)) {
    map.set(keyMapper(entry[0]) as K, entry[1] as V)
  }
  return map
}

/**
 * Creates an object from the given map
 * @param map
 */
export function mapToObject(map: Map<any, any>): Object {
  return Object.fromEntries(map);
}

/**
 * groups a list of objects by a key selection function on that object.
 * Yields the list of objects with the same key in a map.
 */
export function groupBy<T, K>(list: T[], key: (listItem: T) => K): Map<K, T[]> {
  let result = new Map<K, T[]>();
  list.map((item) => {
    let k = key(item);
    let existingList = result.get(k) || [];
    existingList.push(item);
    result.set(k, existingList);
  });

  return result;
}

/**
 * Associates a list of objects by a key selection function on that object.
 * Yields the last element in the list for each key value.
 */
export function associateBy<T, K>(list: T[], key: (listItem: T) => K): Map<K, T> {
  let resultMap = new Map<K, T>();
  list.forEach((item) => resultMap.set(key(item), item))
  return resultMap;
}

/**
 * Associates each item in the list with a value.
 */
export function associateWith<T, V>(list: T[], value: (listItem: T) => V): Map<T, V> {
  let resultMap = new Map<T, V>();
  list.forEach((item) => resultMap.set(item, value(item)))
  return resultMap;
}

/**
 * Takes a list of key-value pairs and puts them into a map.
 */
export function pairsToMap<K, V>(pairs: [K, V][]): Map<K, V> {
  let resultMap = new Map<K, V>();
  pairs.forEach((pair) => resultMap.set(pair[0], pair[1]));
  return resultMap;
}

/**
 * map keys function for a Map.
 * Make sure the keys are unique after the mapping, otherwise entries will be overwritten.
 */
export function mapKeys<K, V, T>(map: Map<K, V>, keyMapper: (key: K, value: V) => T): Map<T, V> {
  let resultMap = new Map<T, V>()
  map.forEach((v, k) => {
    resultMap.set(keyMapper(k, v),v);
  });
  return resultMap;
}

/**
 * map values function for a Map.
 */
export function mapValues<K, V, T>(map: Map<K, V>, valueMapper: (key: K, value: V) => T): Map<K, T> {
  let resultMap = new Map<K, T>()
  map.forEach((v, k) => {
    resultMap.set(k, valueMapper(k, v));
  });
  return resultMap;
}
