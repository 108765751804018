import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {MatIconModule} from "@angular/material/icon";
import {HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {InvoicesPageComponent} from './components/user-page/invoices-page/invoices-page.component';
import {
  ParkingOperationsPageComponent
} from './components/user-page/parking-operation-customer-payment/parking-operations-page/parking-operations-page.component';
import {MatCardModule} from "@angular/material/card";
import {MatSelectModule} from "@angular/material/select";
import {MatDividerModule} from "@angular/material/divider";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  NoExistingParkingOperationsPageComponent
} from './components/user-page/parking-operation-customer-payment/parking-operations-page/no-existing-parking-operations-page/no-existing-parking-operations-page.component';
import {
  ExistingParkingOperationsPageComponent
} from './components/user-page/parking-operation-customer-payment/parking-operations-page/existing-parking-operations-page/existing-parking-operations-page.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
  CreateInvoiceConfirmationDialogComponent
} from './components/user-page/parking-operation-customer-payment/create-invoice-confirmation-dialog/create-invoice-confirmation-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatRadioModule} from "@angular/material/radio";
import {
  SepaPaymentHintDialogComponent
} from './components/user-page/parking-operation-customer-payment/sepa-payment-hint-dialog/sepa-payment-hint-dialog.component';
import {LoginPageComponent} from './components/management/login-page/login-page.component';
import {ManagementTabsPageComponent} from './components/management/management-tabs-page/management-tabs-page.component';
import {MatTabsModule} from "@angular/material/tabs";
import {
  SupportCenterTabComponent
} from './components/management/management-tabs-page/support-center-tab/support-center-tab.component';
import {
  InvoiceManagementTabComponent
} from './components/management/management-tabs-page/invoice-management-tab/invoice-management-tab.component';
import {
  TariffSettingManagementTabComponent
} from './components/management/management-tabs-page/tariff-setting-management-tab/tariff-setting-management-tab.component';
import {
  LicensePlateInputStepComponent
} from './components/management/management-tabs-page/support-center-tab/license-plate-input-step/license-plate-input-step.component';
import {
  ParkingOperationManagementStepComponent
} from './components/management/management-tabs-page/support-center-tab/parking-operation-management-step/parking-operation-management-step.component';
import {MatStepperModule} from "@angular/material/stepper";
import {
  OpenParkingOperationListManagementComponent
} from './components/management/management-tabs-page/support-center-tab/parking-operation-management-step/open-parking-operation-list-management/open-parking-operation-list-management.component';
import {
  BookedParkingOperationListManagementComponent
} from './components/management/management-tabs-page/support-center-tab/parking-operation-management-step/booked-parking-operation-list-management/booked-parking-operation-list-management.component';
import {
  AddSupportBookingDialogComponent
} from './components/management/management-tabs-page/support-center-tab/parking-operation-management-step/open-parking-operation-list-management/add-support-booking-dialog/add-support-booking-dialog.component';
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from '@angular/material/core';
import {
  InvoiceDetailsDialogComponent
} from './components/management/management-tabs-page/invoice-management-tab/invoice-details-dialog/invoice-details-dialog.component';
import {
  SendInvoiceToEmailDialogComponent
} from './components/management/management-tabs-page/invoice-management-tab/invoice-details-dialog/send-invoice-to-email-dialog/send-invoice-to-email-dialog.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatMenuModule} from "@angular/material/menu";
import {
  ParkingOperationWithHoverComponent
} from './components/user-page/invoices-page/parking-operation-with-hover/parking-operation-with-hover.component';
import {UserPageComponent} from './components/user-page/user-page.component';
import {
  ParkingOperationDetailsDialogComponent
} from './components/user-page/invoices-page/parking-operation-with-hover/parking-operation-details-dialog/parking-operation-details-dialog.component';
import {
  TariffSettingQuickOverviewComponent
} from './components/management/management-tabs-page/tariff-setting-management-tab/tariff-setting-quick-overview/tariff-setting-quick-overview.component';
import {
  TariffSettingColorBarComponent
} from './components/management/management-tabs-page/tariff-setting-management-tab/tariff-setting-quick-overview/tariff-setting-color-bar/tariff-setting-color-bar.component';
import {
  TariffSettingDialogComponent
} from './components/user-page/tariff-setting-dialog/tariff-setting-dialog.component';
import {
  TariffSettingEditDialogComponent
} from './components/management/management-tabs-page/tariff-setting-management-tab/tariff-setting-edit-dialog/tariff-setting-edit-dialog.component';
import {
  TariffTimeSettingComponent
} from './components/management/management-tabs-page/tariff-setting-management-tab/tariff-setting-edit-dialog/tariff-time-setting/tariff-time-setting.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatChipsModule} from "@angular/material/chips";
import {
  ConsecutiveFeeInputComponent
} from './components/management/management-tabs-page/tariff-setting-management-tab/tariff-setting-edit-dialog/tariff-time-setting/consecutive-fee-input/consecutive-fee-input.component';
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {
  ManualAddParkingOperationComponent
} from './components/user-page/parking-operation-customer-payment/parking-operations-page/sub-components/manual-add-parking-operation/manual-add-parking-operation.component';
import {
  ParkingOperationSummaryComponent
} from './components/user-page/parking-operation-customer-payment/parking-operations-page/sub-components/parking-operation-summary/parking-operation-summary.component';
import {
  ParkingOperationListComponent
} from './components/user-page/parking-operation-customer-payment/parking-operations-page/sub-components/parking-operation-list/parking-operation-list.component';
import {
  PayParkingOperationsHeaderComponent
} from "./components/user-page/parking-operation-customer-payment/parking-operations-page/sub-components/pay-parking-operations-header/pay-parking-operations-header.component";
import {DataProtectionPageComponent} from './components/data-protection-page/data-protection-page.component';
import {
  CustomTariffInfoDialogComponent
} from './components/user-page/custom-tariff-info-dialog/custom-tariff-info-dialog.component';
import {
  WeekDaySelectorComponent
} from './components/management/management-tabs-page/tariff-setting-management-tab/week-day-selector/week-day-selector.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
  ConsecutiveIntervalsEditComponent
} from "./components/management/management-tabs-page/tariff-setting-management-tab/tariff-setting-edit-dialog/tariff-time-setting/consecutive-intervals-edit/consecutive-intervals-edit.component";
import {
  PaymentLinkPaymentStatusTabComponent
} from "./components/management/management-tabs-page/payment-link-payment-status-tab/payment-link-payment-status-tab.component";
import {
  PayNowButtonComponent
} from "./components/user-page/parking-operation-customer-payment/pay-now-button/pay-now-button.component";
import {
  InvoiceWithPaymentLinkComponent
} from "./components/user-page/invoice-with-payment-link/invoice-with-payment-link.component";
import {
  InvoiceWithPaymentLinkDesktopComponent
} from "./components/user-page/invoice-with-payment-link-desktop/invoice-with-payment-link-desktop.component";
import {FormInputComponent} from "./components/form-input/form-input.component";

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    InvoicesPageComponent,
    ParkingOperationsPageComponent,
    NoExistingParkingOperationsPageComponent,
    ExistingParkingOperationsPageComponent,
    CreateInvoiceConfirmationDialogComponent,
    SepaPaymentHintDialogComponent,
    LoginPageComponent,
    ManagementTabsPageComponent,
    SupportCenterTabComponent,
    InvoiceManagementTabComponent,
    TariffSettingManagementTabComponent,
    LicensePlateInputStepComponent,
    ParkingOperationManagementStepComponent,
    OpenParkingOperationListManagementComponent,
    BookedParkingOperationListManagementComponent,
    AddSupportBookingDialogComponent,
    InvoiceDetailsDialogComponent,
    SendInvoiceToEmailDialogComponent,
    UserPageComponent,
    ParkingOperationDetailsDialogComponent,
    TariffSettingQuickOverviewComponent,
    TariffSettingColorBarComponent,
    TariffSettingDialogComponent,
    TariffSettingEditDialogComponent,
    TariffTimeSettingComponent,
    ConsecutiveFeeInputComponent,
    ManualAddParkingOperationComponent,
    ParkingOperationSummaryComponent,
    ParkingOperationListComponent,
    PayParkingOperationsHeaderComponent,
    DataProtectionPageComponent,
    CustomTariffInfoDialogComponent,
    WeekDaySelectorComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    RouterModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatSnackBarModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatDividerModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatRadioModule,
    MatTabsModule,
    MatStepperModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatChipsModule,
    CdkDrag,
    CdkDropList,
    MatSlideToggleModule,
    ConsecutiveIntervalsEditComponent,
    PaymentLinkPaymentStatusTabComponent,
    PayNowButtonComponent,
    InvoiceWithPaymentLinkComponent,
    InvoiceWithPaymentLinkDesktopComponent,
    ParkingOperationWithHoverComponent

  ],
  providers: [],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
