import {Component, Input, OnChanges, OnInit, signal} from '@angular/core';
import {WEEKDAY_DISPLAY} from "./weekday.constant";

/**
 * This component is responsible for displaying a color bar
 * to represent tariff settings on a specific day of the week.
 */
@Component({
    selector: 'app-tariff-setting-color-bar',
    templateUrl: './tariff-setting-color-bar.component.html',
    styleUrls: ['./tariff-setting-color-bar.component.css'],
    standalone: false
})
export class TariffSettingColorBarComponent implements OnInit, OnChanges {

  /**
   * Array with 24 color entries. Containing the color for each hour on the day.
   */
  @Input() hourArray!: string[];

  /**
   * Day of the week as a number starting with 0 for monday.
   */
  @Input() day!: number;

  /**
   * Signal for displaying an array of hour labels, initially containing an empty string.
   */
  hourArrayDisplay = signal(['']);

  ngOnChanges(): void {
    if (this.hourArray) {
      this.hourArrayDisplay.set(this.hourArray);
    }
  }

  ngOnInit(): void {
    if (this.hourArray) {
      this.hourArrayDisplay.set(this.hourArray);
    }
  }

  getDayDescription(day: number): string {
    return WEEKDAY_DISPLAY[day] ?? "??";
  }

}
