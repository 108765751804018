<div class="scs-flex-c scs-gap-l" style=" height:100%;width:100%;align-self: center; align-items: center; text-align: center; padding: 1rem">

  <p class="days-one">
    Rechnung ({{ getFormattedFee(data.totalFee) }}) für die Parkvorgänge wurde erstellt
    <span *ngIf="data.emailSentSuccessfully">und per E-Mail versandt</span>.
    <span *ngIf="pollingPaymentStatus">Bitte Zahlung im neuen Fenster abschließen</span>
    <span *ngIf="!paymentSuccessful">Bezahlungslink ist nach Eingabe des Kennzeichens jederzeit aufrufbar</span>
  </p>

  <div class="scs-flex-c scs-gap-m" style="align-items: center">
    <!-- Payment button and status section -->
    <ng-container *ngIf="!paymentApproved; else paymentSuccessful">

      <!-- Loading spinner while polling -->
      <div *ngIf="pollingPaymentStatus" class="scs-flex-c scs-gap-s" style="align-items: center">
        <mat-spinner diameter="24"></mat-spinner>
        <span class="days-one" style="font-size: smaller">Zahlungsstatus wird überprüft...</span>
      </div>
    </ng-container>

    <!-- Payment success template -->
    <ng-template #paymentSuccessful>
      <div class="scs-flex-c scs-gap-m" style="align-items: center">
        <mat-icon style="color: green; transform: scale(1.5)">check_circle</mat-icon>
        <span class="days-one color-primary">Zahlung erfolgreich abgeschlossen!</span>
      </div>
    </ng-template>

    <!-- Error state -->
    <ng-container *ngIf="paymentError">
      <mat-icon style="color: red; transform: scale(1.5)">error</mat-icon>
      <span class="days-one color-warn">{{ paymentError }}</span>
      <button mat-flat-button color="primary"
              style="width: fit-content; padding: 1rem; margin-top: 1rem"
              (click)="handlePayment()">
        Erneut versuchen
      </button>
    </ng-container>
  </div>


  <div  mat-dialog-actions class="button-container">
    <button mat-fab extended
            [disabled]="isDownloadingInvoice"
            style="width: 50%; padding: 1.5rem; "
            (click)="downloadInvoice()">
      <mat-icon>download</mat-icon>
      Rechnung
    </button>

    <button mat-fab extended
            [disabled]="!paymentApproved || pollingPaymentStatus"
            style="width: 50%; padding: 1.5rem;"
            (click)="downloadReceipt()">
      <mat-icon>download</mat-icon>
      Quittung
    </button>
  </div>


  <mat-spinner *ngIf="isDownloadingInvoice"></mat-spinner>


  <div mat-dialog-actions>
    <button mat-flat-button
            class="color-grey"
            [matDialogClose]="paymentApproved ? 'PAYMENT_SUCCESSFUL' : paymentError ? 'PAYMENT_FAILED' : undefined">
      {{ paymentApproved ? 'Fertig' : 'Schließen' }}
    </button>
  </div>
</div>
