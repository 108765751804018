/**
 * Downloads a new csv file.
 * @param filename
 * @param rows
 * @param headers
 * @param columnSeparator
 * @param rowDelimiter
 */
export const exportToCsv = (filename: string, rows: string[][], headers: string[], columnSeparator: string = ";", rowDelimiter: string = "\n"): void => {
  if (!rows || !rows.length) {
    return;
  }

  const csvContent =
    headers.join(columnSeparator) + rowDelimiter +
    rows.map(row => row.join(columnSeparator)).join(rowDelimiter);

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // @ts-ignore
  if (navigator.msSaveBlob) { // In case of IE 10+
    // @ts-ignore
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
