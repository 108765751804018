import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {
  CompleteParkingOperation,
  ParkingOperation,
  ParkingOperationWithBookingsAndFees
} from "../../models/ParkingOperation";
import {ParkingLot} from "../../models/ParkingLot";
import {TariffStructure, TariffWithTariffTimes} from "../../models/TariffStructure";
import {CreatedInvoiceResponse} from "../../models/CreatedInvoiceResponse";
import {Invoice} from "../../models/Invoice";
import {BASE_URL} from "./url";
import {AuthService} from "../auth.service";
import {HttpHeadersWrapper} from "./models/HttpHeadersWrapper";
import {CustomTariffDescription} from "../../models/CustomTariffDescription";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../models/InvoiceWithPaymentLink";

@Injectable({
  providedIn: 'root'
})
export class ApiRoutesService {

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient,
              private authService: AuthService
  ) {
  }

  private getAuthenticatedHttpOptions(contentType: string | undefined = undefined): HttpHeadersWrapper {
    return {
      headers: new HttpHeaders({
        'Content-Type': contentType ?? 'application/json',
        'Authorization': 'Bearer ' + (this.authService.getToken() ?? "")
      })
    };
  }

  /**
   * Queries the parking fee for a given parking operation.
   */
  getParkingFee(parkingOperation: ParkingOperation): Observable<ParkingOperationWithBookingsAndFees> {
    return this.http.post<ParkingOperationWithBookingsAndFees>(
      BASE_URL + `payment-website/parkinglots/${parkingOperation.start_event!!.parking_lot_id!!}/fees`,
      parkingOperation,
      this.httpOptions
    ).pipe(
      map(
        parkingOperationWithBookingsAndFees => {
          let tempOperation = parkingOperation
          tempOperation.longer_than_allowed = parkingOperationWithBookingsAndFees.parking_operation.longer_than_allowed
          parkingOperationWithBookingsAndFees.parking_operation = tempOperation;
          return parkingOperationWithBookingsAndFees
        }
      )
    );
  }

  /**
   * Queries all available parking lots.
   */
  getParkingLots(): Observable<ParkingLot[]> {
    return this.http.get<ParkingLot[]>(BASE_URL + `payment-website/parking-lots`, this.httpOptions);
  }

  getParkingLotsWithCustomTariff(): Observable<CustomTariffDescription[]> {
    return this.http.get<CustomTariffDescription[]>(BASE_URL + `/payment-website/custom-tariff-descriptions`, this.httpOptions);
  }

  /**
   * Queries the tariff structure for a given parking lot.
   */
  getTariffStructure(parkingLotId: number): Observable<TariffStructure> {
    return this.http.get<TariffWithTariffTimes>(BASE_URL + `payment-website/parking-lots/${parkingLotId}/tariff`, this.httpOptions)
      .pipe(
        map((backendResponse: TariffWithTariffTimes) => {
          return {
            parking_lot_id: backendResponse.tariff.parking_lot_id,
            tariff_id: -1,
            billing_interval_minutes: backendResponse.tariff.billing_interval_minutes,
            maximum_duration_minutes: backendResponse.tariff.maximum_duration_minutes,
            maximum_fee: backendResponse.tariff.maximum_fee,
            currency: backendResponse.tariff.currency,
            subtract_free_parking_duration: backendResponse.tariff.subtract_free_parking_duration,
            max_daily_fee: backendResponse.tariff.max_daily_fee,
            daily_max_fee_reset_time: backendResponse.tariff.daily_max_fee_reset_time,
            tariff_times: backendResponse.tariff_times.map(tariffTime => {
              return {
                tariff_id: tariffTime.tariff_id,
                start_time: tariffTime.start_time,
                end_time: tariffTime.end_time,
                day_of_week_start: tariffTime.day_of_week_start - 1,
                day_of_week_end: tariffTime.day_of_week_end - 1,
                fee: tariffTime.fee,
                flat_fee: tariffTime.flat_fee,
                consecutive_fees: tariffTime.consecutive_fees,
                billing_interval_minutes: tariffTime.billing_interval_minutes,
                consecutive_billing_interval_minutes: tariffTime.consecutive_billing_interval_minutes,
                free_parking_duration_minutes: tariffTime.free_parking_duration_minutes
              }

            })
          };
        })
      );
  }

  /**
   * Queries the parking operations including all matched existing bookings and the fee of that parking operation
   * for a given normalized license plate.
   */
  getParkingOperationsWithBookings(licensePlate: string, normalizedLicensePlate: string): Observable<ParkingOperationWithBookingsAndFees[]> {
    return this.http.post<ParkingOperationWithBookingsAndFees[]>(
      BASE_URL + `payment-website/license-plate/parking-operations`,
      {
        normalized_license_plate: normalizedLicensePlate,
        license_plate: licensePlate
      },
      this.httpOptions
    ).pipe(
      map(
        parkingOperationsWithBookingsAndFees => {
          return parkingOperationsWithBookingsAndFees.map(parkingOperationWithBookingsAndFees => {
            return {
              parking_operation: {
                start_event: parkingOperationWithBookingsAndFees.parking_operation.start_event,
                start_event_editable: false,
                payment_start_time: parkingOperationWithBookingsAndFees.parking_operation.payment_start_time,
                end_event: parkingOperationWithBookingsAndFees.parking_operation.end_event,
                end_event_editable: false,
                license_plate: licensePlate,
                normalized_license_plate: normalizedLicensePlate,
                longer_than_allowed: parkingOperationWithBookingsAndFees.parking_operation.longer_than_allowed
              },
              bookings: parkingOperationWithBookingsAndFees.bookings,
              fee: parkingOperationWithBookingsAndFees.fee
            };
          });
        }
      )
    );

  }

  /**
   * Creates an invoice for a given license plate and parking operations.
   * If the email is undefined, the invoice is created without sending it to the customer.
   * This request may take several seconds especially if the invoice needs to be sent to the customer
   */
  createInvoice(normalizedLicensePlate: string, licensePlate: string, email: string | undefined, parkingOperations: CompleteParkingOperation[]): Observable<CreatedInvoiceResponse> {
    return this.http.post<CreatedInvoiceResponse>(
      BASE_URL + `payment-website/invoice`,
      {
        normalized_license_plate: normalizedLicensePlate,
        license_plate: licensePlate,
        email: email,
        parking_operations: parkingOperations
      },
      this.httpOptions
    );
  }

  downloadInvoiceWithPaymentLinkPdf(normalizedLicensePlate: string, licensePlate: string, invoiceId: string): Observable<any> {
    return this.http.post<Blob>(
      BASE_URL + `payment-website/download-invoice-payment-link-pdf/${invoiceId}`,
      {
        normalized_license_plate: normalizedLicensePlate,
        license_plate: licensePlate,
      },
      {
        headers: this.getAuthenticatedHttpOptions('application/json').headers,
        responseType: 'blob' as any
      }
    );
  }

  downloadReceiptPdf(normalizedLicensePlate: string, licensePlate: string, invoiceId: string): Observable<any> {
    return this.http.post<Blob>(
      BASE_URL + `payment-website/download-receipt-pdf/${invoiceId}`,
      {
        normalized_license_plate: normalizedLicensePlate,
        license_plate: licensePlate,
      },
      {
        headers: this.getAuthenticatedHttpOptions('application/json').headers,
        responseType: 'blob' as any
      }
    );
  }

  getInvoicesOfLicensePlate(normalizedLicensePlate: string): Observable<Invoice[]> {
    return this.http.post<Invoice[]>(
      BASE_URL + `payment-website/license-plate/invoices`,
      {
        normalized_license_plate: normalizedLicensePlate
      },
      this.getAuthenticatedHttpOptions()
    );
  }

  getInvoicesWithPaymentLink(licensePlate: string): Observable<PaymentWebsiteInvoiceWithPaymentLink[]> {
    return this.http.get<PaymentWebsiteInvoiceWithPaymentLink[]>(
      BASE_URL + 'payment-website/license-plate/invoices-with-payment-link?licensePlate=' + licensePlate,
      this.httpOptions
    );
  }


  sendInvoicesToEmail(normalizedLicensePlate: string, invoiceIds: string[], email: string): Observable<never> {
    return this.http.post<never>(
      BASE_URL + `payment-website/invoices/e-mail/send`,
      {
        normalized_license_plate: normalizedLicensePlate,
        invoice_ids: invoiceIds,
        email: email
      },
     this.getAuthenticatedHttpOptions()
    )
  }

  sendReceiptToEmail(normalizedLicensePlate: string, invoiceId: number, email: string): Observable<never> {
    return this.http.post<never>(
      BASE_URL + `payment-website/receipt/${invoiceId}/e-mail/send`,
      {
        normalized_license_plate: normalizedLicensePlate,
        email: email,
        invoice_id: invoiceId
      },
      this.httpOptions
    )
  }

}
