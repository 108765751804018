<div style="display: flex; flex-direction: row; align-items: center">

  <!--            <mat-checkbox color="primary" style="width: 2rem; min-width: 2rem; margin: 5px 10px"-->
  <!--                          (change)="selectionChange(invoiceWithPaymentLink, $event.checked)">-->
  <!--            </mat-checkbox>-->

  <span style="width: 12rem; min-width: 12rem">{{ invoiceWithPaymentLink.invoice.invoice_id_string }}</span>

  <span style="width: 12rem; min-width: fit-content">
            {{ invoiceWithPaymentLink.invoice.created_at * 1000 | date: 'dd.MM.yyyy HH:mm' }}
          </span>

  <app-parking-operation-with-hover
    [invoice]="invoiceWithPaymentLink.invoice"
    [parkingLots]="parkingLots"
    style="width: 15rem; margin: 5px 10px">
  </app-parking-operation-with-hover>
  <span style="width: 12rem; min-width: fit-content">
            {{ getFormattedTotalFee(invoiceWithPaymentLink.invoice) }}
          </span>

  <div class="scs-flex-r" style="width: 12rem; min-width: fit-content; margin: 5px 10px; text-align: center">
    <button mat-icon-button
            *ngIf="!isDownloadingInvoice(invoiceWithPaymentLink.invoice, downloadingInvoiceIds); else spinner"

            (click)="downloadInvoicePdf(invoiceWithPaymentLink.invoice)">
      <mat-icon style="color: #024670;">receipt</mat-icon>

    </button>

    <ng-template #spinner>
      <mat-spinner color="primary" style="width: 2rem; height: 2rem"></mat-spinner>
      <span style="font-size: smaller; margin-left: 5px; align-self: center">laden...</span>
    </ng-template>
  </div>


  <div class="scs-flex-r" style="width: 12rem; min-width: fit-content; margin: 5px 10px; text-align: center"
       *ngIf="invoiceWithPaymentLink.paymentLink?.payment_status?.toUpperCase() === 'APPROVED';else noReceipt">
    <button mat-icon-button
            *ngIf="!isDownloadingInvoice(invoiceWithPaymentLink.invoice, downloadingInvoiceIds); else spinner"
            style="background-color: transparent; box-shadow: none"
            (click)="downloadReceiptPdf(invoiceWithPaymentLink.invoice)">
      <mat-icon style="color: #024670;">receipt</mat-icon>
    </button>

    <ng-template #spinner>
      <mat-spinner color="primary" style="width: 2rem; height: 2rem"></mat-spinner>
      <span style="font-size: smaller; margin-left: 5px; align-self: center">laden...</span>
    </ng-template>
  </div>

  <ng-template #noReceipt>
    <div class="scs-flex-r"
         style="width: 12rem; min-width: fit-content; margin: 5px 10px; text-align: center">
      <button mat-icon-button [disabled]="true"
              style="background-color: transparent; box-shadow: none"
      >
        <mat-icon style="color: #9E9E9E;">receipt</mat-icon>
      </button>
    </div>
  </ng-template>

  <span style="width: 12rem; min-width: fit-content">
  <ng-container *ngIf="invoiceWithPaymentLink.paymentLink?.expiration_date; else noExpirationdate">
    <ng-container>
      <span

        [style.color]="isPaymentLinkExpired(invoiceWithPaymentLink.paymentLink?.expiration_date, invoiceWithPaymentLink.paymentLink!.payment_status!) ? '#d32f2f' : 'black'"
      >
        {{ (invoiceWithPaymentLink.paymentLink?.expiration_date || 0) * 1000 | date: 'dd.MM.yyyy HH:mm' }}
      </span>
    </ng-container>

  </ng-container>
  <ng-template #noExpirationdate>
    /
  </ng-template>
</span>

  <span style="width: 12rem; min-width: fit-content">
  <ng-container *ngIf="invoiceWithPaymentLink.paymentLink; else noPaymentLink">
    <ng-container
      *ngIf="invoiceWithPaymentLink.paymentLink?.payment_status?.toUpperCase() === 'APPROVED'; else notPaidYet">
      <span class="payment-status-approved">
        <mat-icon class="payment-success-icon">check_circle</mat-icon>
        <span class="payment-status-text">Bezahlt</span>
      </span>
    </ng-container>

<ng-template #notPaidYet>

<ng-container *ngIf="!hasUnrecoverablePaymentError(invoiceWithPaymentLink); else unrecoverableError">
  <ng-container
    *ngIf="!isPaymentLinkExpired(invoiceWithPaymentLink.paymentLink?.expiration_date, invoiceWithPaymentLink.paymentLink?.payment_status); else paymentLinkExpired">
    <!-- Initial Payment Button -->
    <button
      *ngIf="!checkoutInProgress"
      mat-fab
      extended
      style="padding: 1.5rem;margin-top:10px;height:40px;"
      (click)="initiatePayment(invoiceWithPaymentLink)"
    >
      <mat-icon>payment</mat-icon>
      <span>Bezahlen</span>
    </button>

    <!-- Loading Button -->
    <button
      mat-fab
      extended
      class="pay-button"
      color="primary"
      style="padding: 1.5rem;margin-top:10px;height:40px;"
      [disabled]="true"
      *ngIf="checkoutInProgress"
    >
      <div class="spinner-container">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      {{ getLoadingStatusString() }}
    </button>
  </ng-container>
</ng-container>

<ng-template #unrecoverableError>
  <div class="payment-error-notice">
    <mat-icon color="warn">error</mat-icon>
    <span>Zahlung wurde aus Sicherheitsgründen gesperrt.</span>
  </div>
</ng-template>

<ng-template #paymentLinkExpired>
  <span class="payment-status-expired">
    <span class="payment-status-text" style="color:#d32f2f">Frist abgelaufen</span>
  </span>
</ng-template>

      <ng-template #paymentLinkExpired>
        <span class="payment-status-expired">
          <span class="payment-status-text" style="color:#d32f2f">Frist abgelaufen</span>
        </span>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template #noPaymentLink>
    /
  </ng-template>
</span>


</div>
