<div class="container">
  <app-pay-parking-operations-header></app-pay-parking-operations-header>
<!--  <h3 class="days-one" style="margin-top: 10px;" *ngIf="!hideAddAdditionalParkingOperationsView">-->
<!--    Parkvorgänge hinzufügen-->
<!--  </h3>-->

  <button
          mat-button
          style="width: fit-content; min-width: 50px;box-shadow: none !important;"
          class="days-one"
          (click)="clearStateAndGoToLanding()">
    Kennzeichen ändern
  </button>
  <app-manual-add-parking-operation
    *ngIf="showAddManualParkingOperationView()"
    class="manual-add-parking-operation-container"
    [parkingLots]="parkingLots"
    [parkingOperationsWithBookingsAndFeesSelections]="parkingOperationsWithBookingsAndFeesSelections"
    (recomputeSelectedFees)="emitNewParkignOperationAndHide()">
  </app-manual-add-parking-operation>


  <h3 class="days-one" style="margin-top:20px;" *ngIf="parkingOperationsWithBookingsAndFeesSelections.length > 0">
    Ihre offenen Parkvorgänge
  </h3>

  <app-parking-operation-list style="margin-top:20px;" [parkingLots]="parkingLots"
                              [parkingOperationsWithBookingsAndFeesSelections]="parkingOperationsWithBookingsAndFeesSelections"
                              [recomputeSelectedFeesTrigger]="recomputeSelectedFeesTriggerSubject.asObservable()"
                              (selectedParkingOperationsChange)="selectedParkingOperations.next($event)"
                              (totalSelectionFeeChange)="totalSelectionFee.next($event)">
  </app-parking-operation-list>
  <h3 class="days-one" style="margin-top: 10px" *ngIf="!showAddManualParkingOperationView()"
  >Fehlt ein Parkvorgang?</h3>
  <button *ngIf="!showAddManualParkingOperationView()"
          mat-button color="primary"
          style="width: fit-content; min-width: 50px; margin: 0 10px 20px;  box-shadow: none !important;"
          class="days-one"
          (click)="hideAddAdditionalParkingOperationsView = false">
    Hinzufügen
  </button>

  <app-parking-operation-summary  [parkingLots]="parkingLots"
                                 [selectedParkingOperations]="selectedParkingOperations"
                                 [totalSelectionFee]="totalSelectionFee"
                                 (reloadParkingOperationsTrigger)="reloadParkingOperationsTrigger.emit()">
  </app-parking-operation-summary>

</div>
