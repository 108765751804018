import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../auth.service";
import {CompleteParkingOperation} from "../../models/ParkingOperation";
import {CreatedInvoiceWithPaymentLinkResponse, PaymentWebsitePaymentLink} from "../../models/CreatedInvoiceResponse";
import {BASE_URL} from "../api/url";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../models/InvoiceWithPaymentLink";

@Injectable({
  providedIn: 'root'
})
export class TelecashPaymentServiceService {

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  constructor(private http: HttpClient,
              private authService: AuthService
  ) {
  }


  /**
   * Creates an invoice for a given license plate and parking operations.
   * If the email is undefined, the invoice is created without sending it to the customer.
   * This request may take several seconds especially if the invoice needs to be sent to the customer
   */
  createInvoiceAndPaymentLink(normalizedLicensePlate: string, licensePlate: string, email: string | undefined, parkingOperations: CompleteParkingOperation[]): Observable<CreatedInvoiceWithPaymentLinkResponse> {
    return this.http.post<CreatedInvoiceWithPaymentLinkResponse>(
      BASE_URL + `payment-website/payment-link`,
      {
        normalized_license_plate: normalizedLicensePlate,
        license_plate: licensePlate,
        email: email,
        parking_operations: parkingOperations
      },
      this.httpOptions
    );
  }


  // method to get a paymentlink for a given payment link
  getPaymentLink(paymentLinkId: string,licensePlate:string): Observable<PaymentWebsitePaymentLink> {
    return this.http.get<PaymentWebsitePaymentLink>(BASE_URL + `payment-website/payment-link/${paymentLinkId}?licensePlate=${licensePlate}`
      , this.httpOptions);
  }

  createMissingPaymentLinkIfNecessary(invoice_id: string, license_plate: string):Observable<CreatedInvoiceWithPaymentLinkResponse> {
    return this.http.post<CreatedInvoiceWithPaymentLinkResponse>(
      BASE_URL + `payment-website/invoice/create-payment-link`,
      {
        invoice_id: invoice_id,
        license_plate: license_plate,
      },
      this.httpOptions
    );
  }
}
