<div class="scs-flex-c" style="margin: 1rem; overflow: auto">

  <h1 class="days-one color-primary">Tarif einstellen: {{ data.parkingLot.name }}</h1>

  <div class="scs-flex-r scs-gap-xl">

    <div class="scs-flex-c scs-gap-s" style="max-width: 70vw">

      <h4 style="margin: 0">Allgemeines</h4>

<!--      <div class="scs-flex-r scs-gap-s" style="align-items: center">-->
<!--        <span style="width: 10rem"-->
<!--              matTooltip="Stellen sie hier ein in welchem Intervall Kosten anfallen sollen. Beispiel: Werden 60 Minuten eingestellt, fällt die eingestellte Gebühr für jede angefangene Stunde an"-->
<!--        >-->
<!--          Abrechnungsintervall:-->
<!--        </span>-->

<!--        <form>-->
<!--          <mat-form-field appearance="outline">-->
<!--            <input matInput type="number" min="1" step="1"-->
<!--                   [formControl]="billingIntervalMinutesFormControl" placeholder="Beispiel: 60"-->
<!--                   (change)="onBillingIntervalMinutesChange()"-->
<!--                   name="billing_interval_minutes" required-->
<!--                   style="text-align: end">-->
<!--          </mat-form-field>-->
<!--          <mat-error *ngIf="billingIntervalMinutesFormControl.hasError('required')">-->
<!--            Pflichtfeld-->
<!--          </mat-error>-->
<!--        </form>-->

<!--        <span>Minuten</span>-->
<!--      </div>-->

      <div class="scs-flex-r scs-gap-s" style="align-items: center">
        <span style="width: 10rem"
              matTooltip="Stellen sie hier ein bis zu welcher Parkdauer ein Parkvorgang bezahlt werden kann. Beispiel: Werden 120 Minuten eingestellt, darf ein Parkvorgang maximal zwei Stunden dauern und es kann auch nicht für eine längere Parkdauer bezahlt werden."
        >
          Höchstparkdauer:
        </span>

        <form>
          <mat-form-field appearance="outline">
            <input matInput type="number" min="1" step="1"
                   [formControl]="maximumDurationInMinutesFormControl" placeholder="Beispiel: 90"
                   (change)="onMaximumDurationInMinutesChange()"
                   name="maximum_duration_in_minutes"
                   style="text-align: end">
          </mat-form-field>
        </form>

        <span>Minuten</span>
      </div>

      <div class="scs-flex-r scs-gap-s" style="align-items: center">
        <span style="width: 10rem"
              matTooltip="Stellen sie hier die maximale Gebühr ein, die für einen Parkvorgang anfallen kann. Die entstehenden Parkgebühren werden auf diesen Betrag gedeckelt."
        >
          Höchstparkgebühr:
        </span>

        <form>
          <mat-form-field appearance="outline">
            <input matInput type="number" min="1" step="0.01"
                   [formControl]="maximumFeeFormControl" placeholder="Beispiel: 10"
                   (change)="onMaximumFeeChange()"
                   name="maximum_fee"
                   style="text-align: end">
          </mat-form-field>
        </form>

        <span>EUR</span>
      </div>
      <!-- Same for daily max fee-->
      <div class="scs-flex-r scs-gap-s" style="align-items: center">
        <span style="width: 10rem"
              matTooltip="Stellen sie hier die maximale Gebühr ein
              die für einen Parkvorgang anfallen kann. Die entstehenden Parkgebühren werden auf diesen Betrag gedeckelt."
        >
          Höchstparkgebühr pro Tag:
        </span>

        <form>
          <mat-form-field appearance="outline">
            <input matInput type="number" min="1" step="0.01"
                   [formControl]="dailyMaximumFeeFormControl" placeholder="Beispiel: 10"
                   (change)="onDailyMaximumFeeChange()"
                   name="daily_maximum_fee"
                   style="text-align: end">
          </mat-form-field>
        </form>

        <span>EUR</span>
      </div>

      <!-- Smae for daily max fee reset time-->
      <div class="scs-flex-r scs-gap-s" style="align-items: center">
        <span style="width: 10rem"
              matTooltip="Stellen sie hier die Uhrzeit ein, zu der die tägliche maximale Gebühr zurückgesetzt wird."
        >
          Tägliche Gebühr zurücksetzen um:
        </span>

        <form>
          <mat-form-field appearance="outline">
            <input matInput type="time"
                   [formControl]="dailyMaximumFeeResetTimeFormControl"
                   (change)="onDailyMaximumFeeResetTimeChange()"
                   name="daily_maximum_fee_reset_time"
                   style="text-align: end">
          </mat-form-field>
        </form>

        <span>Uhr</span>
      </div>

      <h4 style="margin: 1rem 0 0;">Zeiträume und Parkgebühren</h4>

      <div style="overflow-y: auto; max-height: 40vh">

        <div class="scs-flex-c scs-gap-s" style="margin: 10px">
          @for (groupedTariffTime of getSortedGroupedTariffTimes(groupedTariffTimesArray()); track groupedTariffTime) {
            <mat-card>
              <div class="scs-flex-r scs-gap-m" style="justify-content: center">
                <app-tariff-time-setting
                  [groupedTariffTime]="groupedTariffTime"
                  (dayChange)="onDayChange(groupedTariffTime, $event)"
                  [billing_interval_minutes]="tariffStructure.billing_interval_minutes"
                  (multiDayCheckEmitter)="onMultiDayCheckChange(groupedTariffTime,$event)"
                  (tariffTimeChange)="onTariffTimeChange(groupedTariffTime,$event)">
                </app-tariff-time-setting>

                <button mat-icon-button style="align-self: center"
                        (click)="removeTariffTime(groupedTariffTime)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-card>
          }
        </div>
      </div>

      <button mat-stroked-button color="primary" style="width: fit-content;" class="mat-elevation-z2"
              (click)="addTariffTimeGroup()">
        <span *ngIf="tariffStructure.tariff_times.length > 0">Weiteren </span>Zeitraum hinzufügen + <span
        *ngIf="tariffStructure.tariff_times.length == 0">(Eingabe mit Enter pro angefangene {{ tariffStructure.billing_interval_minutes }}
        Minuten. Beispiel: 1,5 [Enter])</span>
      </button>

    </div>

    <div class="scs-flex-c" style="flex-grow: 1">
      <mat-card>
        <app-tariff-setting-quick-overview
          [tariffStructureSubject]="tariffStructureSubject"
          style="margin: 1rem">
        </app-tariff-setting-quick-overview>
      </mat-card>

      <!--spacer-->
      <div class=scs-flex-c style="flex-grow: 1; justify-content: center">

        <p *ngIf="errorMessage != null"
           class="color-warn"
           style="margin: 1rem; text-align: center"
           [innerHTML]="errorMessage">
        </p>

      </div>

      <button mat-raised-button mat-flat-button="" color="primary" autofocus=""
              class="mat-focus-indicator mat-flat-button mat-button-base mat-primary"
              (click)="onConfirmClick()"
              [disabled]="disableConfirmButton">
        <span class="mat-button-wrapper"> Tarifeinstellungen übernehmen </span>
        <div class="mat-ripple mat-button-ripple"></div>
        <div class="mat-button-focus-overlay"></div>
      </button>

    </div>
  </div>

</div>
