import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Invoice} from "../../../../models/Invoice";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../../../models/InvoiceWithPaymentLink";

@Component({
    selector: 'app-payment-link-payment-status-tab',
    imports: [],
    templateUrl: './payment-link-payment-status-tab.component.html',
    styleUrl: './payment-link-payment-status-tab.component.css'
})
export class PaymentLinkPaymentStatusTabComponent {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild('monthPicker') monthPicker!: ElementRef<HTMLInputElement>;

  isLoadingInvoices = false;

  displayedColumns: string[] = [
    'invoice_id',
    'date',
    'license_plate',
    'fee',
    'payment_status',
    'payment_date'
  ];

  dataSource: MatTableDataSource<PaymentWebsiteInvoiceWithPaymentLink> = new MatTableDataSource<PaymentWebsiteInvoiceWithPaymentLink>([]);


}
