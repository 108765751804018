import {Component, OnInit} from '@angular/core';
import {GlobalStateService} from "../../../../services/global-state.service";
import {Router} from "@angular/router";
import {ApiCallWrapperService} from "../../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../../services/api/api-routes.service";
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {ParkingLot} from "../../../../models/ParkingLot";
import {ParkingLotsCacheService} from "../../../../services/parking-lots-cache.service";
import {ParkingOperationWithBookingsAndFees} from "../../../../models/ParkingOperation";
import {CustomTariffDescription} from "../../../../models/CustomTariffDescription";
import {ParkingOperationsPageTabIndexService} from "../../../../services/parking-operations-page-tab-index.service";
import {LocalStorageService} from "../../../../services/local-storage.service";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../../../models/InvoiceWithPaymentLink";

@Component({
  selector: 'app-parking-operations-page',
  templateUrl: './parking-operations-page.component.html',
  styleUrls: ['./parking-operations-page.component.css'],
  standalone: false
})
export class ParkingOperationsPageComponent implements OnInit {


  parkingLots: ParkingLot[] = [];
  parkingLotsWithCustomTariffDescription: CustomTariffDescription[] = [];
  parkingOperationsWithBookingsAndFeesSelections: ParkingOperationWithBookingsAndFeesWithSelection[] = [];
  reloadParkingOperationsFunction: () => void = () => this.reloadParkingOperations();
  invoices: PaymentWebsiteInvoiceWithPaymentLink[] = []
  isLoading = true;
  useNoExistingParkingOperationsPage = false;
  totalFee = 0;

  constructor(
    private router: Router,
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    private parkingLotsCacheService: ParkingLotsCacheService,
    public globalStateService: GlobalStateService,
    private parkingOperationsPageTabIndexService: ParkingOperationsPageTabIndexService,
    localStorageService: LocalStorageService
  ) {
    let existingLicensePlate = localStorageService.getLicensePlateData()
    if (existingLicensePlate) {
      globalStateService.normalizedLicensePlate = existingLicensePlate.normalizedLicensePlate
      globalStateService.licensePlateInput = existingLicensePlate.licensePlate
      return
    }
    if (globalStateService.normalizedLicensePlate.length == 0 || globalStateService.licensePlateInput.length == 0) {
      this.router.navigateByUrl("/");
    }
  }

  calculateTotalFee(): number {
    return this.parkingOperationsWithBookingsAndFeesSelections
      .reduce((sum, parkingOperationWithBookingsAndFeesSelection) =>
        sum + parkingOperationWithBookingsAndFeesSelection.parking_operation.fee, 0);
  }

  async ngOnInit() {
    this.parkingLotsCacheService.getParkingLots().then(parkingLots => {
      this.parkingLots = parkingLots ?? [];
    });

    this.parkingLotsCacheService.getParkingLotsWithCustomTariff().then(parkingLotsWithCustomTariff => {
      console.log(parkingLotsWithCustomTariff);
      this.parkingLotsWithCustomTariffDescription = parkingLotsWithCustomTariff ?? [];
    });
    let invoices = await this.apiCallHelper.call(
      this.apiRoutesService.getInvoicesWithPaymentLink(this.globalStateService.normalizedLicensePlate)
    )
    if (invoices != null) {
      this.invoices = invoices || []
    }
    this.reloadParkingOperations();
  }

  reloadParkingOperations() {
    this.isLoading = true;

    this.apiCallHelper.call(
      this.apiRoutesService.getParkingOperationsWithBookings(
        this.globalStateService.licensePlateInput,
        this.globalStateService.normalizedLicensePlate,
      )
    ).then(parkingOperationsWithBookingsAndFees => {
      const newParkingOperations = (parkingOperationsWithBookingsAndFees ?? [])
        // .filter(parkingOperationWithBookingsAndFees =>
        //   notCompletedAndFreeFilter(parkingOperationWithBookingsAndFees))
        .map(parkingOperationWithBookingsAndFees => {
          return new ParkingOperationWithBookingsAndFeesWithSelection(
            parkingOperationWithBookingsAndFees,
            true,
            parkingOperationWithBookingsAndFees.parking_operation.start_event == null ? "" : null,
            parkingOperationWithBookingsAndFees.parking_operation.end_event == null ? "" : null
          );
        });

      this.parkingOperationsWithBookingsAndFeesSelections.splice(0);
      this.parkingOperationsWithBookingsAndFeesSelections.push(...newParkingOperations);
      this.useNoExistingParkingOperationsPage = newParkingOperations.length == 0;
      this.isLoading = false;
      this.totalFee = this.calculateTotalFee();
      if (this.parkingOperationsWithBookingsAndFeesSelections.length == 0 && this.invoices.length > 0) {
        console.log("Switching to invoices")
        if (!this.parkingOperationsPageTabIndexService.tabIndexChanged) {
          this.parkingOperationsPageTabIndexService.switchToInvoiceTab();
        }
        return
      }
      if (this.totalFee == 0 && this.parkingOperationsWithBookingsAndFeesSelections.length > 0) {
        if (!this.parkingOperationsPageTabIndexService.tabIndexChanged) {
          this.parkingOperationsPageTabIndexService.switchToInvoiceTab();
        }
      }
    });
  }

}


/**
 * Returns  true if  the parking operation is not completed or completed but not free
 * @param parkingOperationWithBookingsAndFee
 */
export function notCompletedAndFreeFilter(parkingOperationWithBookingsAndFee: ParkingOperationWithBookingsAndFees): boolean {
  return !(parkingOperationWithBookingsAndFee.parking_operation.start_event != null && parkingOperationWithBookingsAndFee.parking_operation.end_event != null && parkingOperationWithBookingsAndFee.fee == 0);
}
