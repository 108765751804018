import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {ApiCallWrapperService} from "../../../../services/api/api-call-wrapper.service";
import {ManagementApiRoutesService} from "../../../../services/api/management-api-routes";
import {MatPaginator} from "@angular/material/paginator";
import {exportToCsv} from "../../../../services/csv-export-helper";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {InvoiceDetailsDialogComponent} from "./invoice-details-dialog/invoice-details-dialog.component";
import {GlobalStateService} from "../../../../services/global-state.service";
import {ParkingLotsCacheService} from "../../../../services/parking-lots-cache.service";
import {InvoiceDownloadService} from "../../../../services/invoice-download-service";
import {FeeService} from "../../../../services/fee.service";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../../../models/InvoiceWithPaymentLink";

@Component({
  selector: 'app-invoice-management-tab',
  templateUrl: './invoice-management-tab.component.html',
  styleUrls: ['./invoice-management-tab.component.css'],
  standalone: false
})
export class InvoiceManagementTabComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild('monthPicker') monthPicker!: ElementRef<HTMLInputElement>;

  isLoadingInvoices = false;
  isExportingCsv = false;
  isExportingPdf = false;

  incompleteMonth = false;

  // Table data setup
  displayedColumns: string[] = [
    'invoice_id',
    'date',
    'license_plate',
    'fee',
    'payment_status',
    'payment_expiration_date',
    'details',
  ];
  dataSource: MatTableDataSource<PaymentWebsiteInvoiceWithPaymentLink> = new MatTableDataSource<PaymentWebsiteInvoiceWithPaymentLink>([]);

  filteredInvoices: PaymentWebsiteInvoiceWithPaymentLink[] = [];

  startDate = new Date();

  filterType: string = "license_plate";
  filterSearchString: string = "";
  private filterFunction: (invoice: PaymentWebsiteInvoiceWithPaymentLink) => boolean = (invoice: PaymentWebsiteInvoiceWithPaymentLink) => true;

  allInvoices: PaymentWebsiteInvoiceWithPaymentLink[] = [];

  // Add property for active status filters
  activeStatusFilters: string[] = ['all']; // Default to showing all

  constructor(
    private apiCallHelper: ApiCallWrapperService,
    private managementApiRouteService: ManagementApiRoutesService,
    private parkingLotsCacheService: ParkingLotsCacheService,
    private globalStateService: GlobalStateService,
    private invoiceDownloadService: InvoiceDownloadService,
    private feeService: FeeService,
    private dialog: MatDialog
  ) {

  }

  ngAfterViewInit(): void {
    this.initDataSource();

    setTimeout(() => {
      this.onMonthSelectionChange(new Date(
        new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear(),
        (new Date().getMonth() + 11) % 12,
        1
      ));
    }, 100);
  }

  /**
   * Toggle status filter when a metric card is clicked
   */
  toggleStatusFilter(filter: string): void {
    // If 'all' is clicked
    if (filter === 'all') {
      this.activeStatusFilters = ['all'];
    }
    // If not 'all' and 'all' is currently active
    else if (this.activeStatusFilters.includes('all')) {
      this.activeStatusFilters = [filter];
    }
    // If filter already active, remove it if it's not the only one
    else if (this.activeStatusFilters.includes(filter)) {
      if (this.activeStatusFilters.length > 1) {
        this.activeStatusFilters = this.activeStatusFilters.filter(f => f !== filter);
      }
    }
    // Add new filter
    else {
      this.activeStatusFilters = [...this.activeStatusFilters, filter];
    }

    // Reapply filters
    this.updateFilterFunction();
    this.applyFilter();
  }

  /**
   * Check if a filter is active
   */
  isStatusFilterActive(filter: string): boolean {
    return this.activeStatusFilters.includes(filter);
  }

  /**
   * Will be called initially too to signal the initial selection
   */
  onMonthSelectionChange(selectedMonth: Date | null | undefined) {
    this.startDate = selectedMonth ?? new Date();
    const startOfMonth = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1);
    this.monthPicker.nativeElement.value = startOfMonth.toLocaleString('default', {month: 'long'}) + " " + this.startDate.getFullYear();

    const startMonth = startOfMonth.getMonth();
    const nextMonthYear = startMonth == 11 ? startOfMonth.getFullYear() + 1 : startOfMonth.getFullYear();
    const endDate = new Date(nextMonthYear, (startMonth + 1) % 12, 1);

    this.reloadInvoices(startOfMonth.getTime() / 1000, endDate.getTime() / 1000);

    this.incompleteMonth = endDate.getTime() > new Date().getTime();
  }

  /**
   * Returns the filename for the zip. If the month is incomplete, the current date will be used instead.
   */
  getFilename(): string {
    const startOfMonth = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1);
    const startMonth = startOfMonth.getMonth();
    const nextMonthYear = startMonth == 11 ? startOfMonth.getFullYear() + 1 : startOfMonth.getFullYear();
    const endDate = new Date(nextMonthYear, (startMonth + 1) % 12, 1);

    const datePipe = new DatePipe('en');

    if (this.incompleteMonth) {
      return "Rechnungen_" + datePipe.transform(startOfMonth, 'yyyy-MM-dd') + "_" + datePipe.transform(new Date(), 'yyyy-MM-dd');
    } else {
      return "Rechnungen_" + datePipe.transform(startOfMonth, 'yyyy-MM-dd');
    }
  }

  private reloadInvoices(startUnixSeconds: number, endUnixSeconds: number) {
    this.isLoadingInvoices = true;

    // Reset to default filter when loading new data
    this.activeStatusFilters = ['all'];

    this.apiCallHelper.call(
      this.managementApiRouteService.getAllInvoicesWithPaymentLinkBetween(startUnixSeconds, endUnixSeconds)
    ).then((invoices: PaymentWebsiteInvoiceWithPaymentLink[] | null) => {
      if (invoices === null) {
        console.log("Failed to load invoices");
        this.allInvoices = [];
      } else {
        this.allInvoices = invoices.sort((a, b) => Number(b.invoice.invoice_id) - Number(a.invoice.invoice_id));
      }
      this.isLoadingInvoices = false;
      this.updateFilterFunction();
      this.applyFilter();
    });
  }

  exportCsv() {
    if (this.isExportingCsv) {
      return;
    }

    this.isExportingCsv = true;

    const datePipe = new DatePipe('en');

    const promise = new Promise((resolve, reject) => {
      const headers = ["Rechnungsnummer", "Rechnungsdatum", "Fälligkeitsdatum", "Zahlungsdatum", "Kennzeichen", "Brutto", "Netto", "MwSt", "Zahlungsart"];
      const rows: string[][] = this.filteredInvoices.map((invoice): string[] => {
        // Calculate VAT values assuming 19% as the standard rate
        const bruttoAmount = this.accumulateInvoiceFee(invoice);
        const nettoAmount = bruttoAmount / 1.19;
        const mwstAmount = bruttoAmount - nettoAmount;

        return [
          invoice.invoice.invoice_id_string,
          datePipe.transform(invoice.invoice.created_at * 1000, 'dd.MM.yyyy HH:mm') ?? "--",
          datePipe.transform(invoice.invoice.due_date * 1000, 'dd.MM.yyyy HH:mm') ?? "--",
          invoice.paymentLink?.payment_date ? datePipe.transform(invoice.paymentLink.payment_date * 1000, 'dd.MM.yyyy HH:mm')! : "--",
          invoice.invoice.license_plate,
          this.feeService.getFormattedFeeValueOnly(bruttoAmount, false),
          this.feeService.getFormattedFeeValueOnly(nettoAmount, false),
          this.feeService.getFormattedFeeValueOnly(mwstAmount, false),
          this.getPaymentMethod(invoice)
        ];
      });

      exportToCsv(this.getFilename() + ".csv", rows, headers);

      resolve(null);
    });

    promise.then(() => {
      this.isExportingCsv = false;
    });
  }

  /**
   * Get payment method for the invoice
   */
  getPaymentMethod(invoice: PaymentWebsiteInvoiceWithPaymentLink): string {
    // Use the payment_method directly from the invoice object
    if (invoice.invoice.payment_method) {
      return invoice.invoice.payment_method;
    }

    // Fallback logic
    if (!invoice.paymentLink) return 'N/A';
    if (invoice.paymentLink.payment_status !== 'APPROVED') return 'Offen';

    return 'Online-Zahlung';
  }

  exportPdf() {
    if (this.isExportingPdf) {
      return;
    }

    this.isExportingPdf = true;

    this.apiCallHelper.call(
      this.managementApiRouteService.downloadMergedInvoicesPdf(
        this.filteredInvoices.map((invoice) => invoice.invoice.invoice_id)
      )
    ).then((zip) => {
      this.invoiceDownloadService.downloadZipFileContent(zip, this.getFilename() + ".zip", "application/zip")
      this.isExportingPdf = false;
    });
  }

  openInvoiceDetailsDialog(invoice: PaymentWebsiteInvoiceWithPaymentLink) {
    this.parkingLotsCacheService.getParkingLots().then((parkingLots) => {
      this.dialog.open(InvoiceDetailsDialogComponent, {
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: {
          invoice: invoice.invoice,
          paymentLink: invoice.paymentLink,
          parkingLots: parkingLots
        },
        autoFocus: false
      });
    });
  }

  onFilterTypeChange(newFilterType: string) {
    this.filterType = newFilterType;
    this.updateFilterFunction();
    this.applyFilter()
  }

  onFilterChange(event: KeyboardEvent) {
    // @ts-ignore
    this.filterSearchString = this.globalStateService.toNormalizedLicensePlateWithoutE(event.target.value.trim());
    this.updateFilterFunction();
    this.applyFilter();
  }

  accumulateInvoiceFee(invoice: PaymentWebsiteInvoiceWithPaymentLink): number {
    return invoice.invoice.amount
  }

  getNettoAmount(invoice: PaymentWebsiteInvoiceWithPaymentLink): number {
    return invoice.invoice.amount / 1.19; // Assuming 19% VAT
  }

  getTotalCollectedFee(invoices: PaymentWebsiteInvoiceWithPaymentLink[]): number {
    return invoices.map((invoice) => this.accumulateCollectedFee(invoice)).reduce((a, b) => a + b, 0);
  }

  accumulateCollectedFee(invoice: PaymentWebsiteInvoiceWithPaymentLink): number {
    let paymentLink = invoice.paymentLink
    if (paymentLink == null) return 0
    return paymentLink.payment_status.toUpperCase() == "APPROVED" ? invoice.invoice.amount : 0
  }

  accumulateMultipleInvoicesFees(invoices: PaymentWebsiteInvoiceWithPaymentLink[]): number {
    return invoices.map((invoice) => this.accumulateInvoiceFee(invoice)).reduce((a, b) => a + b, 0);
  }

  getInvoiceFee(invoice: PaymentWebsiteInvoiceWithPaymentLink): string {
    return this.feeService.getFormattedFee(this.accumulateInvoiceFee(invoice));
  }

  getTotalCollectedFeeString(invoices: PaymentWebsiteInvoiceWithPaymentLink[]): string {
    return this.feeService.getFormattedFee(this.getTotalCollectedFee(invoices));
  }

  getMultipleInvoicesFees(invoices: PaymentWebsiteInvoiceWithPaymentLink[]): string {
    return this.feeService.getFormattedFee(this.accumulateMultipleInvoicesFees(invoices));
  }

  /**
   * Get the count of invoices with approved payment status
   */
  getPaidInvoicesCount(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): number {
    return invoices.filter(invoice =>
      invoice.paymentLink?.payment_status === 'APPROVED'
    ).length;
  }

  /**
   * Get the count of invoices with pending payment status that are not expired
   */
  getPendingInvoicesCount(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): number {
    const now = new Date().getTime() / 1000;
    return invoices.filter(invoice =>
      invoice.paymentLink &&
      ['PENDING', 'INITIATED', 'WAITING'].includes(invoice.paymentLink.payment_status) &&
      invoice.paymentLink.expiration_date > now
    ).length;
  }

  /**
   * Get the count of expired invoices that weren't paid
   */
  getExpiredInvoicesCount(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): number {
    const now = new Date().getTime() / 1000;
    return invoices.filter(invoice =>
      invoice.paymentLink &&
      invoice.paymentLink.payment_status !== 'APPROVED' &&
      invoice.paymentLink.expiration_date <= now
    ).length;
  }

  /**
   * Get total pending fees for invoices that are not yet expired
   */
  getPendingFees(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): number {
    const now = new Date().getTime() / 1000;
    return invoices
      .filter(invoice =>
        invoice.paymentLink &&
        ['PENDING', 'INITIATED', 'WAITING'].includes(invoice.paymentLink.payment_status) &&
        invoice.paymentLink.expiration_date > now
      )
      .reduce((sum, invoice) => sum + invoice.invoice.amount, 0);
  }

  /**
   * Get formatted pending fees
   */
  getPendingFeesFormatted(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): string {
    return this.feeService.getFormattedFee(this.getPendingFees(invoices));
  }

  /**
   * Get total fees for expired unpaid invoices
   */
  getExpiredFees(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): number {
    const now = new Date().getTime() / 1000;
    return invoices
      .filter(invoice =>
        invoice.paymentLink &&
        invoice.paymentLink.payment_status !== 'APPROVED' &&
        invoice.paymentLink.expiration_date <= now
      )
      .reduce((sum, invoice) => sum + invoice.invoice.amount, 0);
  }

  /**
   * Get formatted expired fees
   */
  getExpiredFeesFormatted(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): string {
    return this.feeService.getFormattedFee(this.getExpiredFees(invoices));
  }

  /**
   * Count invoices by payment status for dashboard display
   */
  getInvoiceCountsByStatus(): Record<string, number> {
    const counts: Record<string, number> = {
      'APPROVED': 0,
      'PENDING': 0,
      'EXPIRED': 0,
      'INITIATED': 0,
      'FAILED': 0,
      'DECLINED': 0,
      'WAITING': 0,
      'FRAUD': 0,
      'UNKNOWN': 0,
      'NO_LINK': 0
    };

    this.filteredInvoices.forEach(invoice => {
      if (!invoice.paymentLink) {
        counts['NO_LINK']++;
      } else {
        const status = invoice.paymentLink.payment_status;
        if (Object.keys(counts).includes(status)) {
          counts[status]++;
        } else {
          counts['UNKNOWN']++;
        }
      }
    });

    return counts;
  }

  /**
   * Get total amounts by payment status
   */
  getAmountsByStatus(): Record<string, number> {
    const amounts: Record<string, number> = {
      'APPROVED': 0,
      'PENDING': 0,
      'EXPIRED': 0,
      'INITIATED': 0,
      'FAILED': 0,
      'DECLINED': 0,
      'WAITING': 0,
      'FRAUD': 0,
      'UNKNOWN': 0,
      'NO_LINK': 0
    };

    this.filteredInvoices.forEach(invoice => {
      if (!invoice.paymentLink) {
        amounts['NO_LINK'] += invoice.invoice.amount;
      } else {
        const status = invoice.paymentLink.payment_status;
        if (Object.keys(amounts).includes(status)) {
          amounts[status] += invoice.invoice.amount;
        } else {
          amounts['UNKNOWN'] += invoice.invoice.amount;
        }
      }
    });

    return amounts;
  }

  /**
   * Format amount by status for display
   */
  getFormattedAmountByStatus(status: string): string {
    const amounts = this.getAmountsByStatus();
    return this.feeService.getFormattedFee(amounts[status] || 0);
  }

  /**
   * Get pending invoices that are still active (not expired)
   */
  getActivePendingInvoices(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): PaymentWebsiteInvoiceWithPaymentLink[] {
    const now = new Date().getTime() / 1000;
    return invoices.filter(invoice =>
      invoice.paymentLink &&
      ['PENDING', 'INITIATED', 'WAITING'].includes(invoice.paymentLink.payment_status) &&
      invoice.paymentLink.expiration_date > now
    );
  }

  /**
   * Get total amount of active pending invoices
   */
  getActivePendingAmount(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): number {
    return this.getActivePendingInvoices(invoices).reduce((sum, invoice) =>
      sum + invoice.invoice.amount, 0);
  }

  /**
   * Get formatted amount of active pending invoices
   */
  getFormattedActivePendingAmount(invoices: PaymentWebsiteInvoiceWithPaymentLink[] = this.filteredInvoices): string {
    return this.feeService.getFormattedFee(this.getActivePendingAmount(invoices));
  }

  /**
   * Get problem invoices (failed, declined, fraud)
   */
  getProblemInvoices(): PaymentWebsiteInvoiceWithPaymentLink[] {
    return this.filteredInvoices.filter(invoice =>
      invoice.paymentLink &&
      ['FAILED', 'DECLINED', 'FRAUD'].includes(invoice.paymentLink.payment_status)
    );
  }

  /**
   * Get total amount of problem invoices
   */
  getProblemAmount(): number {
    return this.getProblemInvoices().reduce((sum, invoice) =>
      sum + invoice.invoice.amount, 0);
  }

  /**
   * Get formatted amount of problem invoices
   */
  getFormattedProblemAmount(): string {
    return this.feeService.getFormattedFee(this.getProblemAmount());
  }

  getFilterType(type: string): string {
    switch (type) {
      case "license_plate":
        return "Kennzeichen";
      default:
        return "Rechnungsnummer";
    }
  }

  private applyFilter() {
    this.filteredInvoices = this.allInvoices.filter(this.filterFunction);
    this.dataSource.data = this.filteredInvoices;
  }

  private updateFilterFunction() {
    // Create search filter function
    let searchFilterFunction: (invoice: PaymentWebsiteInvoiceWithPaymentLink) => boolean;

    if (this.filterSearchString.length < 1) {
      searchFilterFunction = (invoice: PaymentWebsiteInvoiceWithPaymentLink) => true;
    } else {
      switch (this.filterType) {
        case "license_plate":
          searchFilterFunction = (invoice: PaymentWebsiteInvoiceWithPaymentLink) =>
            this.globalStateService.toNormalizedLicensePlateWithoutE(
              invoice.invoice.license_plate
            ).startsWith(this.filterSearchString);
          break;
        default:
          searchFilterFunction = (invoice: PaymentWebsiteInvoiceWithPaymentLink) =>
            invoice.invoice.invoice_id.toLowerCase().startsWith(this.filterSearchString);
      }
    }

    // Combine with status filter
    this.filterFunction = (invoice: PaymentWebsiteInvoiceWithPaymentLink) => {
      // First apply the search filter
      if (!searchFilterFunction(invoice)) {
        return false;
      }

      // If showing all, return true
      if (this.activeStatusFilters.includes('all')) {
        return true;
      }

      // Check status filters
      const now = new Date().getTime() / 1000;

      if (this.activeStatusFilters.includes('paid') &&
        invoice.paymentLink?.payment_status === 'APPROVED') {
        return true;
      }

      if (this.activeStatusFilters.includes('pending') &&
        invoice.paymentLink &&
        ['PENDING', 'INITIATED', 'WAITING'].includes(invoice.paymentLink.payment_status) &&
        invoice.paymentLink.expiration_date > now) {
        return true;
      }

      if (this.activeStatusFilters.includes('expired') &&
        invoice.paymentLink &&
        invoice.paymentLink.payment_status !== 'APPROVED' &&
        invoice.paymentLink.expiration_date <= now) {
        return true;
      }

      // If none of the above conditions match, exclude the invoice
      return false;
    };
  }

  private initDataSource() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    //complex object used as data source, so we need a data accessor that defines where to get column values from
    this.dataSource.sortingDataAccessor = (
      invoice: PaymentWebsiteInvoiceWithPaymentLink,
      column: string
    ) => {
      switch (column) {
        case 'invoice_id':
          return invoice.invoice.invoice_id;
        case 'date':
          return invoice.invoice.created_at;
        case 'license_plate':
          return invoice.invoice.license_plate;
        case 'fee':
          return this.accumulateInvoiceFee(invoice);
        default:
          return invoice.invoice.created_at;
      }
    };
  }

  isPaid(invoice: PaymentWebsiteInvoiceWithPaymentLink): boolean {
    return invoice.paymentLink?.payment_status === 'APPROVED';
  }

  getPaymentStatus(invoice: PaymentWebsiteInvoiceWithPaymentLink): string {
    if (!invoice.paymentLink) return 'Kein Zahlungslink';
    const status = invoice.paymentLink?.payment_status;
    const statusMap = {
      'APPROVED': 'Bezahlt',
      'PENDING': 'Offen',
      'EXPIRED': 'Abgelaufen',
      'INITIATED': 'Initiiert',
      'FAILED': 'Fehlgeschlagen',
      'DECLINED': 'Abgelehnt',
      'WAITING': 'Wartend',
      'FRAUD': 'Verdächtig'
    };
    // @ts-ignore
    return statusMap[status] || 'Unbekannt';
  }

  getPaymentStatusClass(invoice: PaymentWebsiteInvoiceWithPaymentLink): string {
    if (!invoice.paymentLink) return 'status-neutral';
    const status = invoice.paymentLink?.payment_status;

    return {
      'APPROVED': 'status-success',
      'PENDING': 'status-pending',
      'EXPIRED': 'status-danger',
      'INITIATED': 'status-info',
      'FAILED': 'status-danger',
      'DECLINED': 'status-danger',
      'WAITING': 'status-pending',
      'FRAUD': 'status-warning'
    }[status] || 'status-neutral';
  }

  getStatusIcon(invoice: PaymentWebsiteInvoiceWithPaymentLink): string {
    const status = invoice.paymentLink?.payment_status;
    if (!status) return 'link_off';
    return {
      'APPROVED': 'check_circle',
      'PENDING': 'schedule',
      'EXPIRED': 'error_outline',
      'INITIATED': 'pending',
      'FAILED': 'cancel',
      'DECLINED': 'block',
      'WAITING': 'hourglass_empty',
      'FRAUD': 'warning'
    }[status] || 'help_outline';
  }

  getStatusIconClass(invoice: PaymentWebsiteInvoiceWithPaymentLink): string {
    const status = invoice.paymentLink?.payment_status;
    if (!status) return 'icon-neutral';
    return {
      'APPROVED': 'icon-success',
      'PENDING': 'icon-pending',
      'EXPIRED': 'icon-danger',
      'INITIATED': 'icon-info',
      'FAILED': 'icon-danger',
      'DECLINED': 'icon-danger',
      'WAITING': 'icon-pending',
      'FRAUD': 'icon-warning'
    }[status] || 'icon-neutral';
  }

  getPaymentExpirationDate(item: PaymentWebsiteInvoiceWithPaymentLink): string {
    if (!item.paymentLink) return 'Kein Zahlungslink';
    return new Date(item.paymentLink.expiration_date * 1000).toLocaleString();
  }

  getExpirationClass(item: PaymentWebsiteInvoiceWithPaymentLink): string {
    if (!item.paymentLink) return 'status-neutral';

    if (item.paymentLink.payment_date != null) return 'status-success';
    const expirationDate = new Date(item.paymentLink.expiration_date * 1000);
    const now = new Date();
    const hoursUntilExpiration = (expirationDate.getTime() - now.getTime()) / (1000 * 60 * 60);

    if (hoursUntilExpiration < 0) return 'status-danger';
    if (hoursUntilExpiration <= 24) return 'status-warning';
    return 'status-neutral';
  }

  reloadCurrentMonthInvoices() {
    const startOfMonth = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1);
    const startMonth = startOfMonth.getMonth();
    const nextMonthYear = startMonth == 11 ? startOfMonth.getFullYear() + 1 : startOfMonth.getFullYear();
    const endDate = new Date(nextMonthYear, (startMonth + 1) % 12, 1);

    this.reloadInvoices(startOfMonth.getTime() / 1000, endDate.getTime() / 1000);
  }
}
