
<div class="desktop-mode-only" style="display: flex; flex-direction: row">
  <h2 class="color-primary days-one font-size-h2" style="display: flex; flex-grow: 1">
    Parkvorgänge ({{globalStateService.licensePlateInput}})
  </h2>
  <div style="max-width: 170px; min-width: 170px; display: flex; flex-direction: row; flex-grow: 1"></div>
</div>

<div class="mobile-mode-only" style="display: flex; flex-direction: column">
  <h2 class="color-primary days-one font-size-h2" style="display: flex; flex-grow: 1; margin-bottom: 0">
    Parkvorgänge ({{globalStateService.licensePlateInput}})
  </h2>
</div>
