<div class="scs-flex-c scs-align-start-center">

  <span>{{ getDayDescription(day) }}</span>

  <div
    *ngFor="let hour of hourArrayDisplay(); let first = first; let last = last"
    class="item-container"
    [ngStyle]="{ 'background-color': hour }"
    [ngClass]="{ last: last, first: first }">
  </div>

</div>
