import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private bearerToken: string | null = null;

  constructor() {

  }

  setToken(token: string | null): void {
    this.bearerToken = token;
  }

  getToken(): string | null {
    return this.bearerToken;
  }

  isAuthenticated(): boolean {
    return this.bearerToken != null;
  }

}
