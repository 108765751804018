import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {ParkingLot} from "../../../../../../models/ParkingLot";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import moment from "moment";
import {TIME_FORMAT} from "../../../../../../models/constants";
import {CameraEvent} from "../../../../../../models/CameraEvent";
import {ParkingOperation} from "../../../../../../models/ParkingOperation";
import {ApiCallWrapperService} from "../../../../../../services/api/api-call-wrapper.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GlobalStateService} from "../../../../../../services/global-state.service";
import {ApiRoutesService} from "../../../../../../services/api/api-routes.service";
import {CustomTariffDescription} from "../../../../../../models/CustomTariffDescription";

@Component({
    selector: 'app-manual-add-parking-operation',
    templateUrl: './manual-add-parking-operation.component.html',
    styleUrls: ['./manual-add-parking-operation.component.css'],
    standalone: false
})
export class ManualAddParkingOperationComponent implements OnInit {

  @Input() parkingOperationsWithBookingsAndFeesSelections!: ParkingOperationWithBookingsAndFeesWithSelection[];
  @Input() parkingLots!: ParkingLot[];
  @Input() parkingLotsWithCustomTariffDescription: CustomTariffDescription[] = []

  @Output() recomputeSelectedFees = new EventEmitter<any>();

  // Parking lots and fake parking lots for the custom tariffs
  allParkingLots: ParkingLot[] = [];

  addParkingOperationSelectedParkingLot: ParkingLot | null = null;
  addParkingOperationSelectedStartDateTime: string = "";
  addParkingOperationSelectedEndDateTime: string = "";

  addParkingOperationParkingLotFormControl = new FormControl('', [Validators.required]);
  addParkingOperationStartTimeFormControl = new FormControl('', [Validators.required, (control: AbstractControl) => {
    if (this.addParkingOperationSelectedEndDateTime == null || this.addParkingOperationSelectedEndDateTime.length == 0) {
      return null;
    }
    if (control.value == null || control.value.length == 0) {
      return null;
    }
    const unixStartSeconds = moment(control.value, TIME_FORMAT).unix();
    const unixEndSeconds = moment(this.addParkingOperationSelectedEndDateTime, TIME_FORMAT).unix();
    return unixStartSeconds < unixEndSeconds ? null : {startAfterEnd: true};
  }]);
  addParkingOperationEndTimeFormControl = new FormControl('', [Validators.required, (control: AbstractControl) => {
    if (this.addParkingOperationSelectedStartDateTime == null || this.addParkingOperationSelectedStartDateTime.length == 0) {
      return null;
    }
    if (control.value == null || control.value.length == 0) {
      return null;
    }
    const unixStartSeconds = moment(this.addParkingOperationSelectedStartDateTime, TIME_FORMAT).unix();
    const unixEndSeconds = moment(control.value, TIME_FORMAT).unix();
    return unixStartSeconds < unixEndSeconds ? null : {endBeforeStart: true};
  }]);

  constructor(
    private apiCallHelper: ApiCallWrapperService,
    private snackBar: MatSnackBar,
    private apiRoutesService: ApiRoutesService,
    public globalStateService: GlobalStateService,
  ) {

  }

  ngOnInit(): void {
    this.allParkingLots = this.parkingLots.concat(this.parkingLotsWithCustomTariffDescription.map(customTariff => {
      return {
        name: customTariff.name,
        id: customTariff.parking_lot_id,
        latitude: 0,
        longitude: 0
      }
    }));
  }



  onAddParkingOperationClick() {
    if (this.addParkingOperationSelectedParkingLot == null || this.addParkingOperationSelectedStartDateTime.length == 0 || this.addParkingOperationSelectedEndDateTime.length == 0) {
      this.snackBar.open("Bitte füllen Sie alle Felder aus.", "OK", {duration: 3000})
      return;
    }

    const parkingLotId = this.addParkingOperationSelectedParkingLot?.id;
    const startTimeUnixSeconds = moment(this.addParkingOperationSelectedStartDateTime, TIME_FORMAT).unix();
    const endTimeUnixSeconds = moment(this.addParkingOperationSelectedEndDateTime, TIME_FORMAT).unix();

    if (parkingLotId == null) {
      this.snackBar.open("Bitte wählen Sie einen Parkplatz aus.", "OK", {duration: 3000})
      return;
    }

    if (startTimeUnixSeconds >= endTimeUnixSeconds) {
      this.snackBar.open("Die Startzeit muss vor der Endzeit liegen.", "OK", {duration: 3000})
      return;
    }

    const startEvent: CameraEvent = {
      parking_lot_id: parkingLotId,
      driving_direction: "in",
      time: startTimeUnixSeconds
    }
    const endEvent: CameraEvent = {
      parking_lot_id: parkingLotId,
      driving_direction: "out",
      time: endTimeUnixSeconds
    }
    let artificialParkingOperation: ParkingOperation = {
      start_event: startEvent,
      start_event_editable: true,
      payment_start_time: startEvent.time,
      end_event: endEvent,
      end_event_editable: true,
      license_plate: this.globalStateService.licensePlateInput,
      normalized_license_plate: this.globalStateService.normalizedLicensePlate,
      longer_than_allowed: false
    }

    this.apiCallHelper.call(this.apiRoutesService.getParkingFee(artificialParkingOperation)).then(parkingOperationWithFee => {
      if (parkingOperationWithFee == null) {
        this.snackBar.open("Leider ist etwas schief gelaufen, bitte versuchen Sie es erneut.", "OK", {duration: 3000})
        return;
      }
      const parkingOperation = new ParkingOperationWithBookingsAndFeesWithSelection(
        parkingOperationWithFee,
        !parkingOperationWithFee.parking_operation.longer_than_allowed,
        this.addParkingOperationSelectedStartDateTime,
        this.addParkingOperationSelectedEndDateTime
      );
      this.parkingOperationsWithBookingsAndFeesSelections.push(parkingOperation);
      this.addParkingOperationSelectedParkingLot = null;
      this.addParkingOperationSelectedStartDateTime = "";
      this.addParkingOperationSelectedEndDateTime = "";
      this.addParkingOperationParkingLotFormControl.reset();
      this.addParkingOperationStartTimeFormControl.reset();
      this.addParkingOperationEndTimeFormControl.reset();

      this.recomputeSelectedFees.emit();
    });
  }


}
