<div>
  <app-pay-parking-operations-header></app-pay-parking-operations-header>
  <button
    mat-button
    color="primary"
    style="width: fit-content; min-width: 50px;box-shadow: none !important;"
    class="days-one"
    (click)="clearStateAndGoToLanding()">
    Kennzeichen ändern
  </button>
  <div class="scs-flex-c scs-gap-s">
   <!-- just some space-->
    <div style="height:16px;">
        </div>
    <app-parking-operation-list [parkingLots]="parkingLots"
                                [parkingOperationsWithBookingsAndFeesSelections]="parkingOperationsWithBookingsAndFeesSelections"
                                [recomputeSelectedFeesTrigger]="recomputeSelectedFeesTriggerSubject.asObservable()"
                                (selectedParkingOperationsChange)="selectedParkingOperations.next($event)"
                                (totalSelectionFeeChange)="totalSelectionFee.next($event)">
    </app-parking-operation-list>

    <h3 class="days-one" style="margin-top: 10px">Fehlt ein Parkvorgang?</h3>

    <button *ngIf="hideAddAdditionalParkingOperationsView"
            mat-button color="primary"
            style="width: fit-content; min-width: 50px; margin: 0 10px 20px;"
            class="days-one"
            (click)="hideAddAdditionalParkingOperationsView = false">
      Hinzufügen
    </button>

    <app-manual-add-parking-operation *ngIf="!hideAddAdditionalParkingOperationsView"
                                      [parkingLots]="parkingLots"
                                      [parkingLotsWithCustomTariffDescription]="parkingLotsWithCustomTariffDescription"
                                      [parkingOperationsWithBookingsAndFeesSelections]="parkingOperationsWithBookingsAndFeesSelections"
                                      (recomputeSelectedFees)="recomputeSelectedFeesTriggerSubject.emit()">
    </app-manual-add-parking-operation>

  </div>

  <app-parking-operation-summary [parkingLots]="parkingLots"
                                 [selectedParkingOperations]="selectedParkingOperations"
                                 [parkingLotsWithCustomTariffDescription]="parkingLotsWithCustomTariffDescription"
                                 [totalSelectionFee]="totalSelectionFee"
                                 (reloadParkingOperationsTrigger)="reloadParkingOperationsTrigger.emit()">
  </app-parking-operation-summary>

</div>
