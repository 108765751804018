import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ApiCallWrapperService} from "../../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../../services/api/api-routes.service";
import {GlobalStateService} from "../../../../services/global-state.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PaymentOptions} from "./PaymentOptions";
import {CompleteParkingOperation, ParkingOperationWithBookingsAndFees} from "../../../../models/ParkingOperation";
import {groupBy} from "../../../../services/mapHelper";
import {ParkingLot} from "../../../../models/ParkingLot";
import {FormControl, Validators} from "@angular/forms";
import {SepaPaymentHintDialogComponent} from "../sepa-payment-hint-dialog/sepa-payment-hint-dialog.component";
import {FeeService} from "../../../../services/fee.service";
import {TelecashPaymentServiceService} from "../../../../services/payment/telecash-payment-service.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
  PaymentLinkPaymentHintDialogComponent
} from "../payment-link-payment-hint-dialog/payment-link-payment-hint-dialog.component";
import {ParkingOperationsPageTabIndexService} from "../../../../services/parking-operations-page-tab-index.service";


export interface CreateInvoiceConfirmationDialogComponentData {
  selectedParkingOperations: ParkingOperationWithBookingsAndFeesWithSelection[];
  parkingLots: ParkingLot[];
  reloadParkingOperations: () => void;
}

@Component({
    selector: 'app-create-invoice-confirmation-dialog',
    templateUrl: './create-invoice-confirmation-dialog.component.html',
    styleUrls: ['./create-invoice-confirmation-dialog.component.css'],
    standalone: false
})
export class CreateInvoiceConfirmationDialogComponent {

  selectedPaymentOption: PaymentOptions | null = PaymentOptions.CREDIT_CARDS_AND_WALLET;

  showPaymentLinkOption = false;


  /**
   * Map of parking lot id to list of parking operations on that id sorted ascending by start time.
   * All camera events are non-optional in the list of parking operations.
   */
  parkingLotToParkingOperationsMap: Map<number, ParkingOperationWithBookingsAndFees[]>;

  invoiceCreationInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateInvoiceConfirmationDialogComponentData,
    private router: Router,
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    public globalStateService: GlobalStateService,
    private feeService: FeeService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private telecashPaymentService: TelecashPaymentServiceService,
    public dialogRef: MatDialogRef<CreateInvoiceConfirmationDialogComponent>,
    public parkingOperationsPageTabIndexService: ParkingOperationsPageTabIndexService,
    private http: HttpClient
  ) {

    // show payment link option if local storage  value "admin" is set to true

    if (localStorage.getItem('admin') == 'true') {
      this.showPaymentLinkOption = true;
    }

    const parkingOperations = data.selectedParkingOperations.filter(parkingOperation =>
      parkingOperation.parking_operation.parking_operation.start_event != null &&
      parkingOperation.parking_operation.parking_operation.end_event != null)
      .map((parkingOperation) => parkingOperation.parking_operation)
      .sort((a, b) =>
        a.parking_operation.start_event!.time - b.parking_operation.start_event!.time
      );

    this.parkingLotToParkingOperationsMap = groupBy(
      parkingOperations,
      parkingOperation => parkingOperation.parking_operation.start_event!.parking_lot_id
    );
  }


  onConfirm() {
    const mergedParkingOperations = [...this.parkingLotToParkingOperationsMap.values()].flat()
    let email: string | undefined = undefined
    const simpleParkingOperations: CompleteParkingOperation[] = mergedParkingOperations.map((parkingOperation) => {
      return {
        start_event: parkingOperation.parking_operation.start_event!,
        end_event: parkingOperation.parking_operation.end_event!,
        longer_than_allowed: parkingOperation.parking_operation.longer_than_allowed
      }
    });

    this.invoiceCreationInProgress = true;

    if (this.selectedPaymentOption == PaymentOptions.SEPA) {
      this.onPayWithSepa(email, this.globalStateService.normalizedLicensePlate, this.globalStateService.licensePlateInput, simpleParkingOperations);
    } else {
      this.onPayWithPaymentLink(email, this.globalStateService.normalizedLicensePlate, this.globalStateService.licensePlateInput, simpleParkingOperations);
    }
  }

  onPayWithSepa(email: string | undefined, normalizedLicensePlate: string, licensePlate: string, parkingOperations: CompleteParkingOperation[]) {
    this.apiCallHelper.call(this.apiRoutesService.createInvoice(
      normalizedLicensePlate,
      licensePlate,
      email,
      parkingOperations
    )).then((response) => {
      if (response == null) {
        this.snackBar.open("Die Rechnung konnte leider nicht erstellt werden, bitte versuchen Sie es später erneut", "", {duration: 3000});
        this.invoiceCreationInProgress = false;
        return;
      }

      this.data.reloadParkingOperations();  //reloads the parking operations which will now contain the associated invoices

      const totalFee = this.getTotalFee(this.parkingLotToParkingOperationsMap);

      if (email != null && response.sent_email) {
        this.openSepaPaymentHintDialog(totalFee, response.invoice_id, response.invoice_id_string, true);
      } else {
        this.openSepaPaymentHintDialog(totalFee, response.invoice_id, response.invoice_id_string, false);
      }
      this.dialogRef.close();
    });
  }

  getParkingLotName(parkingLotId: number): string {
    return this.data.parkingLots.filter((parkingLot) => parkingLot.id == parkingLotId)[0].name ?? "??";
  }

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

  getFormattedTotalFee(parkingOperationsWithFees: Map<number, ParkingOperationWithBookingsAndFees[]>): string {
    const totalFee = this.getTotalFee(parkingOperationsWithFees);
    return this.getFormattedFee(totalFee);
  }

  getTotalFee(parkingOperationsWithFees: Map<number, ParkingOperationWithBookingsAndFees[]>): number {
    const mergedParkingOperations = [...parkingOperationsWithFees.values()].flat();
    return mergedParkingOperations.map((parkingOperation) =>
      parkingOperation.fee
    ).reduce((a, b) => a + b, 0);
  }

  private openSepaPaymentHintDialog(totalFee: number, invoiceId: string, invoiceIdString: string, emailSentSuccessfully: boolean,
  ) {
    this.dialog.open(SepaPaymentHintDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '80vh',
      data: {
        totalFee: totalFee,
        invoiceId: invoiceId,
        invoiceIdString: invoiceIdString,
        emailSentSuccessfully: emailSentSuccessfully,
      },
      autoFocus: false
    });
  }

  private openPaymentLinkPaymentHintDialogComponent(totalFee: number, invoiceId: string, invoiceIdString: string, emailSentSuccessfully: boolean,
                                                    paymentLinkUrl: string, paymentLinkExpirationDate: number,
                                                    externalPaymentLinkId: string, licensePlate: string) {
    let dialogRef = this.dialog.open(PaymentLinkPaymentHintDialogComponent, {
      maxWidth: '80vw',
      maxHeight: '90vh',
      data: {
        totalFee: totalFee,
        invoiceId: invoiceId,
        invoiceIdString: invoiceIdString,
        emailSentSuccessfully: emailSentSuccessfully,
        paymentLinkUrl: paymentLinkUrl,
        paymentLinkExpirationDate: paymentLinkExpirationDate,
        externalPaymentLinkId: externalPaymentLinkId,
        licensePlate: licensePlate
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(async action => {
      console.log("reloading parking operations");
      await this.data.reloadParkingOperations();
      this.parkingOperationsPageTabIndexService.switchToInvoiceTab()
    })
  }

  protected readonly PaymentOptions = PaymentOptions;


  createInvoice() {
    this.invoiceCreationInProgress = true;
    // Implement invoice creation logic here
  }

  getDateTime(): string {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}:${month}:${day}-${hours}:${minutes}:${seconds}`;
  }


  getTotalAmount(): string {
    // Implement logic to calculate the total amount
    // This should return a string with two decimal places, e.g., "13.00"
    return this.getFormattedTotalFee(this.parkingLotToParkingOperationsMap);
  }


  onPayWithPaymentLink(email: string | undefined, normalizedLicensePlate: string, licensePlate: string, parkingOperations: CompleteParkingOperation[]) {
    let result = this.apiCallHelper.call(this.telecashPaymentService.createInvoiceAndPaymentLink(
      normalizedLicensePlate,
      licensePlate,
      email,
      parkingOperations)
    ).then((response) => {

      if (response == null) {
        this.snackBar.open("Die Rechnung konnte leider nicht erstellt werden, bitte versuchen Sie es später erneut", "", {duration: 3000});
        this.invoiceCreationInProgress = false;
        return;
      }
      this.data.reloadParkingOperations();  //reloads the parking operations which will now contain the associated invoices

      const totalFee = this.getTotalFee(this.parkingLotToParkingOperationsMap);

      if (email != null && response.sent_email) {
        this.openPaymentLinkPaymentHintDialogComponent(totalFee, response.invoice_id, response.invoice_id_string, true,
          response.payment_link, response.expiration_date,
          response.external_payment_link_id, licensePlate);
      } else {
        this.openPaymentLinkPaymentHintDialogComponent(totalFee, response.invoice_id, response.invoice_id_string, false, response.payment_link, response.expiration_date,
          response.external_payment_link_id, licensePlate);
      }
      this.dialogRef.close();
    })
  }
}
