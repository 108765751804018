import {Component, Input, OnInit} from '@angular/core';
import {Invoice, InvoiceParkingOperation} from "../../../../models/Invoice";
import {ParkingLot} from "../../../../models/ParkingLot";
import {MatDialog} from "@angular/material/dialog";
import {
  ParkingOperationDetailsDialogComponent
} from "./parking-operation-details-dialog/parking-operation-details-dialog.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-parking-operation-with-hover',
  templateUrl: './parking-operation-with-hover.component.html',
  styleUrls: ['./parking-operation-with-hover.component.css'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class ParkingOperationWithHoverComponent implements OnInit {

  @Input() invoice!: Invoice;
  @Input() parkingLots!: ParkingLot[];


  sortedParkingOperations: InvoiceParkingOperation[] = [];

  constructor(
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.sortedParkingOperations = this.invoice.parking_operations.sort((parkingOperation1, parkingOperation2) => parkingOperation1.start_time - parkingOperation2.start_time);
  }

  openParkingOperationDetailsDialog() {
    this.dialog.open(ParkingOperationDetailsDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: {
        invoice: this.invoice,
        parkingLots: this.parkingLots
      },
      autoFocus: false
    });
  }

}
