import {Component, OnDestroy, OnInit, NgZone, Input} from '@angular/core';
import {Router} from "@angular/router";
import {ScrollService} from "../../../../services/scroll.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-app-scroll-indicator',
  imports: [
    NgIf
  ],
  templateUrl: './app-scroll-indicator.component.html',
  styleUrl: './app-scroll-indicator.component.css'
})
export class AppScrollIndicatorComponent implements OnInit, OnDestroy {
  isButtonVisible = false;
  shouldShowScroll = false;
  private observer: IntersectionObserver | null = null;
  private feeSubscription: Subscription | null = null;
  @Input() totalSelectionFee!: BehaviorSubject<number>;

  constructor(private ngZone: NgZone,
              private router: Router,
              private scrollService: ScrollService) {
  }

  ngOnInit() {
    // First check the initial fee value
    const initialFee = this.totalSelectionFee.getValue();
    if (initialFee <= 0) {
      this.shouldShowScroll = false;
    } else {
      this.setupIntersectionObserver();
    }

    // Subscribe to fee changes
    this.feeSubscription = this.totalSelectionFee.subscribe(fee => {
      this.ngZone.run(() => {
        if (fee <= 0) {
          this.shouldShowScroll = false;
          if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
          }
        } else if (!this.observer) {
          this.setupIntersectionObserver();
        }
        this.updateScrollVisibility(fee);
      });
    });
  }

  private updateScrollVisibility(fee: number) {
    this.shouldShowScroll = fee > 0 && !this.isButtonVisible;
  }

  private setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.ngZone.run(() => {
          this.isButtonVisible = entry.isIntersecting;
          this.updateScrollVisibility(this.totalSelectionFee.getValue());
        });
      });
    }, options);

    const payButton = document.querySelector('.pay-now-button');
    if (payButton) {
      this.observer.observe(payButton);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.feeSubscription) {
      this.feeSubscription.unsubscribe();
    }
  }

  scrollToPayButton() {
    this.scrollService.scrollToPayButton();
  }
}
