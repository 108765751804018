import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {Invoice, InvoiceParkingOperation} from "../../../../../models/Invoice";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ApiCallWrapperService} from "../../../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../../../services/api/api-routes.service";
import {GlobalStateService} from "../../../../../services/global-state.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {ParkingLot} from "../../../../../models/ParkingLot";
import {FeeService} from "../../../../../services/fee.service";
import {InvoiceDownloadService} from "../../../../../services/invoice-download-service";
import {SendInvoiceToEmailDialogComponent} from "./send-invoice-to-email-dialog/send-invoice-to-email-dialog.component";


export interface InvoiceDetailsDialogComponentData {
  invoice: Invoice;
  parkingLots: ParkingLot[];
}

@Component({
    selector: 'app-invoice-details-dialog',
    templateUrl: './invoice-details-dialog.component.html',
    styleUrls: ['./invoice-details-dialog.component.css'],
    standalone: false
})
export class InvoiceDetailsDialogComponent implements AfterViewInit {

  @ViewChild(MatSort) sort!: MatSort;

  isDownloadingPdf: boolean = false;

  displayedColumns: string[] = ["parking_lot_name", "start", "end", "duration", "fee"];
  dataSource: MatTableDataSource<InvoiceParkingOperation> = new MatTableDataSource<InvoiceParkingOperation>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InvoiceDetailsDialogComponentData,
    private router: Router,
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    public globalStateService: GlobalStateService,
    private feeService: FeeService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private invoiceDownloadService: InvoiceDownloadService,
    public dialogRef: MatDialogRef<InvoiceDetailsDialogComponent>
  ) {
    this.dataSource.data = data.invoice.parking_operations;
  }

  ngAfterViewInit() {
    this.initDataSource();
  }

  downloadInvoicePdf() {
    if (this.isDownloadingPdf) {
      return;
    }

    this.isDownloadingPdf = true;
    this.invoiceDownloadService.downloadInvoice(
      this.data.invoice.invoice_id, this.data.invoice.invoice_id_string, this.globalStateService.toNormalizedLicensePlate(this.data.invoice.license_plate)
      , this.data.invoice.license_plate// TODO please add a better way
    ).then(() => {
      this.isDownloadingPdf = false;
    });
  }

  openSendEmailDialog() {
    this.dialog.open(SendInvoiceToEmailDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        invoice: this.data.invoice
      },
      autoFocus: false
    });
  }

  getParkingLotName(parkingLotId: number): string {
    const parkingLot = this.data.parkingLots.find(parkingLot => parkingLot.id == parkingLotId);
    return parkingLot?.name ?? "-";
  }

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

  getParkingDuration(invoiceParkingOperation: InvoiceParkingOperation): string {
    const durationSeconds = invoiceParkingOperation.end_time - invoiceParkingOperation.start_time;
    const durationMinutes = Math.ceil(durationSeconds / 60);

    const days = Math.floor(durationMinutes / 60 / 24);
    const hours = Math.floor(durationMinutes / 60) % 24;
    const minutes = durationMinutes % 60;

    return (days > 0 ? days + "T " : "") + (hours > 0 ? hours + "h " : "") + minutes + "min";
  }

  private initDataSource() {
    this.dataSource.sort = this.sort;

    //complex object used as data source, so we need a data accessor that defines where to get column values from
    this.dataSource.sortingDataAccessor = (
      invoiceParkingOperation: InvoiceParkingOperation,
      column: string
    ) => {
      switch (column) {
        case 'parking_lot_name':
          return this.getParkingLotName(invoiceParkingOperation.parking_lot_id);
        case 'start':
          return invoiceParkingOperation.start_time;
        case 'end':
          return invoiceParkingOperation.end_time;
        case 'duration':
          return invoiceParkingOperation.end_time - invoiceParkingOperation.start_time;
        case 'fee':
          return invoiceParkingOperation.fee;
        default:
          return this.getParkingLotName(invoiceParkingOperation.parking_lot_id);
      }
    };
  }

}
