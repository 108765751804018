import {Injectable} from '@angular/core';

interface LicensePlateData {
  licensePlate: string;
  lastUpdated: string;  // ISO string format
  normalizedLicensePlate: string
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }


// Store license plate with timestamp
  setLicensePlateData(licensePlate: string, normalizedLicensePlate: string): void {
    try {
      const data: LicensePlateData = {
        licensePlate: licensePlate,
        normalizedLicensePlate: normalizedLicensePlate,
        lastUpdated: new Date().toISOString()
      };
      localStorage.setItem('licensePlateData', JSON.stringify(data));
    } catch (error) {
      console.error('Error saving license plate data to localStorage:', error);
    }
  }

// Get license plate if it's less than 10 minutes old
  getLicensePlateData(): LicensePlateData | null {
    try {
      const storedData = localStorage.getItem('licensePlateData');
      if (!storedData) return null;

      const data: LicensePlateData = JSON.parse(storedData);
      const lastUpdated = new Date(data.lastUpdated);
      const now = new Date();
      const diffInMinutes = (now.getTime() - lastUpdated.getTime()) / (1000 * 60);

      if (diffInMinutes <= 10) {
        return data
      }

      // Data is too old, remove it
      this.removeLicensePlateData();
      return null;
    } catch (error) {
      console.error('Error reading license plate data from localStorage:', error);
      return null;
    }
  }

// Remove license plate data
  removeLicensePlateData(): void {
    try {
      localStorage.removeItem('licensePlateData');
    } catch (error) {
      console.error('Error removing license plate data from localStorage:', error);
    }
  }

// Get last update time of license plate
  getLicensePlateLastUpdate(): Date | null {
    try {
      const storedData = localStorage.getItem('licensePlateData');
      if (!storedData) return null;

      const data: LicensePlateData = JSON.parse(storedData);
      return new Date(data.lastUpdated);
    } catch (error) {
      console.error('Error reading license plate update time from localStorage:', error);
      return null;
    }
  }
}
