import {TariffStructure, TariffTimes} from "../../../../../models/TariffStructure";
import {PRESET_COLOR_SCHEME} from "./tariff-setting-color-bar/color-bar.constant";
import {groupBy, mapValues} from "../../../../../services/mapHelper";
import {WEEKDAY_DISPLAY} from "./tariff-setting-color-bar/weekday.constant";
import {FeeService} from "../../../../../services/fee.service";

/**
 * From the given tariff structure computes the map of day of the week to color bar (24 colors per day of the week).
 * Also returns the sorted groups of tariff times that are grouped by their fee/flat_fee/consecutive_fees.
 */
export function computeColoredHoursMap(tariffStructure: TariffStructure | null | undefined): [Map<number, string[]>, TariffTimes[][]] {

  if (tariffStructure == null) {
    return [getDefaultColorBarMap(), []];
  }

  const indexToTariffTimeGroupMap = groupTariffTimesByFee(tariffStructure.tariff_times);
  //TODO we need to assume that there will actually never be more groups than there are colors available

  const colorMap = getDefaultColorBarMap(); //start with the default then color it accordingly
  indexToTariffTimeGroupMap.forEach((tariffTimeGroup, index) => {

    const color = PRESET_COLOR_SCHEME[(index + 1) % PRESET_COLOR_SCHEME.length];

    tariffTimeGroup.forEach(tariffTime => {
      const coveredDaysAndHours = getCoveredDaysAndHours(tariffTime);
      coveredDaysAndHours.forEach((hours, day) => {
        hours.forEach(hour => {
          colorMap.get(day)![hour] = color;
        });
      });
    });
  });

  const sortedTariffTimeGroups = [...indexToTariffTimeGroupMap.entries()].sort((a, b) => {
    return a[0] - b[0];
  }).map(entry => entry[1]);

  return [colorMap, sortedTariffTimeGroups];

}

/**
 * Returns a map of increasing tariff time group index to all tariff times in that group.
 * Tariff times are grouped by their fee/flat_fee/consecutive_fees, all tariff times use the same fee settings.
 */
export function groupTariffTimesByFee(tariffTimes: TariffTimes[]): Map<number, TariffTimes[]> {

  const groups = groupBy(tariffTimes, tariffTime => {
    return JSON.stringify({
      fee: tariffTime.fee,
      flat_fee: tariffTime.flat_fee,
      consecutive_fees: tariffTime.consecutive_fees
    });
  });

  //Sort the tariff times in the groups ascending by their start day/time, so we can number them in ascending order
  const sortedTariffTimes = [...mapValues(groups, (key, values) => {
    return values.sort((a, b) => {
      const dayDiff = a.day_of_week_start - b.day_of_week_start;
      if (dayDiff != 0) {
        return dayDiff;
      }

      return a.start_time - b.start_time;
    });
  }).values()];

  const sortedGroups = sortedTariffTimes.sort((a, b) => {
    const dayDiff = a[0].day_of_week_start - b[0].day_of_week_start;
    if (dayDiff != 0) {
      return dayDiff;
    }

    return a[0].start_time - b[0].start_time;
  });

  const indexToTariffTimeGroupMap = new Map<number, TariffTimes[]>()
  sortedGroups.forEach((group, index) => {
    indexToTariffTimeGroupMap.set(index, group);
  });

  return indexToTariffTimeGroupMap;
}

/**
 * Computes a map of day of the week to the list of hours the tariffTime covers (at least partially).
 */
function getCoveredDaysAndHours(tariffTime: TariffTimes): Map<number, number[]> {
  if (tariffTime.day_of_week_start < 0 || tariffTime.day_of_week_start > 6 ||
    tariffTime.day_of_week_end < 0 || tariffTime.day_of_week_end > 6) {
    console.log("Invalid day of week in tariff time: " + tariffTime)
    return new Map<number, number[]>();
  }

  const daysToCheck = new Set<number>();
  daysToCheck.add(tariffTime.day_of_week_start);
  daysToCheck.add(tariffTime.day_of_week_end);
  if (tariffTime.day_of_week_start < tariffTime.day_of_week_end) {
    for (let i = tariffTime.day_of_week_start + 1; i < tariffTime.day_of_week_end; i++) {
      daysToCheck.add(i);
    }
  } else if (tariffTime.day_of_week_start > tariffTime.day_of_week_end) {
    for (let i = (tariffTime.day_of_week_start + 1 % 7); i > tariffTime.day_of_week_end; i = (i + 1 % 7)) {
      daysToCheck.add(i);
    }
  }

  const daysToHoursMap = new Map<number, number[]>();

  daysToCheck.forEach(day => {
    const hoursCoveredThisDay: number[] = [];
    for (let i = 0; i < 24; i++) {
      if (isHourInTariffTime(day, i, tariffTime)) {
        hoursCoveredThisDay.push(i);
      }
    }

    if (hoursCoveredThisDay.length > 0) {
      daysToHoursMap.set(day, hoursCoveredThisDay);
    }
  });

  return daysToHoursMap;
}

function isHourInTariffTime(dayOfWeek: number, hour: number, tariffTime: TariffTimes): boolean {
  const startHour = tariffTime.start_time / 60.0;
  const endHour = tariffTime.end_time / 60.0;

  if (tariffTime.day_of_week_start == tariffTime.day_of_week_end) {
    return dayOfWeek == tariffTime.day_of_week_start && hour >= startHour && hour <= endHour;
  }

  if (tariffTime.day_of_week_start < tariffTime.day_of_week_end) {
    return (dayOfWeek == tariffTime.day_of_week_start && hour >= startHour)
      || (dayOfWeek == tariffTime.day_of_week_end && hour <= endHour)
      || (dayOfWeek > tariffTime.day_of_week_start && dayOfWeek < tariffTime.day_of_week_end);
  }

  //if(tariffTime.day_of_week_start > tariffTime.day_of_week_end) {
  return (dayOfWeek == tariffTime.day_of_week_start && hour >= startHour)
    || (dayOfWeek == tariffTime.day_of_week_end && hour <= endHour)
    || (dayOfWeek > tariffTime.day_of_week_start || dayOfWeek < tariffTime.day_of_week_end);
  //}
}


/**
 * Generates the default map of weekdays to color bars.
 */
function getDefaultColorBarMap(): Map<number, string[]> {
  const defaultColorArray = new Array(24).fill(PRESET_COLOR_SCHEME[0]);
  return new Map([
    [0, [...defaultColorArray]],
    [1, [...defaultColorArray]],
    [2, [...defaultColorArray]],
    [3, [...defaultColorArray]],
    [4, [...defaultColorArray]],
    [5, [...defaultColorArray]],
    [6, [...defaultColorArray]]
  ]);
}

/**
 * The tariff times need to be sorted ascending by their start day and time.
 * Only tariffs with the same fee are in the same tariff time group.
 * This function returns the time description text for the tariff time group.
 */
export function getTariffTimeGroupTimeHtmlDescription(tariffTimes: TariffTimes[]): string {
  if (tariffTimes.length == 0) {
    return "??"
  }

  return tariffTimes.map(tariffTime => getTariffTimeTimespanDescription(tariffTime)).join(",<br>");
}

/**
 * The tariff times need to be sorted ascending by their start day and time.
 * Only tariffs with the same fee are in the same tariff time group.
 * This function returns the fee description text for the tariff time group.
 */
export function getTariffTimeGroupFeeHtmlDescription(tariffTimes: TariffTimes[], billingIntervalMinutesOfTariffStructure: number, feeService: FeeService): string {
  if (tariffTimes.length == 0) {
    return "??"
  }
  const fee = tariffTimes[0].fee;
  const flatFee = tariffTimes[0].flat_fee;
  const consecutiveFees = tariffTimes[0].consecutive_fees;
  const consecutiveIntervals = tariffTimes[0].consecutive_billing_interval_minutes;
  const freeParkingDurationMinutes = tariffTimes[0].free_parking_duration_minutes;
  let billingIntervalMinutes = tariffTimes[0].billing_interval_minutes;
  if (billingIntervalMinutes == null) {
    billingIntervalMinutes = billingIntervalMinutesOfTariffStructure;
  }
  if (flatFee != null) {
    if (freeParkingDurationMinutes != null && freeParkingDurationMinutes > 0) {
      return "Pauschal: " + feeService.getFormattedFee(flatFee) + " (nach " + freeParkingDurationMinutes + " Minuten frei)";
    } else {
      return "Pauschal: " + feeService.getFormattedFee(flatFee)
    }
  } else if (consecutiveFees.length > 0) {
    if (consecutiveFees.length == 1) {
      if (freeParkingDurationMinutes != null && freeParkingDurationMinutes > 0) {
        return feeService.getFormattedFee(consecutiveFees[0]) + " pro " + billingIntervalMinutes + " Minuten (nach " + freeParkingDurationMinutes + " Minuten frei)";
      }
      return feeService.getFormattedFee(consecutiveFees[0]) + " pro " + billingIntervalMinutes + " Minuten";
    }

    const consecutiveFeeDescriptions = consecutiveFees.map((fee, index) => {
      let consecutiveInterval = billingIntervalMinutes
      if (consecutiveIntervals != null && consecutiveIntervals.length > 0) {
        if (index < consecutiveIntervals.length) {
          consecutiveInterval = consecutiveIntervals[index]!!
        } else {
          consecutiveInterval = consecutiveIntervals[consecutiveIntervals.length - 1]!!
        }
      }
      if (index == 0) {
        return feeService.getFormattedFee(fee) + " in den ersten " + consecutiveInterval + " Minuten";
      } else if (index != consecutiveFees.length - 1) {
        return feeService.getFormattedFee(fee) + " in den folgenden " + consecutiveInterval + " Minuten";
      } else {
        return feeService.getFormattedFee(fee) + " pro angefangene " + consecutiveInterval + " Minuten (ab " +
          (index * consecutiveInterval!!) + " Minuten)";
      }
    }).join("<br>");

    if (freeParkingDurationMinutes != null && freeParkingDurationMinutes > 0) {
      return "Gestaffelt:<br>" + consecutiveFeeDescriptions + "<br>(nach " + freeParkingDurationMinutes + " Minuten frei)";
    }
    return "Gestaffelt:<br>" + consecutiveFeeDescriptions;
  }

  if (freeParkingDurationMinutes != null && freeParkingDurationMinutes > 0) {
    return feeService.getFormattedFee(fee) + " pro " + billingIntervalMinutes + " Minuten (nach " + freeParkingDurationMinutes + " Minuten frei)";
  }
  return feeService.getFormattedFee(fee) + " pro angefangene " + billingIntervalMinutes + " Minuten";
}

export function getTariffTimeTimespanDescription(tariffTime: TariffTimes): string {
  if (tariffTime.day_of_week_start == tariffTime.day_of_week_end) {
    return WEEKDAY_DISPLAY[tariffTime.day_of_week_start] + " zwischen " + getTimeDescription(tariffTime.start_time) + " und " + getTimeDescription(tariffTime.end_time);
  }
  return WEEKDAY_DISPLAY[tariffTime.day_of_week_start] + " " + getTimeDescription(tariffTime.start_time) + " bis " + WEEKDAY_DISPLAY[tariffTime.day_of_week_end] + " " + getTimeDescription(tariffTime.end_time);
}

function getTimeDescription(timeMinutes: number): string {
  const hour = Math.floor(timeMinutes / 60);
  const minute = timeMinutes % 60;
  return (hour.toString()).padStart(2, "0") + ":" + (minute.toString()).padStart(2, "0") + " Uhr";
}
