
<div *ngIf="parkingOperationsWithBookingsAndFeesSelections.length > 0">

  <!--this version of the parking operation list will be displayed if the screen is in landscape mode-->
  <div class="landscape-mode-only" style="flex-direction: column">

    <mat-card style="width: fit-content; height: fit-content; min-width: 80vw">

      <div style="display: flex; flex-direction: row">
        <span class="color-primary days-one" style="width: 40px; min-width: 40px;"><!--selection--></span>
        <span class="color-primary days-one"
              style="width: 15vw; min-width: 150px; margin: 5px 10px">Parkplatz</span>
        <span class="color-primary days-one"
              style="width: 10vw; min-width: 200px; margin: 5px 10px">Parkbeginn</span>
        <span class="color-primary days-one"
              style="width: 10vw; min-width: 200px; margin: 5px 10px">Parkende</span>
        <span class="color-primary days-one"
              style="width: 7vw; min-width: fit-content; margin: 5px 10px">Parkdauer</span>
        <span class="color-primary days-one"
              style="width: 5vw; min-width: fit-content; margin: 5px 10px">Parkgebühr</span>
        <span class="color-primary days-one"
              style="width: 5vw; min-width: fit-content; margin: 5px 10px">Tarif-Info</span>
        <span class="color-primary days-one"
              style="width: 5vw; min-width: fit-content; margin: 5px 10px">Status</span>
        <span class="color-primary days-one"
              style="width: 10vw; min-width: fit-content; margin: 5px 10px"><!--löschen--></span>
      </div>

      <div *ngFor="let parkingOperationWithSelection of parkingOperationsWithBookingsAndFeesSelections">
        <mat-divider style="margin: 10px"></mat-divider>

        <div
          *ngIf="getParkingLotOfParkingOperation(parkingOperationWithSelection.parking_operation)?.name != null"
          style="display: flex; flex-direction: row; align-items: center">

          <mat-checkbox [disabled]="isParkingOperationDisabled(parkingOperationWithSelection)"
                        color="primary" style="width: 40px; min-width: 40px"
                        [(ngModel)]="parkingOperationWithSelection.selected"
                        (change)="recomputeSelectedFees()">
          </mat-checkbox>

          <span style="width: 15vw; min-width: 150px; margin: 0 10px">
                {{getParkingLotOfParkingOperation(parkingOperationWithSelection.parking_operation)?.name}}
              </span>

          <mat-form-field *ngIf="isStartEditable(parkingOperationWithSelection.parking_operation); else startTime"
                          appearance="outline" style="width: 10vw; min-width: 200px; margin: 0 10px"
                          color="primary">
            <input matInput type="datetime-local" placeholder="Parkbeginn eingeben" class="color-primary"
                   [(ngModel)]="parkingOperationWithSelection.editedStartTime"
                   (input)="onEditedStartTime(parkingOperationWithSelection)"
                   [formControl]="parkingOperationWithSelection.startTimeFormControl">
            <mat-error *ngIf="parkingOperationWithSelection.startTimeFormControl.hasError('startAfterEnd')">Start
              muss vor Ende liegen
            </mat-error>
            <mat-error *ngIf="parkingOperationWithSelection.startTimeFormControl.hasError('required')">Ungültige
              Startzeit
            </mat-error>
          </mat-form-field>

          <ng-template #startTime>
              <span style="width: 10vw; min-width: 200px; margin: 0 10px">
                {{parkingOperationWithSelection.parking_operation.parking_operation.start_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm'}}
              </span>
          </ng-template>

          <mat-form-field *ngIf="isEndEditable(parkingOperationWithSelection.parking_operation); else endTime"
                          appearance="outline" style="width: 10vw; min-width: 200px; margin: 0 10px"
                          color="primary">
            <input matInput type="datetime-local" placeholder="Parkende eingeben" class="color-primary"
                   [(ngModel)]="parkingOperationWithSelection.editedEndTime"
                   (input)="onEditedEndTime(parkingOperationWithSelection)"
                   [formControl]="parkingOperationWithSelection.endTimeFormControl">
            <mat-error *ngIf="parkingOperationWithSelection.endTimeFormControl.hasError('endBeforeStart')">Ende
              muss
              nach Start liegen
            </mat-error>
            <mat-error *ngIf="parkingOperationWithSelection.endTimeFormControl.hasError('required')">Ungültige
              Endzeit
            </mat-error>
          </mat-form-field>

          <ng-template #endTime>
              <span style="width: 10vw; min-width: 200px; margin: 0 10px">
                {{parkingOperationWithSelection.parking_operation.parking_operation.end_event!.time * 1000 | date: 'dd.MM.yyyy HH:mm'}}
              </span>
          </ng-template>

          <span style="width: 7vw; min-width: 70px; margin: 0 10px">
                {{getParkingDuration(parkingOperationWithSelection.parking_operation)}}
              </span>

          <span style="width: 5vw; min-width: 50px; margin: 0 10px">
                <span *ngIf="isParkingOperationComplete(parkingOperationWithSelection.parking_operation)">
                {{getFormattedFee(parkingOperationWithSelection.parking_operation.fee)}}
                </span>
              </span>

          <div style="width: 5vw; min-width: 50px; margin: 0 10px">
            <button mat-icon-button
                    (click)="openTariffStructureDialog(getParkingLotOfParkingOperation(parkingOperationWithSelection.parking_operation)?.id ?? -1)">
              <mat-icon color="primary" style="scale: 1.25">info_outline</mat-icon>
            </button>
          </div>

          <div style="width: 5vw; min-width: 50px; margin: 0 10px">
            <span>{{getPaymentStatus(parkingOperationWithSelection.parking_operation)}}</span>
          </div>

          <div style="width: 10vw; min-width: 100px;">
            <button *ngIf="isParkingOperationDeletable(parkingOperationWithSelection)"
                    mat-stroked-button color="warn" style="width: fit-content;" class="mat-elevation-z2"
                    (click)="deleteParkingOperation(parkingOperationWithSelection.parking_operation)">
              Parkvorgang löschen
            </button>
          </div>

        </div>
      </div>

    </mat-card>
  </div>


  <!--this version of the parking operation list will be displayed if the screen is in portrait mode-->
  <div class="portrait-mode-only" style="flex-direction: column">
    <mat-card *ngFor="let parkingOperationWithSelection of parkingOperationsWithBookingsAndFeesSelections"
              class="parking-card">
      <!-- Blue Accent Bar -->
      <div class="accent-bar background-color-accent"></div>

      <div class="card-inner-wrapper">
        <!-- Checkbox -->
        <div class="checkbox-wrapper">
          <mat-checkbox [disabled]="isParkingOperationDisabled(parkingOperationWithSelection)"
                        color="primary"
                        [(ngModel)]="parkingOperationWithSelection.selected"
                        (change)="recomputeSelectedFees()">
          </mat-checkbox>
        </div>

        <div *ngIf="getParkingLotOfParkingOperation(parkingOperationWithSelection.parking_operation)?.name != null"
             class="parking-card-content">

          <!-- Location Header -->
          <div class="parking-header">
          <span class="color-primary days-one location-text">
            {{getParkingLotOfParkingOperation(parkingOperationWithSelection.parking_operation)?.name}}
          </span>
          </div>

          <!-- Time Section -->
          <div class="time-section">
            <ng-container *ngIf="isSameDay(parkingOperationWithSelection.parking_operation) &&
                       !isStartEditable(parkingOperationWithSelection.parking_operation) &&
                       !isEndEditable(parkingOperationWithSelection.parking_operation) &&
                       parkingOperationWithSelection.parking_operation.parking_operation.end_event != null; else otherCases">
              <!-- Compact Same-Day Non-Editable Display -->
              <div class="compact-same-day">
      <span class="date-value color-primary days-one">
        {{parkingOperationWithSelection.parking_operation.parking_operation.start_event!.time * 1000 | date: 'dd.MM.yyyy'}}
      </span>
                <span class="time-range">
        {{parkingOperationWithSelection.parking_operation.parking_operation.start_event!.time * 1000 | date: 'HH:mm'}} -
                  {{parkingOperationWithSelection.parking_operation.parking_operation.end_event!.time * 1000 | date: 'HH:mm'}}
      </span>
              </div>
            </ng-container>

            <!-- All other cases use improved layout -->
            <ng-template #otherCases>
              <div class="time-blocks-aligned">
                <!-- Start Time Row -->
                <div class="time-row">
                  <span class="time-label">Von</span>
                  <div class="time-content time-content-compact">
                    <!-- Non-editable Start Time -->
                    <ng-container *ngIf="!isStartEditable(parkingOperationWithSelection.parking_operation)">
            <span class="date-value color-primary days-one">
              {{parkingOperationWithSelection.parking_operation.parking_operation.start_event!.time * 1000 | date: 'dd.MM.yyyy'}}
            </span>
                      <span class="time-value">
              {{parkingOperationWithSelection.parking_operation.parking_operation.start_event!.time * 1000 | date: 'HH:mm'}}
            </span>
                    </ng-container>

                    <!-- Editable Start Time -->
                    <mat-form-field *ngIf="isStartEditable(parkingOperationWithSelection.parking_operation)"
                                    appearance="outline" class="time-picker"
                                    color="primary">
                      <input matInput type="datetime-local" placeholder="Parkbeginn eingeben" class="color-primary"
                             [(ngModel)]="parkingOperationWithSelection.editedStartTime"
                             (input)="onEditedStartTime(parkingOperationWithSelection)"
                             [formControl]="parkingOperationWithSelection.startTimeFormControl">
                      <mat-error *ngIf="parkingOperationWithSelection.startTimeFormControl.hasError('startAfterEnd')">
                        Start muss vor Ende liegen
                      </mat-error>
                      <mat-error *ngIf="parkingOperationWithSelection.startTimeFormControl.hasError('required')">
                        Ungültiger Startzeitpunkt
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <!-- End Time Row -->
                <div class="time-row">
                  <span class="time-label">Bis</span>
                  <div class="time-content time-content-compact">
                    <!-- Editable End Time -->
                    <mat-form-field *ngIf="isEndEditable(parkingOperationWithSelection.parking_operation)"
                                    appearance="outline" class="time-picker"
                                    color="primary">
                      <input matInput type="datetime-local" placeholder="Parkende eingeben" class="color-primary"
                             [(ngModel)]="parkingOperationWithSelection.editedEndTime"
                             (input)="onEditedEndTime(parkingOperationWithSelection)"
                             [formControl]="parkingOperationWithSelection.endTimeFormControl">
                      <mat-error *ngIf="parkingOperationWithSelection.endTimeFormControl.hasError('endBeforeStart')">
                        Ende muss nach Start liegen
                      </mat-error>
                      <mat-error *ngIf="parkingOperationWithSelection.endTimeFormControl.hasError('required')">
                        Ungültige Endzeit
                      </mat-error>
                    </mat-form-field>

                    <!-- Non-editable End Time -->
                    <ng-container *ngIf="!isEndEditable(parkingOperationWithSelection.parking_operation) && parkingOperationWithSelection.parking_operation.parking_operation.end_event">
            <span class="date-value color-primary days-one">
              {{parkingOperationWithSelection.parking_operation.parking_operation.end_event!.time * 1000 | date: 'dd.MM.yyyy'}}
            </span>
                      <span class="time-value">
              {{parkingOperationWithSelection.parking_operation.parking_operation.end_event!.time * 1000 | date: 'HH:mm'}}
            </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>          <div class="parking-footer">
            <div class="footer-left">
              <button mat-icon-button
                      (click)="openTariffStructureDialog(getParkingLotOfParkingOperation(parkingOperationWithSelection.parking_operation)?.id ?? -1)">
                <mat-icon color="primary">info_outline</mat-icon>
              </button>
              <span class="payment-status">
              {{getPaymentStatus(parkingOperationWithSelection.parking_operation)}}
            </span>
            </div>

            <div class="footer-right">
            <span class="fee-amount days-one" *ngIf="isParkingOperationComplete(parkingOperationWithSelection.parking_operation)">
              {{getFormattedFee(parkingOperationWithSelection.parking_operation.fee)}}
            </span>
              <!--              <button *ngIf="isParkingOperationDeletable(parkingOperationWithSelection)"-->
              <!--                      mat-button-->
              <!--                      color="warn color-primary"-->
              <!--                      class="delete-button"-->
              <!--                      (click)="deleteParkingOperation(parkingOperationWithSelection.parking_operation)">-->
              <!--                Löschen-->
              <!--              </button>-->
              <button *ngIf="isParkingOperationDeletable(parkingOperationWithSelection)"
                      mat-icon-button
                      class="delete-button"
                      (click)="deleteParkingOperation(parkingOperationWithSelection.parking_operation)"
                      aria-label="Parkvorgang löschen">
                <mat-icon class="delete-icon" [inline]="true">close</mat-icon>
              </button>
            </div>
          </div>

        </div>
      </div>
    </mat-card>
  </div></div>
