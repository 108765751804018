import {ParkingOperationWithBookingsAndFees} from "./ParkingOperation";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import moment from "moment/moment";
import {TIME_FORMAT} from "./constants";

export class ParkingOperationWithBookingsAndFeesWithSelection {
  parking_operation: ParkingOperationWithBookingsAndFees;
  selected: boolean;
  editedStartTime: string | null;
  editedEndTime: string | null;
  startTimeFormControl: FormControl;
  endTimeFormControl: FormControl;

  constructor(
    parking_operation: ParkingOperationWithBookingsAndFees,
    selected: boolean,
    editedStartTime: string | null = null,
    editedEndTime: string | null = null
  ) {
    this.parking_operation = parking_operation;
    this.selected = selected;
    this.editedStartTime = editedStartTime;
    this.editedEndTime = editedEndTime;

    if (this.editedStartTime != null) {
      this.startTimeFormControl = new FormControl(editedStartTime, [Validators.required, (control: AbstractControl) => {
        if (this.parking_operation.parking_operation.end_event == null && (this.editedEndTime?.length ?? 0) == 0) {
          return null;
        }
        if (control.value == null || control.value.length == 0) {
          return null;
        }
        const unixStartSeconds = moment(control.value, TIME_FORMAT).unix();
        const unixEndSeconds = this.parking_operation.parking_operation.end_event?.time ?? moment(this.editedEndTime, TIME_FORMAT).unix();
        return unixStartSeconds < unixEndSeconds ? null : {startAfterEnd: true};
      }]);
    } else {
      this.startTimeFormControl = new FormControl('');
    }

    if (this.editedEndTime != null) {
      this.endTimeFormControl = new FormControl(editedEndTime, [Validators.required, (control: AbstractControl) => {
        if (this.parking_operation.parking_operation.start_event == null && (this.editedStartTime?.length ?? 0) == 0) {
          return null;
        }
        if (control.value == null || control.value.length == 0) {
          return null;
        }
        const unixStartSeconds = this.parking_operation.parking_operation.start_event?.time ?? moment(this.editedStartTime, TIME_FORMAT).unix();
        const unixEndSeconds = moment(control.value, TIME_FORMAT).unix();
        return unixStartSeconds < unixEndSeconds ? null : {endBeforeStart: true};
      }]);
    } else {
      this.endTimeFormControl = new FormControl('');
    }
  }

}
