import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FeeService {


  constructor() {
  }

  /**
   * TODO currency may have to depend on the parking lot tariff structure currency
   * fee in smallest currency unit. Default is EUR.
   */
  getFormattedFee(fee: number): string {
    return this.getFormattedFeeValueOnly(fee) + " EUR";
  }

  getFormattedFeeValueOnly(fee: number, useCommaSeparator: boolean = true): string {
    const value = (fee / 100).toFixed(2)
    if(useCommaSeparator) {
      return value.replace(".", ",");
    }
    return value;
  }

}
