import {ApiRoutesService} from "./api/api-routes.service";
import {ApiCallWrapperService} from "./api/api-call-wrapper.service";
import {Injectable} from "@angular/core";
import {GlobalStateService} from "./global-state.service";

@Injectable({
  providedIn: 'root'
})
export class InvoiceDownloadService {

  constructor(
    private apiCallWrapper: ApiCallWrapperService,
    private apiRouteService: ApiRoutesService,
    private globalStateService: GlobalStateService
  ) {
  }

  /**
   * Downloads the invoice pdf file with the given id.
   * One can wait for the download to complete by awaiting this function.
   */
  async downloadInvoice(
    invoiceId: string,
    invoiceIdString: string,
    normalizedLicensePlate: string = this.globalStateService.normalizedLicensePlate,
    licensePlate: string = this.globalStateService.licensePlateInput,
    fileName: string = "PaymentWebsiteRechnung" + invoiceIdString + ".pdf"
  ) {
    await this.apiCallWrapper.call(
      this.apiRouteService.downloadInvoiceWithPaymentLinkPdf(normalizedLicensePlate, licensePlate, invoiceId)
    ).then((fileContent) => {

      if (fileContent == null) {
        return;
      }

      this.downloadZipFileContent(fileContent, fileName, "application/pdf");
    });
  }

  downloadZipFileContent(fileContent: Blob, fileName: string, type: string) {
    const zipFileBlob = new Blob([fileContent], {type: type});

    // @ts-ignore
    if (navigator.msSaveBlob) { // In case of IE 10+
      // @ts-ignore
      navigator.msSaveBlob(zipFileBlob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(zipFileBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  async downloadReceiptPdf(
    invoiceId: string,
    invoiceIdString: string,
    normalizedLicensePlate: string = this.globalStateService.normalizedLicensePlate,
    licensePlate: string = this.globalStateService.licensePlateInput,
    fileName: string = "PaymentWebsiteQuittung" + invoiceIdString + ".pdf"
  ) {
    await this.apiCallWrapper.call(
      this.apiRouteService.downloadReceiptPdf(normalizedLicensePlate, licensePlate, invoiceId)
    ).then((fileContent) => {

      if (fileContent == null) {
        return;
      }

      this.downloadZipFileContent(fileContent, fileName, "application/pdf");
    });
  }

}
