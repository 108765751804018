import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalStateService} from "../../../services/global-state.service";
import {Router} from "@angular/router";
import {FormControl, Validators} from "@angular/forms";
import {ApiCallWrapperService} from "../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../services/api/api-routes.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InvoiceDownloadService} from "../../../services/invoice-download-service";
import {FeeService} from "../../../services/fee.service";
import {ParkingLot} from "../../../models/ParkingLot";
import {ParkingLotsCacheService} from "../../../services/parking-lots-cache.service";
import {PaymentWebsiteInvoiceWithPaymentLink} from "../../../models/InvoiceWithPaymentLink";
import {MatDialog} from "@angular/material/dialog";
import {interval, Subscription} from "rxjs";
import {PaymentHandlingService} from "../../../services/payment/payment-handling.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {LocalStorageService} from "../../../services/local-storage.service";

@Component({
  selector: 'app-invoices-page',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.css'],
  animations: [
    trigger('paymentSuccess', [
      state('initial', style({
        border: '1px solid rgba(0, 0, 0, 0.12)', // default mat-card border
        boxShadow: 'none',
        backgroundColor: 'white'
      })),
      state('success', style({
        border: '1px solid #81c784', // subtle green border
        backgroundColor: '#f1fff7' // very soft green background
      })),
      transition('initial => success', [
        // First flash a more prominent success state
        animate('0.3s ease-in', style({
          border: '2px solid #4CAF50',
          boxShadow: '0 0 12px rgba(76, 175, 80, 0.5)'
        })),
        // Then settle into the subtle final state
        animate('0.5s ease-out')
      ])
    ])
  ],
  standalone: false
})
export class InvoicesPageComponent implements OnInit, OnDestroy {

  private refreshInterval = 5000; // 5 seconds
  private refreshSubscription?: Subscription;
  private updateTimeInterval?: Subscription;
  lastUpdateTime?: Date;
  emailAddressFormControl = new FormControl('', [Validators.email]);

  invoicesWithPaymentLink: PaymentWebsiteInvoiceWithPaymentLink[] = [];
  finishedUpdating: boolean = false;
  downloadingInvoiceIds: string[] = [];
  selectedInvoices: PaymentWebsiteInvoiceWithPaymentLink[] = [];

  isSendingEmail = false;
  lastSentInvoiceIdStrings: string[] = [];
  cancelPayment = false;

  paymentStates: { [key: string]: 'success' | 'initial' } = {};

  parkingLots: ParkingLot[] = [];
  isUpdating = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    private invoiceDownloadService: InvoiceDownloadService,
    public globalStateService: GlobalStateService,
    private feeService: FeeService,
    private parkingLotsCacheService: ParkingLotsCacheService,
    private paymentHandlingService: PaymentHandlingService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService
  ) {

    if (globalStateService.normalizedLicensePlate.length == 0 || globalStateService.licensePlateInput.length == 0) {
      this.router.navigateByUrl("/");
    }
  }

  async ngOnInit() {
    await this.loadInvoicesWithPaymentLink();
    this.lastUpdateTime = new Date()
    this.setupRefreshInterval();
    this.setupUpdateTimeInterval();
    this.startInactivityTimer();
    this.events.forEach(event => {
      window.addEventListener(event, () => this.resetInactivityTimer());
    });
  }


  private async refreshData() {
    try {
      await this.loadInvoicesWithPaymentLink();
      this.lastUpdateTime = new Date();
    } catch (error) {
      console.error('Failed to refresh invoices:', error);
    }
  }

  private setupRefreshInterval() {
    let counter = 0;

    this.refreshSubscription = interval(this.refreshInterval).subscribe(async () => {
      counter++;
      this.isUpdating = true;
      await this.refreshData();
      await this.delay(500);
      this.isUpdating = false;
      if (counter >= 24) {
        this.refreshSubscription?.unsubscribe();
        this.finishedUpdating = true;
      }
    });
  }

  private async delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private inactivityTimer!: ReturnType<typeof setTimeout>;
  private readonly events = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

  isInactive = false;
  inactivityDuration =5000; // 5 seconds


  ngOnDestroy() {
    this.refreshSubscription?.unsubscribe();
    this.updateTimeInterval?.unsubscribe();
    clearTimeout(this.inactivityTimer);
    this.events.forEach(event => {
      window.removeEventListener(event, () => this.resetInactivityTimer());
    });

  }

  private startInactivityTimer() {
    this.inactivityTimer = setTimeout(() => {
      this.isInactive = true;
      console.log("Inactivity detected");
    }, this.inactivityDuration);
  }

  private resetInactivityTimer() {
    this.isInactive = false;
    console.log("Inactivity reset");
    clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  }

  private setupUpdateTimeInterval() {
    // Update every 5 second for the first two minutes. After that cancel updates
    this.updateTimeInterval = interval(5000).subscribe(() => {
      const diffInSeconds = Math.floor(
        (new Date().getTime() - this.lastUpdateTime!.getTime()) / 1000
      );

      if (diffInSeconds >= 60) {
        // Switch to minute-based updates after the first minute
        this.updateTimeInterval?.unsubscribe();
        this.updateTimeInterval = interval(60000).subscribe(() => {
          this.lastUpdateTime = this.lastUpdateTime;
        });
      }

      this.lastUpdateTime = this.lastUpdateTime;
    });
  }

  getTimeSinceLastUpdate(): string {
    if (!this.lastUpdateTime) return '';

    const diffInSeconds = Math.floor(
      (new Date().getTime() - this.lastUpdateTime.getTime()) / 1000
    );

    // Less than a minute
    if (diffInSeconds < 60) {
      if (diffInSeconds <= 1) return 'einer Sekunde';
      return `${diffInSeconds} Sekunden`;
    }

    // Minutes
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      if (diffInMinutes === 1) return 'einer Minute';
      return `${diffInMinutes} Minuten`;
    }

    // Hours
    const hours = Math.floor(diffInMinutes / 60);
    if (hours < 24) {
      if (hours === 1) return 'einer Stunde';
      return `${hours} Stunden`;
    }

    // Days (optional, depending on your needs)
    const days = Math.floor(hours / 24);
    if (days === 1) return 'einem Tag';
    return `${days} Tagen`;
  }


  async loadInvoicesWithPaymentLink() {
    this.parkingLotsCacheService.getParkingLots().then(parkingLots => {
      this.parkingLots = parkingLots;
      this.apiCallHelper.call(
        this.apiRoutesService.getInvoicesWithPaymentLink(this.globalStateService.normalizedLicensePlate)
      ).then((invoicesWithPaymentLink) => {
        if (invoicesWithPaymentLink == null) {
          this.snackBar.open("Leider ist etwas schief gelaufen. Bitte versuchen Sie es erneut.", "", {duration: 3000});
          return;
        }
        if (invoicesWithPaymentLink.length > 0) {
          this.localStorageService.setLicensePlateData(this.globalStateService.licensePlateInput,
            this.globalStateService.normalizedLicensePlate)
        }
        this.invoicesWithPaymentLink = invoicesWithPaymentLink.sort((invoice1, invoice2) => invoice2.invoice.created_at - invoice1.invoice.created_at);
        this.invoicesWithPaymentLink?.forEach(invoice => {
          if (this.globalStateService.hasPaidInvoiceInSessionId(invoice.invoice.invoice_id_string)) {
            this.paymentStates[invoice.invoice.invoice_id_string] = 'success';
          } else {
            this.paymentStates[invoice.invoice.invoice_id_string] = 'initial';
          }
        });
      });
    });
  }

  selectionChange(invoice: PaymentWebsiteInvoiceWithPaymentLink, selected: boolean) {
    if (selected) {
      this.selectedInvoices.push(invoice);
    } else {
      this.selectedInvoices = this.selectedInvoices.filter(selectedInvoice => selectedInvoice.invoice.invoice_id != invoice.invoice.invoice_id);
    }
  }

  clearStateAndGoToLanding() {
    this.globalStateService.clearState();
    this.localStorageService.removeLicensePlateData();
    this.router.navigateByUrl("/");
  }


}
