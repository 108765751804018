<div class="pay-button-container pay-now-button"  #payButton>
  <!-- Payment Button -->
  <button mat-fab extended class="pay-button" color="primary" style="padding: 1.5rem;height:40px;"
          [disabled]="totalSelectionFee.value <= 0"
          *ngIf="!checkoutInProgress && !successfulPayment && !paymentFailed"
          (click)="onStartCheckout()">
    Jetzt bezahlen
    <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
  </button>

  <!-- Loading Button -->
  <button mat-fab extended class="pay-button" color="primary" style="padding: 1.5rem;height:40px;"
          [disabled]="true"
          *ngIf="checkoutInProgress && !successfulPayment && !paymentFailed"
         >
    <div class="spinner-container">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
    {{ getLoadingStatusString() }}
  </button>

  <!-- Success Message -->
  <div *ngIf="successfulPayment" class="success-message" style="text-align: center;">
    <mat-icon style="color: #4CAF50; font-size: 48px; height: 48px; width: 48px;">check_circle</mat-icon>
    <p style="color: #4CAF50; margin-top: 8px;">Zahlung erfolgreich!</p>
  </div>

  <!-- Error Message with Retry -->
  <div *ngIf="paymentFailed" class="error-message" style="text-align: center;">
    <div style="display: flex; justify-content: center; align-items: center; gap: 10px;">
      <mat-icon style="color: #f44336; font-size: 48px; height: 48px; width: 48px;">error</mat-icon>
    </div>
    <p style="color: #f44336; margin-top: 8px;">Zahlung fehlgeschlagen</p>
    <p style="color: #f44336; margin-top: 8px;"></p>

    <button mat-fab extended *ngIf="!onInvoicesPage"
            style="padding: 1.5rem;height: 40px; background-color: #ff9800;"
            (click)="goToInvoices()">
      Versuchen Sie hier erneut
      <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>

<app-app-scroll-indicator [totalSelectionFee]="totalSelectionFee"></app-app-scroll-indicator>
