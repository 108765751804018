
export const PRESET_COLOR_SCHEME: string[] = [
  '#F1F1F1', // light grey as default color
  '#CBE7FF', // light blue
  '#E9CBFF', // light purple
  '#CBFFE7', // light green
  '#FFCBCB', // light orange
  '#FFFDCB', // light yellow
  '#FFCDCB', // light red
  '#dce775', // lime
  '#81c784', // green
  '#4db6ac', // teal
  '#4dd0e1', // cyan
  '#7986cb', // indigo
  '#ba68c8', // purple
  '#e57373', // red
];
