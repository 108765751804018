<div *ngIf="paymentData">
  <h2>Payment Details</h2>
  <p>Total Fee: {{paymentData.totalFee}}</p>
  <p>Invoice ID: {{paymentData.invoiceIdString}}</p>
  <p>License Plate: {{paymentData.licensePlate}}</p>
  <!-- Add other fields as needed -->

  <div style="display: flex; gap: 10px;">
    <button mat-raised-button color="primary" (click)="navigateToLicensePlate()">
      Go to License Plate
    </button>
    <button mat-raised-button (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
