import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {ParkingOperationWithBookingsAndFees} from "../../../../../../models/ParkingOperation";
import {ParkingLot} from "../../../../../../models/ParkingLot";
import {ApiCallWrapperService} from "../../../../../../services/api/api-call-wrapper.service";
import {ApiRoutesService} from "../../../../../../services/api/api-routes.service";
import {GlobalStateService} from "../../../../../../services/global-state.service";
import {AddSupportBookingDialogComponent} from "./add-support-booking-dialog/add-support-booking-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FeeService} from "../../../../../../services/fee.service";
import {
  TariffSettingDialogComponent
} from "../../../../../user-page/tariff-setting-dialog/tariff-setting-dialog.component";
import {
  notCompletedAndFreeFilter
} from "../../../../../user-page/parking-operation-customer-payment/parking-operations-page/parking-operations-page.component";

@Component({
    selector: 'app-open-parking-operation-list-management',
    templateUrl: './open-parking-operation-list-management.component.html',
    styleUrls: ['./open-parking-operation-list-management.component.css'],
    standalone: false
})
export class OpenParkingOperationListManagementComponent implements OnInit, OnDestroy {

  @Input() parkingLotSubject!: BehaviorSubject<ParkingLot[]>;
  @Input() reloadTriggerSubject!: BehaviorSubject<any>;

  @Output() onNewBookingCreated: EventEmitter<any> = new EventEmitter<any>();

  parkingOperationsWithBookingsAndFees: ParkingOperationWithBookingsAndFees[] = [];

  isLoading = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private apiCallHelper: ApiCallWrapperService,
    private apiRoutesService: ApiRoutesService,
    private globalStateService: GlobalStateService,
    private feeService: FeeService,
    private dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.subscriptions.push(
      this.reloadTriggerSubject.subscribe(() => this.reloadParkingOperations())
    );
  }

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

  getParkingLotOfParkingOperation(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): ParkingLot | null {
    const startEvent = parkingOperationWithBookingsAndFees.parking_operation.start_event;
    const endEvent = parkingOperationWithBookingsAndFees.parking_operation.end_event;

    const parkingLotId = startEvent?.parking_lot_id ?? endEvent?.parking_lot_id;
    if (parkingLotId == null) {
      return null;
    }

    return this.parkingLotSubject.value.find(parkingLot => parkingLot.id == parkingLotId) ?? null;
  }

  getParkingDuration(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): string {
    const startEvent = parkingOperationWithBookingsAndFees.parking_operation.start_event;
    const endEvent = parkingOperationWithBookingsAndFees.parking_operation.end_event;

    if (startEvent == null || endEvent == null) {
      return "";
    }

    const durationSeconds = endEvent.time - startEvent.time;
    const durationMinutes = Math.ceil(durationSeconds / 60);

    const days = Math.floor(durationMinutes / 60 / 24);
    const hours = Math.floor(durationMinutes / 60) % 24;
    const minutes = durationMinutes % 60;

    return (days > 0 ? days + "T " : "") + (hours > 0 ? hours + "h " : "") + minutes + "min";
  }

  openTariffStructureDialog(parkingLotId: number) {
    const parkingLot = this.parkingLotSubject.value.find(parkingLot => parkingLot.id == parkingLotId);
    this.dialog.open(TariffSettingDialogComponent, {
      maxWidth: '95vw',
      maxHeight: '90vh',
      data: {
        parkingLot: parkingLot
      },
      autoFocus: false
    });
  }

  isParkingOperationComplete(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): boolean {
    return parkingOperationWithBookingsAndFees.parking_operation.start_event != null &&
      parkingOperationWithBookingsAndFees.parking_operation.end_event != null;
  }

  openAddSupportBookingDialog(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees) {
    const parkingLot = this.getParkingLotOfParkingOperation(parkingOperationWithBookingsAndFees);
    if (parkingLot == null) {
      console.log("Parking lot not found")
      return;
    }

    this.dialog.open(AddSupportBookingDialogComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        parkingOperation: parkingOperationWithBookingsAndFees,
        parkingLot: parkingLot,
        onNewBookingCreated: () => this.onNewBookingCreated.emit()
      },
      autoFocus: false
    });
  }

  getTotalFee(parkingOperations: ParkingOperationWithBookingsAndFees[]): string {
    const totalFee = parkingOperations.map(parkingOperationWithBookingsAndFees =>
      parkingOperationWithBookingsAndFees.fee
    ).reduce((a, b) => a + b, 0);
    return this.getFormattedFee(totalFee);
  }

  getPaymentStatus(parkingOperationWithBookingsAndFees: ParkingOperationWithBookingsAndFees): string {
    if (parkingOperationWithBookingsAndFees.parking_operation.start_event == null ||
      parkingOperationWithBookingsAndFees.parking_operation.end_event == null) {
      return "";
    }

    if (parkingOperationWithBookingsAndFees.fee == 0) {
      if (parkingOperationWithBookingsAndFees.bookings.length == 0) {
        return "Kostenlos";
      } else {
        return "Vollständig abgerechnet";
      }
    } else {
      if (parkingOperationWithBookingsAndFees.bookings.length == 0) {
        return "Offen";
      } else {
        return "Offen";//teilweise abgerechnet
      }
    }
  }

  private reloadParkingOperations() {
    this.isLoading = true;

    this.apiCallHelper.call(
      this.apiRoutesService.getParkingOperationsWithBookings(
        this.globalStateService.licensePlateInput,
        this.globalStateService.normalizedLicensePlate,
      )
    ).then(parkingOperationsWithBookingsAndFees => {
      this.parkingOperationsWithBookingsAndFees = (parkingOperationsWithBookingsAndFees ?? []).filter(parkingOperationWithBookingsAndFees =>
        notCompletedAndFreeFilter(parkingOperationWithBookingsAndFees))
      this.isLoading = false;
    });
  };

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
