<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover">
<div class="logo-container"
     style="
  background-color: #fff;
  margin-top: -20px;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.75);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1000">
  <img class="mobile-mode-only" [src]="getHeaderIcon()" height="56px" alt="logo"
       style="margin:5px">
  <img class="desktop-mode-only" [src]="getHeaderIcon()" height="150px" alt="logo">
</div>

<div style="display: flex; flex-direction: column">

  <div class="background-color-accent design-bar design-bar-top days-one">
    <div style="display: flex; flex-direction: row; flex-grow: 1"></div>
    <!--    <div style="color: white; align-self: center; text-align: center; font-size: smaller; flex-grow: 1; margin-left: 2px; margin-right: 2px">-->
    <!--      Parkraum Service Payment-->
    <!--    </div>-->

    <!--    <h1 class="color-primary days-one font-size-h1" style="margin-top: 1rem; margin-bottom: 2rem">HERZLICH WILLKOMMEN</h1>-->

    <div class="design-bar-top-right-space" style="display: flex; flex-direction: row; flex-grow: 1"></div>
  </div>

  <router-outlet></router-outlet>

  <div class="design-bar design-bar-bottom days-one" style="flex-direction: column;align-items: center;"
  *ngIf="showFooter()">
    <div
      style="color: #838383; align-self: center; text-align: center; font-size: smaller; margin-left: 2px;margin-top:2px; margin-right: 2px">
      Bei Problemen erreichen Sie uns unter <br>{{ getEmail() }} oder <span
      style="white-space: nowrap">{{ getNumber() }}</span>
    </div>
    <div
      style="color: black; width: 100%; align-self: center; text-align: center; font-size: smaller;margin-bottom: 5px;">
      <a style="color: #2C3B7D" [href]="getDataProtectionRef()" target="_blank">Datenschutzerklärung</a>
      / <a style="color: #2C3B7D"  [href]="getContactRef()"  target="_blank">Impressum</a>
    </div>

    <div class="payment-logos " style="margin-top: 5px;">
<!--      <img class="payment-logo"  src="../assets/images/payment/de-pp-logo-200px.png" alt="Paypal"-->
<!--           style="height: 18px;margin-right: 5px;padding-bottom: 2px;">-->
      <img class="payment-logo" src="../../../../../assets/images/payment/mc.svg" alt="Mastercard"
           style="height:22px; margin-right: 10px;">
      <img class="payment-logo" src="../../../../../assets/images/payment/visa.svg" alt="Visa"
           style="height: 22px; margin-right: 10px;">
      <img class="payment-logo" src="../../../../../assets/images/payment/google_pay.png" alt="Google Pay"
           style="height: 22px; margin-right: 10px;">
      <img class="payment-logo" src="../../../../../assets/images/payment/apply_pay.svg" alt="Apple Pay"
           style="height: 20px;">

    </div>
  </div>

</div>
