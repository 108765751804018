import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-week-day-selector',
    templateUrl: './week-day-selector.component.html',
    styleUrls: ['./week-day-selector.component.css'],
    standalone: false
})
export class WeekDaySelectorComponent {
  @Input() selectedDays: number[] = [];
  @Output() daysChanged = new EventEmitter<number[]>();

  weekdays = [
    {label: 'Mo', value: 0},
    {label: 'Di', value: 1},
    {label: 'Mi', value: 2},
    {label: 'Do', value: 3},
    {label: 'Fr', value: 4},
    {label: 'Sa', value: 5},
    {label: 'So', value: 6}
  ];

  ngOnInit() {
    this.selectedDays = this.selectedDays.filter(day => day >= 0 && day <= 6);
  }

  isSelected(day: number): boolean {
    return this.selectedDays.includes(day);
  }

  toggleDay(day: number) {
    if (this.isSelected(day)) {
      this.selectedDays = this.selectedDays.filter(d => d !== day);
    } else {
      this.selectedDays = [...this.selectedDays, day].sort((a, b) => a - b);
    }
    console.log(this.selectedDays)
    this.daysChanged.emit(this.selectedDays);
  }
}
