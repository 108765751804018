import {Component, Inject} from '@angular/core';
import {GlobalStateService} from "../../../../services/global-state.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {InvoiceDownloadService} from "../../../../services/invoice-download-service";
import {FeeService} from "../../../../services/fee.service";

export interface SepaPaymentHintDialogComponentData {
  totalFee: number,
  invoiceId: string,
  invoiceIdString: string,
  emailSentSuccessfully: boolean
}

@Component({
    selector: 'app-sepa-payment-hint-dialog',
    templateUrl: './sepa-payment-hint-dialog.component.html',
    styleUrls: ['./sepa-payment-hint-dialog.component.css'],
    standalone: false
})
export class SepaPaymentHintDialogComponent {

  isDownloadingInvoice = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SepaPaymentHintDialogComponentData,
    public globalStateService: GlobalStateService,
    private invoiceDownloadService: InvoiceDownloadService,
    private feeService: FeeService
  ) {

  }

  downloadInvoice() {
    this.isDownloadingInvoice = true;
    this.invoiceDownloadService.downloadInvoice(this.data.invoiceId, this.data.invoiceIdString).then(() => {
      this.isDownloadingInvoice = false;
    });
  }

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

}
