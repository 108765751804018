import {Component, effect, Inject, OnInit, signal} from '@angular/core';
import {TariffStructure, TariffTimes, TariffWithTariffTimes} from "../../../../../models/TariffStructure";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ParkingLot} from "../../../../../models/ParkingLot";
import {BehaviorSubject} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {getTariffTimeTimespanDescription} from "../tariff-setting-quick-overview/tariff-setting-colored-hours";
import {ApiCallWrapperService} from "../../../../../services/api/api-call-wrapper.service";
import {ManagementApiRoutesService} from "../../../../../services/api/management-api-routes";
import {TariffStructureService} from "../../../../../services/tariff-structure.service";
import {GroupedTariffTimeStructureState, GroupedTariffTimesWithDays} from "./GroupedTariffTimeStructureState";


export interface TariffSettingEditDialogComponentData {
  parkingLot: ParkingLot,
  currentTariffStructure: TariffStructure | null
}

@Component({
    selector: 'app-tariff-setting-edit-dialog',
    templateUrl: './tariff-setting-edit-dialog.component.html',
    styleUrls: ['./tariff-setting-edit-dialog.component.css'],
    standalone: false
})
export class TariffSettingEditDialogComponent implements OnInit {

  tariffStructureSubject: BehaviorSubject<TariffStructure | null | undefined>;
  groupedTariffTimeState?: GroupedTariffTimeStructureState

  /**
   * Object that is being modified and pushed out on the tariffStructureSubject.
   */
  tariffStructure: TariffStructure;

  groupedTariffTimesArray = signal<GroupedTariffTimesWithDays[]>([])

  billingIntervalMinutesFormControl = new FormControl('', [Validators.required]);
  maximumDurationInMinutesFormControl = new FormControl('');
  maximumFeeFormControl = new FormControl('');

  dailyMaximumFeeResetTimeFormControl = new FormControl('');
  dailyMaximumFeeFormControl = new FormControl('');
  disableConfirmButton: boolean = true;
  /**
   * Error message to explain why the confirm button is disabled.
   */
  errorMessage: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TariffSettingEditDialogComponentData,
    private dialogRef: MatDialogRef<TariffSettingEditDialogComponent>,
    private apiCallHelper: ApiCallWrapperService,
    private managementApiRoutesService: ManagementApiRoutesService,
    private tariffStructureService: TariffStructureService
  ) {
    effect(() => {
      console.log("Current groups", this.groupedTariffTimesArray())
    })

    if (data.currentTariffStructure != null) {
      //copy so the object we change in this dialog is not the same as the one we were given
      console.log(data.currentTariffStructure.tariff_times)
      let mappedTariffTimes = data.currentTariffStructure.tariff_times.map(tariffTime => {
        return {
          tariff_id: tariffTime.tariff_id,
          start_time: tariffTime.start_time,
          end_time: tariffTime.end_time,
          day_of_week_start: tariffTime.day_of_week_start,
          day_of_week_end: tariffTime.day_of_week_end,
          fee: tariffTime.fee,
          flat_fee: tariffTime.flat_fee,
          consecutive_fees: tariffTime.consecutive_fees,
          billing_interval_minutes: tariffTime.billing_interval_minutes,
          consecutive_billing_interval_minutes: tariffTime.consecutive_billing_interval_minutes,
          free_parking_duration_minutes: tariffTime.free_parking_duration_minutes
        }
      })
      this.tariffStructure = {
        parking_lot_id: data.currentTariffStructure.parking_lot_id,
        tariff_id: data.currentTariffStructure.tariff_id,
        billing_interval_minutes: data.currentTariffStructure.billing_interval_minutes,
        maximum_duration_minutes: data.currentTariffStructure.maximum_duration_minutes,
        maximum_fee: data.currentTariffStructure.maximum_fee,
        currency: data.currentTariffStructure.currency,
        subtract_free_parking_duration: false,
        max_daily_fee: data.currentTariffStructure.max_daily_fee,
        daily_max_fee_reset_time: data.currentTariffStructure.daily_max_fee_reset_time,
        tariff_times: mappedTariffTimes
      };
    } else {
      this.tariffStructure = {
        parking_lot_id: data.parkingLot.id,
        tariff_id: -1,
        billing_interval_minutes: 60,
        maximum_duration_minutes: null,
        maximum_fee: null,
        currency: "EUR",
        subtract_free_parking_duration: false,
        tariff_times: [],
        max_daily_fee: null,
        daily_max_fee_reset_time: null
      }
    }

    this.tariffStructureSubject = new BehaviorSubject<TariffStructure | null | undefined>(this.tariffStructure);
    this.groupedTariffTimeState = new GroupedTariffTimeStructureState({...this.tariffStructure});

    //set all the form controls to the correct initial values
    this.billingIntervalMinutesFormControl.setValue("60");
    if (this.tariffStructure.maximum_duration_minutes != null) {
      this.maximumDurationInMinutesFormControl.setValue(this.tariffStructure.maximum_duration_minutes.toString());
    }
    if (this.tariffStructure.maximum_fee != null) {
      this.maximumFeeFormControl.setValue((this.tariffStructure.maximum_fee / 100).toString());
    }
    this.dailyMaximumFeeResetTimeFormControl.setValue(this.tariffStructure.daily_max_fee_reset_time != null ? this.minutesToTime(this.tariffStructure.daily_max_fee_reset_time) : "");
    if (this.tariffStructure.max_daily_fee != null) {
      this.dailyMaximumFeeFormControl.setValue((this.tariffStructure.max_daily_fee / 100).toString());
    }
  }

  ngOnInit(): void {
    this.onTariffStructureChange();

  }


  onBillingIntervalMinutesChange() {
    const input = this.billingIntervalMinutesFormControl.value;
    if (input == null) {
      this.tariffStructure.billing_interval_minutes = 60;
    } else {
      this.tariffStructure.billing_interval_minutes = +input;
    }
    this.onTariffStructureChange();
  }

  onMaximumDurationInMinutesChange() {
    const input = this.maximumDurationInMinutesFormControl.value;
    if (input == null) {
      this.tariffStructure.maximum_duration_minutes = null;
    } else {
      this.tariffStructure.maximum_duration_minutes = +input;
    }
    this.onTariffStructureChange();
  }

  onMaximumFeeChange() {
    const input = this.maximumFeeFormControl.value;
    if (input == null) {
      this.tariffStructure.maximum_fee = null;
    } else {
      this.tariffStructure.maximum_fee = +input * 100;
    }
    this.onTariffStructureChange();
  }

  onDailyMaximumFeeChange() {
    const input = this.dailyMaximumFeeFormControl.value;
    if (input == null) {
      this.tariffStructure.max_daily_fee = null;
    } else {
      this.tariffStructure.max_daily_fee = +input * 100;
    }
    this.onTariffStructureChange();
  }

  onDailyMaximumFeeResetTimeChange() {
    const input = this.dailyMaximumFeeResetTimeFormControl.value;
    console.log(input)
    if (input == null) {
      this.tariffStructure.daily_max_fee_reset_time = null;
    } else {
      let parsed = this.convertTimeStringToMinuteRange(input);
      this.tariffStructure.daily_max_fee_reset_time = parsed
    }
    this.onTariffStructureChange();
  }

  /**
   * Returns the minute of the day 0-1440 for a given time string like 10:00
   * @param timeString
   */
  convertTimeStringToMinuteRange(timeString: string): number {
    const parts = timeString.split(":");
    let hour = parseInt(parts[0]);
    let minute = parseInt(parts[1]);
    return hour * 60 + minute;
  }

  onConfirmClick() {
    this.apiCallHelper.call(
      this.managementApiRoutesService.createOrUpdateTariffStructure(this.tariffStructure)
    ).then(() => {
        this.tariffStructureService.invalidateCache(this.tariffStructure.parking_lot_id);
        this.dialogRef.close();
      },
      error => {
        console.log(error);
        this.errorMessage = "Fehler beim Speichern der Tarifstruktur"
      }
    );
  }

  addTariffTimeGroup() {
    const newTariffTime = {
      tariff_id: this.tariffStructure.tariff_id,
      start_time: 0,
      end_time: 60 * 24,
      day_of_week_start: 0,
      day_of_week_end: 0,
      fee: 100,
      flat_fee: null,
      consecutive_fees: [],
      billing_interval_minutes: null,
      consecutive_billing_interval_minutes: [],
      free_parking_duration_minutes: null
    }
    this.groupedTariffTimeState?.addTariffTime(newTariffTime);
    this.onTariffStructureChange();
  }

  removeTariffTime(groupedTariffTime: GroupedTariffTimesWithDays) {
    this.groupedTariffTimeState?.removeTariffGroup(groupedTariffTime)
    this.onTariffStructureChange();
  }

  onTariffStructureChange() {
    console.log("tariif structure change")
    this.groupTariffTimes()
    this.tariffStructure.tariff_times = this.groupedTariffTimeState?.getAllTariffTimes() || [];

    this.tariffStructureSubject.next(this.tariffStructure);
    const crossProduct = this.tariffStructure.tariff_times.flatMap(tariffTime => {
      return this.tariffStructure.tariff_times.map(tariffTime2 => {
        return [tariffTime, tariffTime2];
      })
    });

    const invalidTariffTimeCombination = crossProduct.find(tariffTime => {
      const a = tariffTime[0];
      const b = tariffTime[1];
      if (a === b) {
        return false;
      }
      return this.doTariffTimesOverlap(a, b);
    });

    this.disableConfirmButton = invalidTariffTimeCombination != null;

    if (invalidTariffTimeCombination != null) {
      this.errorMessage = "Die Zeitbereiche der Tarifstruktur dürfen sich nicht überschneiden.<br>" +
        "Es gibt eine Überschneidung zwischen:<br>" +
        getTariffTimeTimespanDescription(invalidTariffTimeCombination[0]) + "<br>und<br>" +
        getTariffTimeTimespanDescription(invalidTariffTimeCombination[1]);
    } else {
      this.errorMessage = null;
    }
  }

  private doTariffTimesOverlap(a: TariffTimes, b: TariffTimes): boolean {
    const minutesInDay = 60 * 24;

    const timeSpansToCompare: number[][] = [];

    const aStart = a.start_time + a.day_of_week_start * minutesInDay;
    let aEnd = a.end_time + a.day_of_week_end * minutesInDay;
    if (aEnd < aStart) {
      aEnd += 7 * minutesInDay;
    }

    const bStart = b.start_time + b.day_of_week_start * minutesInDay;
    let bEnd = b.end_time + b.day_of_week_end * minutesInDay;
    if (bEnd < bStart) {
      bEnd += 7 * minutesInDay;
    }

    timeSpansToCompare.push([aStart, aEnd, bStart, bEnd]);
    timeSpansToCompare.push([aStart + 7 * minutesInDay, aEnd + 7 * minutesInDay, bStart, bEnd]);
    timeSpansToCompare.push([aStart, aEnd, bStart + 7 * minutesInDay, bEnd + 7 * minutesInDay]);


    return timeSpansToCompare.some(timeSpan => {
      return this.timeSpanOverlap(timeSpan[0], timeSpan[1], timeSpan[2], timeSpan[3]);
    });
  }

  private timeSpanOverlap(aStart: number, aEnd: number, bStart: number, bEnd: number): boolean {
    return aStart < bEnd && bStart < aEnd;
  }

  private minutesToTime(daily_max_fee_reset_time: number) {
    const hours = Math.floor(daily_max_fee_reset_time / 60);
    if (hours == 24) {
      return "00:00";
    }
    const remainingMinutes = daily_max_fee_reset_time % 60;
    let result = hours.toString().padStart(2, "0") + ":" + remainingMinutes.toString().padStart(2, "0")
    return result
  }


  groupTariffTimes() {
    // this.groupedTariffTimeState?.groupTariffTimes()
    let newGroups = this.groupedTariffTimeState?.getAllGroupedTariffTimes() || []
    this.groupedTariffTimesArray.set(newGroups)
  }


  onDayChange(groupedTariffTime: GroupedTariffTimesWithDays, $event: number[]) {
    this.groupedTariffTimeState?.onDayChange(groupedTariffTime, $event);
    this.onTariffStructureChange();
  }


  /**
   *
   * @param groupedTariffTime
   * @param $event
   *
   * if the checkbox is checked create the tariff time for each day in the grouped tariff time
   * otherwise remove the tariff times that are in the grouped tariff time and replace it with a single tariff time
   */
  onMultiDayCheckChange(groupedTariffTime: GroupedTariffTimesWithDays, $event: boolean) {
    console.log("multi day check change", $event)
    if ($event) {
      this.groupedTariffTimeState?.convertToMultiDay(groupedTariffTime);
    } else {
      this.groupedTariffTimeState?.convertToTimeSpan(groupedTariffTime);
    }
    this.onTariffStructureChange();
  }


  onTariffTimeChange(groupedTariffTime: GroupedTariffTimesWithDays, tariffTime: TariffTimes) {
    console.log("tariff time change", tariffTime)
    this.groupedTariffTimeState?.onTariffTimeChange(groupedTariffTime, tariffTime);
    this.onTariffStructureChange();
  }

  getSortedGroupedTariffTimes(groupedTariffTimesWithDays: GroupedTariffTimesWithDays[]) {
    return groupedTariffTimesWithDays.sort((a, b) => {
      return a.sortOrder > b.sortOrder ? 1 : -1;
    })
  }
}
