import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../../../services/auth.service";
import {ApiCallWrapperService} from "../../../services/api/api-call-wrapper.service";
import {ManagementApiRoutesService} from "../../../services/api/management-api-routes";

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    standalone: false
})
export class LoginPageComponent {
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private apiCallHelper: ApiCallWrapperService,
    private managementApiRoutesService: ManagementApiRoutesService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.loginForm = this.formBuilder.group({
      username: this.formBuilder.control('', Validators.required),
      password: this.formBuilder.control('', Validators.required)
    });
  }

  user = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);

  /**
   * Triggering AuthService´s doLogin function with the entered credentials.
   *
   * If the user was logged in successfully, the user´s account info is being fetched
   * prior to the navigation to the landing page.
   *
   * Otherwise, a snackBar is shown to inform about incorrect credentials.
   *
   */
  submitCredentials(): void {
    const username = this.loginForm.get('username')?.value || "missing_username";
    const password = this.loginForm.get('password')?.value || "missing_password";
    this.loginDashboard(username, password);
  }

  /**
   * LoginDashboard is requesting the login to the management/support part of the website
   * @param username --> username of the user
   * @param password --> password of the user
   */
  private loginDashboard(username: string, password: string): void {
    this.apiCallHelper.call(this.managementApiRoutesService.login(username, password)).then(
      (response) => {
        if (response == null) {
          this.snackBar.open("Login credentials were invalid!", "", {duration: 3000});
          return;
        }

        this.authService.setToken(response.token);
        this.navigateToManagementStartPage();
      }
    );
  }

  private navigateToManagementStartPage() {
    this.router.navigateByUrl("management")
  }

}
