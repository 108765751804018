import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  output,
  Output,
  signal,
  SimpleChange
} from '@angular/core';
import {TariffTimes} from "../../../../../../models/TariffStructure";
import {FormControl, Validators} from "@angular/forms";
import {WEEKDAY_DISPLAY} from "../../tariff-setting-quick-overview/tariff-setting-color-bar/weekday.constant";
import {FeeService} from "../../../../../../services/fee.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {GroupedTariffTimesWithDays} from "../GroupedTariffTimeStructureState";
import {ConsecutiveFeeDescription} from "./consecutive-fee-input/consecutive-fee-input.component";

export interface TimeRangeChangeEvent {
  startDay: number,
  startTimeMinutes: number,
  endDay: number,
  endTimeMinutes: number
}

@Component({
    selector: 'app-tariff-time-setting',
    templateUrl: './tariff-time-setting.component.html',
    styleUrls: ['./tariff-time-setting.component.css'],
    standalone: false
})
export class TariffTimeSettingComponent implements OnInit, OnDestroy {

  @Input() groupedTariffTime!: GroupedTariffTimesWithDays;
  @Input() billing_interval_minutes!: number;
  @Output() tariffTimeChange = new EventEmitter<TariffTimes>();
  @Output() dayChange = new EventEmitter<number[]>();
  @Output() multiDayCheckEmitter = new EventEmitter<boolean>()

  multiDayCheck = signal(false);

  editTariffTime!: TariffTimes

  toggleChecked(checked: boolean) {
    console.log("Toggle checked", checked)
    this.multiDayCheck.set(checked);
  }

  weekDays = WEEKDAY_DISPLAY;

  startDaySelection: number = 0;
  startTimeFormControl = new FormControl('', [Validators.required]);

  endDaySelection: number = 0;
  endTimeFormControl = new FormControl('', [Validators.required]);

  feeType: string = "fee";
  feeFormControl = new FormControl('', []);
  billingIntervalMinutesOnTariffTimes = new FormControl('60', [Validators.required]);
  flatFeeFormControl = new FormControl('', []);
  consecutiveFeeValues: number[] = [];
  freeParkingMinutesFormControl = new FormControl<number | null>(null, []);
  subscriptions: Subscription[] = [];

  constructor(
    private feeService: FeeService
  ) {
    effect(() => {
      console.log("Multi day check", this.multiDayCheck())
      this.multiDayCheckEmitter.emit(this.multiDayCheck());
    });
  }

  ngOnInit() {
    console.log("Grouped tariff time", this.groupedTariffTime)
    this.editTariffTime = {...this.groupedTariffTime.tariffTimes[0]};
    console.log("multi day check", this.groupedTariffTime.multiDay)
    this.multiDayCheck.set(this.groupedTariffTime.multiDay);
    this.startDaySelection = this.editTariffTime.day_of_week_start;
    this.startTimeFormControl.setValue(this.minutesToTime(this.editTariffTime.start_time));
    this.endDaySelection = this.editTariffTime.day_of_week_end;
    this.endTimeFormControl.setValue(this.minutesToTime(this.editTariffTime.end_time));
    this.freeParkingMinutesFormControl.setValue(this.editTariffTime.free_parking_duration_minutes || null);
    if (this.editTariffTime.flat_fee != null) {
      this.feeType = "flat_fee";
      this.flatFeeFormControl.setValue(this.feeService.getFormattedFeeValueOnly(this.editTariffTime.flat_fee).replace(",", "."));
    } else if (this.editTariffTime.consecutive_fees.length > 0) {
      this.feeType = "consecutive_fees";
      console.log("Emiting consecutive fees", this.editTariffTime.consecutive_fees.map((fee) => +(fee / 100.0).toFixed(2)))
      this.consecutiveFeeValues = this.editTariffTime.consecutive_fees.map((fee) => +(fee / 100.0).toFixed(2))
    } else {
      this.feeType = "fee";
      this.feeFormControl.setValue(this.feeService.getFormattedFeeValueOnly(this.editTariffTime.fee).replace(",", "."));
      let currentBillingIntervalMinutes = this.editTariffTime.billing_interval_minutes || this.billing_interval_minutes
      this.billingIntervalMinutesOnTariffTimes.setValue(currentBillingIntervalMinutes.toString());
    }
  }

  ngOnChanges(changes: SimpleChange) {
    // @ts-ignore
    let groupTariffChanges = changes["groupedTariffTime"];
    if (groupTariffChanges == null) {
      return
    }
    let newValue = groupTariffChanges.currentValue as GroupedTariffTimesWithDays;
    console.log("New props", newValue)
    if (newValue != null) {
      console.log("New value", newValue)
      this.multiDayCheck.set(newValue.multiDay);
      this.editTariffTime = {...newValue.tariffTimes[0]};
      this.startDaySelection = this.editTariffTime.day_of_week_start;
      this.startTimeFormControl.setValue(this.minutesToTime(this.editTariffTime.start_time));
      this.endDaySelection = this.editTariffTime.day_of_week_end;
      this.endTimeFormControl.setValue(this.minutesToTime(this.editTariffTime.end_time));
      this.freeParkingMinutesFormControl.setValue(this.editTariffTime.free_parking_duration_minutes || null);
      if (this.editTariffTime.flat_fee != null) {
        this.feeType = "flat_fee";
        this.flatFeeFormControl.setValue(this.feeService.getFormattedFeeValueOnly(this.editTariffTime.flat_fee).replace(",", "."));
      } else if (this.editTariffTime.consecutive_fees.length > 0) {
        this.feeType = "consecutive_fees";
        this.consecutiveFeeValues = this.editTariffTime.consecutive_fees.map((fee) => +(fee / 100.0).toFixed(2))
      } else {
        this.feeType = "fee";
        this.feeFormControl.setValue(this.feeService.getFormattedFeeValueOnly(this.editTariffTime.fee).replace(",", "."));
        let currentBillingIntervalMinutes = this.editTariffTime.billing_interval_minutes || this.billing_interval_minutes
        this.billingIntervalMinutesOnTariffTimes.setValue(currentBillingIntervalMinutes.toString());
      }
    }
  }

  onStartTimeChange() {
    const input = this.startTimeFormControl.value;
    if (input == null) {
      this.editTariffTime.start_time = 0;
    } else {
      this.editTariffTime.start_time = this.timeToMinutes(input);
    }
    this.onTimeRangeChange();
  }

  onEndTimeChange() {
    const input = this.endTimeFormControl.value;
    if (input == null) {
      this.editTariffTime.end_time = 0;
    } else {
      let endTimeMinutes = this.timeToMinutes(input);
      if (endTimeMinutes == 0) {
        endTimeMinutes = 24 * 60;
      }
      this.editTariffTime.end_time = endTimeMinutes;
    }
    this.onTimeRangeChange();
  }

  onStartDayChange() {
    this.editTariffTime.day_of_week_start = this.startDaySelection;
    this.onTimeRangeChange();
  }

  onEndDayChange() {
    this.editTariffTime.day_of_week_end = this.endDaySelection;
    this.onTimeRangeChange();
  }

  onFeeChange() {
    const feeText = this.feeFormControl.value;
    const flatFeeText = this.flatFeeFormControl.value;
    const consecutiveFees = this.consecutiveFeeValues

    if (this.feeType == "fee") {
      const fee = feeText == null ? 0 : (+(+feeText).toFixed(2) * 100);
      if (isNaN(fee)) {
        return;
      }

      this.editTariffTime.fee = fee;
      this.editTariffTime.flat_fee = null;
      this.editTariffTime.consecutive_fees = [];

      this.feeFormControl.setValue((fee / 100.0).toString());
      this.flatFeeFormControl.setValue("");
    } else if (this.feeType == "flat_fee") {
      const flatFee = flatFeeText == null ? 0 : (+(+flatFeeText).toFixed(2) * 100);
      if (isNaN(flatFee)) {
        return;
      }

      this.editTariffTime.fee = 0;
      this.editTariffTime.flat_fee = flatFee;
      this.editTariffTime.consecutive_fees = [];

      this.feeFormControl.setValue("");
      this.flatFeeFormControl.setValue((flatFee / 100.0).toString());
    } else { //this.feeType == "consecutive_fees"
      if (consecutiveFees.find((fee) => isNaN(fee)) != null) {
        return;
      }

      this.editTariffTime.fee = 0;
      this.editTariffTime.flat_fee = null;
      this.editTariffTime.consecutive_fees = consecutiveFees.map((fee) => (+fee.toFixed(2)) * 100.0);

      this.feeFormControl.setValue("");
      this.flatFeeFormControl.setValue("");
    }

    console.log("fee change", this.editTariffTime);
    this.tariffTimeChange.next(this.editTariffTime);
  }

  onBillingIntervalMinutesChange() {
    console.log("billing interval minutes change")
    const input = this.billingIntervalMinutesOnTariffTimes.value;
    if (input == null) {
      this.editTariffTime.billing_interval_minutes = 60
    } else {
      this.editTariffTime.billing_interval_minutes = Number(input);
    }
    this.tariffTimeChange.next(this.editTariffTime);

  }

  onFreeParkingMinutesChange() {
    const input = this.freeParkingMinutesFormControl.value;
    if (input == null) {
      this.editTariffTime.free_parking_duration_minutes = null;
    } else {
      this.editTariffTime.free_parking_duration_minutes = Number(input);
    }
    this.tariffTimeChange.next(this.editTariffTime);
  }

  private onTimeRangeChange() {
    this.tariffTimeChange.next(this.editTariffTime);
  }

  private minutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    if (hours == 24) {
      return "00:00";
    }
    const remainingMinutes = minutes % 60;
    return hours.toString().padStart(2, "0") + ":" + remainingMinutes.toString().padStart(2, "0");
  }

  private timeToMinutes(time: string): number {
    const split = time.split(':');
    const hours = +split[0]
    const minutes = +split[1];

    if (hours == null || minutes == null || isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return 0;
    }
    return hours * 60 + minutes;
  }

  private arraysEqual(a: any[], b: any[]) {
    if (a.length !== b.length) return false;
    return a.every((val, idx) => val === b[idx])
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onSelectedDaysChange($event: any) {
    this.dayChange.next($event);
  }

  onConsecutiveChange($event: { fees: number[]; intervals: number[] }) {
    console.log("Consecutive change", $event)
    this.editTariffTime.consecutive_fees = $event.fees.map((fee) => fee);
    this.editTariffTime.consecutive_billing_interval_minutes = $event.intervals;
    this.tariffTimeChange.next(this.editTariffTime);
  }
}
