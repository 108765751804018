import {Component, OnInit} from '@angular/core';
import {ParkingOperationsPageTabIndexService} from "../../services/parking-operations-page-tab-index.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css'],
  standalone: false
})
export class UserPageComponent   {
  showInvoiceTab: boolean = false;

  constructor(
    public parkingOperationsPageTabIndexService: ParkingOperationsPageTabIndexService,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as { invoiceTab: boolean };

    if (state?.invoiceTab) {
      this.showInvoiceTab = true;
      this.parkingOperationsPageTabIndexService.switchToInvoiceTab()
    }
  }

  isHomePage() {
    return window.location.pathname === '/';
  }
}
