import {Component} from '@angular/core';
import {GlobalStateService} from "../../../../../../services/global-state.service";

@Component({
    selector: 'app-pay-parking-operations-header',
    templateUrl: './pay-parking-operations-header.component.html',
    styleUrls: ['./pay-parking-operations-header.component.css'],
    standalone: false
})
export class PayParkingOperationsHeaderComponent {

  constructor(
    public globalStateService: GlobalStateService
  ) {

  }

}
