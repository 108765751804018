import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParkingLot} from "../../../../../models/ParkingLot";
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {Router} from "@angular/router";
import {GlobalStateService} from "../../../../../services/global-state.service";
import {BehaviorSubject} from "rxjs";
import {CustomTariffDescription} from "../../../../../models/CustomTariffDescription";
import {LocalStorageService} from "../../../../../services/local-storage.service";

@Component({
  selector: 'app-existing-parking-operations-page',
  templateUrl: './existing-parking-operations-page.component.html',
  styleUrls: ['./existing-parking-operations-page.component.css'],
  standalone: false
})
export class ExistingParkingOperationsPageComponent implements OnInit {

  @Input() parkingLots!: ParkingLot[];
  @Input() parkingLotsWithCustomTariffDescription!: CustomTariffDescription[];
  @Input() parkingOperationsWithBookingsAndFeesSelections!: ParkingOperationWithBookingsAndFeesWithSelection[];

  @Output() reloadParkingOperationsTrigger = new EventEmitter<any>();

  recomputeSelectedFeesTriggerSubject = new EventEmitter<any>();

  selectedParkingOperations = new BehaviorSubject<ParkingOperationWithBookingsAndFeesWithSelection[]>([]);
  totalSelectionFee = new BehaviorSubject<number>(0);

  hideAddAdditionalParkingOperationsView = true;

  constructor(
    private router: Router,
    public globalStateService: GlobalStateService,
    private localStorageService: LocalStorageService,
  ) {
    if (globalStateService.normalizedLicensePlate.length == 0 || globalStateService.licensePlateInput.length == 0) {
      this.router.navigateByUrl("/");
    }
  }

  ngOnInit() {
    this.recomputeSelectedFeesTriggerSubject.emit();
  }

  clearStateAndGoToLanding() {
    this.globalStateService.clearState();
    this.localStorageService.removeLicensePlateData();
    this.router.navigateByUrl("/");
  }
}
