import {Component, Input} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GlobalStateService} from "../../../../../services/global-state.service";
import {MatStepper} from "@angular/material/stepper";
import {timeout} from "rxjs";

@Component({
    selector: 'app-license-plate-input-step',
    templateUrl: './license-plate-input-step.component.html',
    styleUrls: ['./license-plate-input-step.component.css'],
    standalone: false
})
export class LicensePlateInputStepComponent {

  @Input() stepper!: MatStepper;

  licensePlateFormControl = new FormControl('',
    [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(15)
    ]
  );

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private globalStateService: GlobalStateService
  ) {

  }

  /**
   * Reads the normalized license plate from the input field.
   * The normalized license plate is the license plate without spaces and dashes and with an E at the end (for electric vehicles).
   */
  private readNormalizedLicensePlate(): string {
    const licensePlateInput = this.licensePlateFormControl.getRawValue() ?? "";

    return this.globalStateService.toNormalizedLicensePlate(licensePlateInput);
  }

  /**
   * Returns true only if the license plate was valid.
   */
  private fetchLicensePlateIntoGlobalState(): boolean {
    this.globalStateService.licensePlateInput = this.licensePlateFormControl.getRawValue()?.toUpperCase() ?? "";
    this.globalStateService.normalizedLicensePlate = this.readNormalizedLicensePlate();
    return !(this.globalStateService.normalizedLicensePlate.length < 3 || this.globalStateService.normalizedLicensePlate.length > 15);
  }

  onClickParkingOperationManagement() {
    if(this.licensePlateFormControl.invalid) {
      return;
    }

    if (this.fetchLicensePlateIntoGlobalState()) {
      this.nextStep();
    } else {
      this.snackBar.open("Bitte geben Sie ein gültiges Kennzeichen ein.", "", {duration: 3000});
    }
  }

  private nextStep() {
    //the stepper needs some time to realize the condition for the next step has changed.
    setTimeout(() => {
      this.stepper.next();
    }, 200);
  }

}
