import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FeeService} from "../../../../../../services/fee.service";
import {MatDialog} from "@angular/material/dialog";
import {ParkingLot} from "../../../../../../models/ParkingLot";
import {
  ParkingOperationWithBookingsAndFeesWithSelection
} from "../../../../../../models/ParkingOperationWithBookingsAndFeesWithSelection";
import {CustomTariffDescription} from "../../../../../../models/CustomTariffDescription";
import {
  ParkingOperationsPageTabIndexService
} from "../../../../../../services/parking-operations-page-tab-index.service";
import {GlobalStateService} from "../../../../../../services/global-state.service";
import {TelecashPaymentServiceService} from "../../../../../../services/payment/telecash-payment-service.service";
import {ApiCallWrapperService} from "../../../../../../services/api/api-call-wrapper.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {FormControl, Validators} from "@angular/forms";

@Component({
    selector: 'app-parking-operation-summary',
    templateUrl: './parking-operation-summary.component.html',
    styleUrls: ['./parking-operation-summary.component.css'],
    standalone: false
})
export class ParkingOperationSummaryComponent {

  @Input() parkingLots!: ParkingLot[];

  @Input() selectedParkingOperations!: BehaviorSubject<ParkingOperationWithBookingsAndFeesWithSelection[]>;
  @Input() totalSelectionFee!: BehaviorSubject<number>;

  @Input() parkingLotsWithCustomTariffDescription: CustomTariffDescription[] = []
  @Output() reloadParkingOperationsTrigger = new EventEmitter<any>();

  constructor(
    private feeService: FeeService,
    public globalStateService: GlobalStateService,
  ) {

  }

  emailAddressFormControl = new FormControl('', [Validators.email]);

  getFormattedFee(fee: number): string {
    return this.feeService.getFormattedFee(fee);
  }

  getEmail(): string {
    return this.emailAddressFormControl.value || ""
  }

}
